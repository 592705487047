/* eslint-disable */
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FieldService } from '../field.service';
import { ModelListComponent } from '../../model/model-list/model-list.component';
// import { FormListComponent } from '../../custom-forms/form-list/form-list.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './field-list.component.scss'],
})
export class FieldListComponent implements OnInit {
  loadingRouteConfig: any;
  refreshLoading: any;
  all_fields: any = [];
  add: boolean;

  @Input()
  data: any;

  @Input()
  component: any;

  @Input()
  apieData: any;

  model_id: any;
  model_name: any;
  data1: any;

  multiStepNo: any = 0;
  multi_form: any;
  clicked_step_id: any;
  clicked_field_id: any;
  @ViewChild('formCanvas', { read: ElementRef, static: true }) formCanvas: ElementRef;
  constructor(
    // private elRef: ElementRef, 
    private service: FieldService, private list: ModelListComponent) {
    this.loadingRouteConfig = true;
  }

  ngOnInit() {
    this.formCanvas.nativeElement.style.display = "none";
    this.refreshLoading = true;
    this.formCanvas.nativeElement.innerHTML = '';
    this.model_id = this.data.model_id;
    this.model_name = this.data.model_name;
    // console.log(this.data);
    //  if (this.data._isRedirect === 'form_list') {
    // // console.log(this.data.form_id);
    //   this.service.getFormField(this.data.form_id).subscribe((field: any) => {
    //     // console.log(field);
    //     this.loadingRouteConfig = false;
    //     this.refreshLoading = false;
    //     if(this.data.submitTo == 'multiple'){
    //       this.formCanvas.nativeElement.style.display = "block";
    //       if (field.data.length === 0) {
    //         this.add = true;
    //       }
    //       else {
    //         this.add = false;
    //         this.all_fields = field.data[0];
    //         this.createFormCanvas();
    //       }
    //     }
    //     else{
    //       if (field.data.length === 0) {
    //         this.add = true;
    //       }
    //       else {
    //         this.add = false;
    //         this.all_fields = field.data;
    //       }
    //     }
    //   });
    // }
    if (this.data._isRedirect === 'model_list') {
      // console.log(this.model_id);
      this.service.getAllField(this.model_id).subscribe((field: any) => {
        // console.log(field);
        this.loadingRouteConfig = false;
        this.refreshLoading = false;
        if (field.data.length === 0) {
          this.add = true;
        }
        else {
          this.add = false;
          this.all_fields = field.data;
        }
      });
    }
    else if (this.data._isRedirect === 'catalog') {
      // console.log(this.model_id);
      this.service.getAllField(this.model_id).subscribe((field: any) => {
        // console.log(field);
        this.refreshLoading = false;
        this.loadingRouteConfig = false;
        if (field.data.length === 0) {
          this.add = true;
        }
        else {
          this.add = false;
          this.all_fields = field.data;
        }
      });
    } 

  }
  // ngAfterViewInit(){
  //   console.log(this.formCanvas.nativeElement.outerHTML);
  //   if(this.multi_form){
  //     console.log('hii');
  //     this.formCanvas.nativeElement.innerHTML = this.multi_form;
  //   }
  // }

  createFormCanvas(){
    Object.keys(this.all_fields).forEach((key,index1) => {
      // console.log(key);
      var step_no = key.replace('step','').trim();
      if(step_no > this.multiStepNo){
        this.multiStepNo = step_no;
      }
        this.addStep(parseInt(step_no));
      // console.log(this.formCanvas.nativeElement.outerHTML);

      // console.log(this.all_fields[key]);
      if(this.all_fields[key].length === 0){

      }
      else{
        this.all_fields[key].forEach((field,index2) => {
          // console.log(field.name);
          let fieldObject = document.createElement('div');
          fieldObject.classList.add('apie_field');
          fieldObject.id = 'apie_step' + step_no +'_field' + (index2+1);
          fieldObject.innerText = field.name;
          document.getElementById(step_no).innerHTML = document.getElementById(step_no).innerHTML + fieldObject.outerHTML;
        });
      }

      if(index1 == Object.keys(this.all_fields).length-1){
        this.multi_form = this.formCanvas.nativeElement.innerHTML.toString();
        var button_array = document.querySelectorAll("button");
        var div_array = document.querySelectorAll("div");
        // console.log(button_array);
        // console.log(button_array.length);
        for (let i = 1; i < button_array.length; i++) {
          if(document.getElementById('apie_button' + i)){
            document.getElementById('apie_button' + i).addEventListener('click', (event) => {
                event.stopPropagation();
                this.formCanvas.nativeElement.style.display = "none";
                this.data1 = {
                  fieldOf: 'form',
                  _isDirected: false,
                  model_id: this.data.model_id,
                  step_type: this.data.submitTo,
                  step_id: i
                };
                this.component = 'field_add';
              });
          }
        }

        Object.keys(this.all_fields).forEach((key) => {
          var step_no = key.replace('step','').trim();
          for (let i = 1; i < div_array.length; i++) {
            if(document.getElementById('apie_step' + step_no +'_field' + i)){
              document.getElementById('apie_step' + step_no +'_field' + i).addEventListener('click', (event) => {
                event.stopPropagation();
                if (this.clicked_step_id) {
                  document.getElementById(this.clicked_step_id).style.borderColor = null;
                  this.clicked_step_id = null;
                }
                if (this.clicked_field_id) {
                  document.getElementById(this.clicked_field_id).style.borderColor = null;
                }
                this.clicked_field_id = 'apie_step' + step_no +'_field' + i;
                document.getElementById('apie_step' + step_no +'_field' + i).style.borderColor = "blue";
                });
            }
          }
        });
        
      }
    });
  }


  deleteFormCanvas(){
    if(this.clicked_field_id){
      // console.log('field');
      // console.log(this.clicked_field_id);
      if(document.getElementById(this.clicked_field_id)){
        document.getElementById(this.clicked_field_id).remove();
        this.clicked_field_id = null;
      }
      // back-end functionalities
    }
    if(this.clicked_step_id){
      // console.log('step');
      // console.log(this.clicked_step_id);
      if(document.getElementById(this.clicked_step_id)){}
      document.getElementById(this.clicked_step_id).remove();
      this.clicked_step_id = null;
    }
      // back-end functionalities
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.all_fields, event.previousIndex, event.currentIndex);
    var record = {
      model_id: this.model_id,
      array: this.all_fields
    }
    this.service.updateOrder(record).subscribe((order: any) => {
      console.log(order)
    })
  }

  addField() {
    if (this.data._isRedirect === 'model_list') {
      this.data1 = {
        _isDirected: false,
        model_id: this.model_id,
        fieldOf: 'model',
        type: 'default'
      };
      this.component = 'field_add';
    }
    else if (this.data._isRedirect === 'catalog') {
      this.data1 = {
        _isDirected: false,
        "model_id": this.model_id,
        fieldOf: 'model',
        type: 'default'
      };
      this.component = 'field_add';
    }
    else if (this.data._isRedirect === 'form_list') {
      this.data1 = {
        fieldOf: 'form',
        _isDirected: false,
        model_id: this.model_id,
        step_type: this.data.submitTo,
        type: this.data.type
      };
      this.component = 'field_add';
    }

  }
  deleteField(obj) {
    this.data1 = {
      "field": obj,
      "model_id": this.model_id,
    }
    this.component = 'field_delete';
  }
  back() {
    // console.log(this.data)
    if (this.data._isRedirect === 'model_list') {
      this.list.component = 'model_list';
    }
    // else if (this.data._isRedirect === 'form_list') {
    //   this.form_list.component = 'form_list';
    // }
    else if (this.data._isRedirect === 'catalog') {
      this.list.component = 'catalog';
    }
  }
  editField(obj) {
    this.data1 = {
      "field": obj,
      "model_id": this.model_id,
    }
    this.component = 'field_edit';
  }
  // below for multi step form
  addStep(step_no) {
    let htmlObject1 = document.createElement('div');
    htmlObject1.classList.add('apie_step');
    htmlObject1.id = step_no;

    let htmlObject2 = document.createElement('button');
    htmlObject2.classList.add('apie_button');
    htmlObject2.id = 'apie_button' + step_no;
    htmlObject2.innerHTML = 'Add Field to step' + step_no;

    let htmlObject3 = document.createElement('input');
    htmlObject3.classList.add('apie_text');
    htmlObject3.id = 'apie_text' + step_no;
    htmlObject3.type = 'text';
    htmlObject3.placeholder = 'give submit url';
    htmlObject3.style.width = '50%';
    
    this.insertHtml(htmlObject1, htmlObject1.id, htmlObject2, htmlObject2.id, htmlObject3, htmlObject3.id);
  }
  insertHtml(htmlObject1, step_id, htmlObject2, button_id, htmlObject3, text_id) {
    var parent_html = this.formCanvas.nativeElement;
    htmlObject1.insertAdjacentHTML("beforeend", htmlObject2.outerHTML);
    htmlObject1.insertAdjacentHTML("beforeend", htmlObject3.outerHTML);
    // console.log(parent_html);
    parent_html.insertAdjacentHTML("beforeend", htmlObject1.outerHTML);
    // this.clicked_html_id = null;
    this.multi_form = this.formCanvas.nativeElement.innerHTML.toString();

    document.getElementById(step_id).addEventListener('click', (event) => {
      event.stopPropagation();
      if(this.clicked_field_id){
        document.getElementById(this.clicked_field_id).style.borderColor = null;
        this.clicked_field_id = null;
      }
      if (this.clicked_step_id) {
        document.getElementById(this.clicked_step_id).style.borderColor = null;
      }
      this.clicked_step_id = step_id;
      document.getElementById(step_id).style.borderColor = "blue";
    });

    // console.log(button_id);
    // console.log(document.getElementById(button_id));
    if(document.getElementById(button_id)){
      document.getElementById(button_id).addEventListener('click', (event) => {
        event.stopPropagation();
        this.formCanvas.nativeElement.style.display = "none";
        this.data1 = {
          fieldOf: 'form',
          _isDirected: false,
          "model_id": this.data.model_id,
          step_type: this.data.submitTo,
          step_id: step_id
        };
        this.component = 'field_add';
      });
    }
    


  }

  increaseMultiStepNo(){
    this.multiStepNo++;
  }

}

