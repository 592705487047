/* eslint-disable */
import { Component, OnInit, Inject } from '@angular/core';
// import { UserService } from 'src/app/admin/shared/users/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { SettingService } from '../../admin/shared/settings/setting.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../admin/shared/shared.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  token: any;
  invitation_id: any;
  d_token: any;
  d_email: any;
  role: any;
  email: any;
  password: any;
  name: any;
  // username: any;
  number: any;
  repassword: any;
  status: any;
  logo: any;
  loadingRouteConfig: boolean;
  hide = true;
  loaderUrl: string;

  isPasswordInvalid: any;
  passwordError: any = {};
  rehide: any;

  constructor(@Inject(DOCUMENT) private doc,private router: Router, private service: SharedService, private fireauth: AngularFireAuth, private route: ActivatedRoute, private _snackBar: MatSnackBar) {
    this.loadingRouteConfig = true;
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
    });
  }

  createLink(type, url) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }

  ngOnInit() {
    // this.pageTitle.setTitle("Register to Site");
    this.loaderUrl = "https://firebasestorage.googleapis.com/v0/b/ " + environment.firebase.storageBucket + "/o/APIE_ASSETS%2Floader.gif?alt=media" ;
    if(this.token === undefined)
    {
      this.router.navigate(['/']);
    }
    else
    {
      this.service.getSettings().subscribe((setting: any) => {
        if(setting.w_logo === undefined)
        {
          this.logo = "../../../assets/apie_img/apie-logo.png";
        }
        else
        {
          this.logo = setting.w_logo;
        }
        if(setting.w_fav === undefined)
        {
          this.createLink("icon","../../../assets/apie_img/apie-fav.png");
        }
        else
        {
          this.createLink("icon", setting.w_fav);
        }
        this.service.getAllUserInvitation(this.token).subscribe((invitation: any) => {
          // console.log(invitation)
          this.invitation_id = invitation.key;
          this.d_token = invitation.token;
          this.d_email = invitation.mail;
          this.role = invitation.role;
          this.status = invitation.status;
          this.loadingRouteConfig = false;
        })
      })
    }
  }

  validatePassword(password: string) {
    let hasLower = false;
    let hasUpper = false;
    let hasNum = false;
    let hasSpecial = false;
    let hasEight = false;

    const lowercaseRegex = new RegExp("(?=.*[a-z])");// has at least one lower case letter
    if (lowercaseRegex.test(password)) {
      hasLower = true;
    }

    const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
    if (uppercaseRegex.test(password)) {
      hasUpper = true;
    }

    const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
    if (numRegex.test(password)) {
      hasNum = true;
    }

    const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");// has at least one special char
    if (specialcharRegex.test(password)) {
      hasSpecial = true;
    }

    if(password){
      if(password.length >= 8){
        hasEight = true;
      }
    }
    

    if(hasLower == false || hasUpper == false || hasNum == false || hasSpecial == false || hasEight == false)
    {
      this.isPasswordInvalid = true;
    }
    else{
      this.isPasswordInvalid = false;
    }
      this.passwordError = {
        hasLower: hasLower,
        hasUpper: hasUpper,
        hasNum: hasNum,
        hasSpecial: hasSpecial,
        hasEight: hasEight
      }

  }

  submit() {

    if (this.d_email === this.email && this.d_token === this.token && this.password === this.repassword && this.status === 'pending') {
      this.fireauth.createUserWithEmailAndPassword(this.email, this.password).then((res: any) => {
        // console.log(res);
        var record = {
          name: this.name,
          email: this.email,
          // username: this.username,
          number: this.number,
          uid: res.user.uid,
          role: this.role,
          status: 'accepted',
          key: this.invitation_id
        }
        // console.log(record)
        this.service.addUser(record).subscribe((user: any) => {
          this.router.navigate(['/login']);
          this._snackBar.open('Login Success', '', {
            duration: 2500,
            horizontalPosition: "center",
            panelClass: ['custom-snackbar']
          });
        })
      }).catch(err => {
        this._snackBar.open('Login failed -' + err.message, '', {
          duration: 2500,
          horizontalPosition: "center",
          panelClass: ['custom-snackbar']
        });
      });
    }
    else {
      this._snackBar.open('Login failed -', '', {
        duration: 2500,
        horizontalPosition: "center",
        panelClass: ['custom-snackbar']
      });
    }

  }

}
