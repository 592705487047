import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LightBoxService {
  host = environment.host;
  constructor(private httpClient: HttpClient) { }


  getBitrixProduct(code) {
    return this.httpClient.get(`${this.host + "bitrix/"}` + code);
  }
  getAllFormList() {
    return this.httpClient.get(`${this.host + "forms/get_all_forms"}`)
  }
  getModelFields(model_id) {
    return this.httpClient.get(`${this.host + "field/modelfield/"}` + model_id)
  }
  addContent(record) {
    return this.httpClient.post(`${this.host + "contents/single/add/"}`, record);
  }
  getAllModels(record) {
    return this.httpClient.post(`${this.host + "model/get"}`, record);
  }
  getAllCodes(model_id) {
    return this.httpClient.get(`${this.host + "query/"}` + model_id);
  }
  getCodeField(code) {
    return this.httpClient.get(`${this.host + "field/codefield/"}` + code);
  }
  updateShortcode(record) {
    return this.httpClient.put(`${this.host + "pages/shortcodes/updatefield"}`, record);
  }
  getFieldMap(page_id,code) {
    return this.httpClient.get(`${this.host + "pages/subcollection/"}` + page_id + '/' + code);
  }
  getFormField(key)
  {
    return this.httpClient.get(`${this.host + "forms/form-field/"}` + key);
  }
  // UpdateMultipleContentOfPage(record)
  // {
  //   return this.httpClient.post(`${this.host + "pages/multi-edit"}`, record);
  // } 
  getBitrixField(record) {
    return this.httpClient.post(`${this.host + "bitrix/fields/all"}`, record);
  }

  getAllPages() {
    return this.httpClient.get(`${this.host + "pages/"}`);
  }
  refreshShortCode(record) {
    return this.httpClient.put(`${this.host + "contents/mrefresh/model"}`, record);
  }
  getModelFromShortcode(code) {
    return this.httpClient.get(`${this.host + "contents/shortcode/"}` + code);
  }
  getSession(record) {
    return this.httpClient.put(`${this.host + "bitrix/get-session"}`, record);
  }
  getAllModelFields(key) {
    return this.httpClient.get(`${this.host + "contents/allfileld/"}` + key);
  }

  getFormFieldMap(record) {
    return this.httpClient.post(`${this.host + "forms/fieldmap"}`, record);
    // record= {
    //   page_id: "",
    //   shortCode: ""
    // }
  }

  getCodeContent(record) {
    return this.httpClient.post(`${this.host + "contents/getcodecontent/"}`, record);
  }

  addUpdateContent(record){
    return this.httpClient.put(`${this.host + "contents/maddupdate/"}`, record);
  }


  sortAlphabetically(arr, prop) {
    arr.sort(
      function (a, b) {
        if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
          return -1;
        } else if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    );
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  getColData(code,record) {
    return this.httpClient.post(`${this.host + "contents/getcol/"}` + code , record);
  }
}
