/* eslint-disable */
import { Component, AfterViewInit, OnDestroy, ViewChild, ViewContainerRef, Inject, NgModuleFactory, NgModule, Compiler, ComponentRef, ComponentFactory, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicService } from './dynamic.service';
import { Meta, Title, DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonModule, DOCUMENT, JsonPipe } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgPipesModule, UnderscorePipe } from 'ngx-pipes';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { SettingService } from '../admin/shared/settings/setting.service';
import { SelectorModule } from './selector/selector.module';
// import { COMPILER_PROVIDERS } from '@angular/compiler';
import { Gesture, GestureController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { get } from 'scriptjs';
declare var $: any;
import * as _language from '../admin/shared/language.json';
import { SharedService } from '../admin/shared/shared.service';
import { Location } from '@angular/common';
import { SidePanelComponent } from '../admin/shared/side-panel/side-panel.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements AfterViewInit, OnDestroy, OnInit
{
  _userRole: any;
  loadingRouteConfig: boolean;
  udata: any;
  admin_bar: boolean;
  reveal = false;
  toolBarVisible = false;
  record: any;
  w_loader: any;
  layoutGroupObj: any;
  group: any;
  lng: any;
  // hello
  constructor(private gestureCtrl: GestureController, private modalService: NgbModal, private route: ActivatedRoute, private router: Router, @Inject(DOCUMENT) private doc, @Inject(DynamicService) service, private title: Title, private meta: Meta, private compiler: Compiler, private fireauth: AngularFireAuth, private location: Location)
  {
    this.page = this.route['_routerState'].snapshot.url.substring(1);
    // const index = window.location.href.lastIndexOf('lang=');
    // // console.log( window.location.href);
    // // console.log(index);

    // if (index !== -1) {
    //   this.lng = window.location.href.substring(index + 5, window.location.href.length);
    //   // console.log(this.lng);
    // }
    this.route.queryParams.subscribe(params =>
    {
      this.lng = params['lang'];
    });
    this.service = service;
    this.loadingRouteConfig = true;
  }
  page: any;
  @ViewChild('dynamic', { read: ViewContainerRef, static: true }) target: ViewContainerRef;
  private cmpRef: ComponentRef<any>;
  isEditor: boolean;
  service: any;
  pageData: any;
  w_js: any;
  userID: any;
  user_id: any;
  user_type: any;
  loaderUrl: any;

  ngOnInit(): void
  {
    this.setCanonicalUrl();
    this.loaderUrl = 'https://firebasestorage.googleapis.com/v0/b/' + environment.firebase.storageBucket + '/o/APIE_ASSETS%2Floader.gif?alt=media';
    if (sessionStorage.getItem('APIE-CREDENTIAL-TEMP') === null)
    {
      this.userID = this.makeid(8);
      sessionStorage.setItem('APIE-CREDENTIAL-TEMP', this.userID);
    }
    else
    {
      this.userID = sessionStorage.getItem('APIE-CREDENTIAL-TEMP');
    }
    this.checkRole();
  }
  setCanonicalUrl(): void
  {
    const baseUrl = this.doc.location.origin; // Get the base URL, e.g., 'https://www.excelorindia.com'
    const canonicalUrl = `${baseUrl}${this.router.url}`; // Append the current route

    let link: HTMLLinkElement = this.doc.querySelector("link[rel='canonical']");
    if (link)
    {
      // Update existing canonical link
      link.setAttribute('href', canonicalUrl);
    } else
    {
      // Create a new canonical link
      link = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canonicalUrl);
      this.doc.head.appendChild(link);
    }
  }
  ngAfterViewInit()
  {
    // this.toolBarVisible = true;
    //   this.reveal = true;
    var timeout = null;
    var that = this;
    // window.addEventListener("mousemove", event => {
    //   if (timeout !== null) {
    //     this.toolBarVisible = true;
    //     this.reveal = true;
    //     clearTimeout(timeout);
    //   }
    //   timeout = setTimeout(function () {
    //     that.toolBarVisible = false;
    //     that.reveal = false;
    //   }, 5000);
    // });
  }

  panelTrigger()
  {
    var timeout = null;
    var that = this;
    this.toolBarVisible = true;
    this.reveal = true;

    // timeout = setTimeout(function () {
    //   that.toolBarVisible = false;
    //   that.reveal = false;
    // }, 5000);
  }

  panelHide()
  {
    var timeout = null;
    var that = this;
    timeout = setTimeout(function ()
    {
      that.toolBarVisible = false;
      that.reveal = false;
    }, 5000);
  }



  createLink(type: string, url: string)
  {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }

  subStringName(name: string)
  {
    var new_name = decodeURIComponent(name);
    var short_name = new_name.substring(new_name.lastIndexOf('/') + 1, new_name.lastIndexOf('?'));
    return short_name;
  }
  appendFont(fontsArr: any[])
  {
    if (fontsArr.length !== 0)
    {
      var style = '';
      const promise = new Promise((resolve, reject) =>
      {
        fontsArr.forEach((font: { url: string; family: any; }) =>
        {
          const new_name = decodeURIComponent(font.url);
          const short_name = new_name.substring(new_name.lastIndexOf('/') + 1, new_name.lastIndexOf('?'));
          let format = '';
          if (short_name.split('.')[1] === 'ttf')
          {
            format = 'truetype';
          }
          else if (short_name.split('.')[1] === 'otf')
          {
            format = 'opentype';
          }
          else if (short_name.split('.')[1] === 'eot')
          {
            format = 'embedded-opentype';
          }
          else
          {
            format = '\'' + short_name.split('.')[1] + '\'';
          }
          style = style + `@font-face {
              font-family: "${font.family}";
              font-style: normal;
              src: url(${font.url}) format(${format});
            }`;
        });
        resolve(style);
      });
      promise.then((result: any) =>
      {
        const node = this.doc.createElement('style');
        node.innerHTML = result;
        this.doc.head.appendChild(node);
      });
    }
  }


  makeid(length: number)
  {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++)
    {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



  openModal()
  {
    const modalReference = this.modalService.open(SidePanelComponent,
      {
        scrollable: true,
        size: 'lg',
        centered: false,
        backdropClass: 'apie_modal-backdrop',
        windowClass: 'custom-modal animated slideInLeft'
      });
    modalReference.componentInstance.modal = modalReference;
    modalReference.componentInstance.udata = this.udata;
    modalReference.componentInstance.type = 'logout';
  }


  checkRole()
  {
    this.fireauth.authState.subscribe(user =>
    {
      // console.log(this.page)
      if (this.page.includes('#'))
      {
        var splitPage = this.page.split('#');
        this.page = splitPage[0];
      }
      if (this.page.includes('?'))
      {
        var splitPage = this.page.split('?');
        this.page = splitPage[0];
      }
      if (this.page === '')
      {
        this.page = '/';
      }
      if (user === null)
      {
        this.user_id = this.userID;
        this.user_type = 'anynomes';

        this.record = {
          page: this.page,
          user: this.userID,
          ln_code: this.lng
        };
      }
      else
      {
        this.user_id = user.uid;
        this.user_type = 'user';

        this.record = {
          page: this.page,
          user: user.uid,
          ln_code: this.lng
        };
      }
      console.log(this.record);
      this.service.getPage(this.record).subscribe((pageData: any) =>
      {
        console.log(pageData);
        console.log(pageData.path === undefined);

        if (pageData.path === undefined)
        {

          if (pageData.pageDetails ? pageData.pageDetails.pageType == 'static' : false)
          {
            if (window.location.search)
              this.location.go(pageData.pageDetails.name + window.location.search);
            else
              this.location.go(pageData.pageDetails.name);
          }

          this.w_loader = pageData.userData.settingData.w_loader;
          pageData.userData.settingData.w_css.forEach((css: any) =>
          {
            this.createLink('stylesheet', css);
          });
          this.appendFont(pageData.userData.settingData.w_fonts);
          this.w_js = pageData.userData.settingData.w_js;
          if (pageData.userData.settingData.layoutGroupObj !== undefined)
          {
            this.layoutGroupObj = pageData.userData.settingData.layoutGroupObj;
          }
          if (pageData.group !== undefined)
          {
            this.group = pageData.group;
            this.layoutGroupObj[pageData.group].css.forEach((j_css: any) =>
            {
              this.createLink('stylesheet', j_css);
            });
            // this.appendFont(this.layoutGroupObj[pageData.group].fonts);
          }
          if (pageData.userData.settingData.w_fav === undefined)
          {
            this.createLink('icon', '../../assets/apie_img/apie-fav.png');
          }
          else
          {
            this.createLink('icon', pageData.userData.settingData.w_fav);
          }

          var selected_languages = pageData['userData'].settingData.w_language;
          var default_language = pageData['userData'].settingData.w_l_default;

          var _selected_languages = [];
          var _default_language: any;

          var languages: { default: any; all_selected: any[]; };
          _language['default'].forEach((single_language: { code: any; }) =>
          {
            if (single_language.code === default_language)
            {
              _default_language = single_language;
            }
            else
            {
              if (selected_languages !== undefined)
              {
                selected_languages.forEach((_select_language: any) =>
                {
                  if (_select_language === single_language.code)
                  {
                    _selected_languages.push(single_language);
                  }
                });
              }
              else
              {
                _selected_languages = [];
              }
            }
          });
          languages = {
            default: _default_language,
            all_selected: _selected_languages
          };
          // console.log(pageData.model)
          // console.log(pageData.userData.settingData.w_loader);

          if (pageData.userData.role.includes('editor'))
          {
            if (pageData.userData.isActive === true)
            {
              this.udata = {
                role: pageData.userData.role,
                uid: user.uid,
                uname: pageData.userData.name,
                w_app: pageData.userData.settingData.w_app,
                html_str: pageData.html,
                model: escape(JSON.stringify(pageData.model)),
                languages: JSON.stringify(languages),
                userData: escape(JSON.stringify(pageData.userData))
              };
              this.admin_bar = true;
              this._userRole = pageData.userData.role;
              this.isEditor = true;



            } else
            {
              this.isEditor = false;
              this.admin_bar = false;
              // console.log("you are not authenticated to access");
            }
          } else
          {
            this.isEditor = false;
            this.admin_bar = false;
          }
          this.pageData = pageData;
          if (pageData.pageDetails !== undefined)
          {
            if (pageData.pageDetails.isLoginRequired === true)
            {
              if (this.user_type !== 'user')
              {
                alert('you have authenticate to access');
                this.loadPage(pageData);
              }
              else
              {
                // Rediect to not aunthenticated page
                alert('you are not authenticated to access');
              }
            }
            else
            {
              this.loadPage(pageData);
            }
          }
        }
        else
        {
          this.router.navigate(['/' + pageData.path]);
        }
      });
    });
  }
  loadScript(url: string)
  {
    const head = this.doc.head;
    const script = this.doc.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = false;
    script.defer = false;
    head.appendChild(script);
  }
  loadPage(pageData: any)
  {
    // console.log(pageData);
    this.metaFunction(pageData.pageDetails.title, pageData.pageDetails.metaData);
    if (pageData["type"] == "dynamic")
    {
      if (pageData.dynamicContent ? pageData.dynamicContent.details ? pageData.dynamicContent.details.metaTags : false : false)
      {
        this.metaFunction(pageData.dynamicContent.details.metaTags.title, pageData.dynamicContent.details.metaTags);
      }
    }
    const selectors = this.stringToHTML(this.pageData.html).getElementsByTagName('app-selector');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let index = 0; index < selectors.length; index++)
    {
      // console.log(selectors[index].attributes[0])
      let elementHTML = this.pageData.elements.find((o: { name: string; }) => o.name === selectors[index].attributes[0].nodeValue).html;
      elementHTML = elementHTML.replace(/\n/g, '');
      const data = {};
      data['_html'] = escape(elementHTML);
      data['_js'] = escape(this.pageData.elements.find((o: { name: string; }) => o.name === selectors[index].attributes[0].nodeValue).js);
      const e_js = this.pageData.elements.find((o: { name: string; }) => o.name === selectors[index].attributes[0].nodeValue).e_js;
      const e_css = this.pageData.elements.find((o: { name: string; }) => o.name === selectors[index].attributes[0].nodeValue).e_css;

      if (e_css !== undefined)
      {
        e_css.forEach((css: any) =>
        {
          this.createLink('stylesheet', css);
        });
      }
      if (e_js !== undefined)
      {
        e_js.forEach((js: string) =>
        {
          // console.log(js)
          js = js.replace(/"/g, '');
          get(js, function () { });
        });
      }

      data['_functions'] = escape(this.pageData.elements.find((o: { name: string; }) => o.name === selectors[index].attributes[0].nodeValue).functions);
      data['_scss'] = escape(this.pageData.elements.find((o: { name: string; }) => o.name === selectors[index].attributes[0].nodeValue).scss);
      data['pageID'] = this.pageData.page_id;
      data['pageDetails'] = this.pageData.pageDetails;
      if (this.pageData.dynamicContent !== undefined)
      {
        data['dynamicContent'] = escape(JSON.stringify(this.pageData.dynamicContent));
      }
      data['isEditor'] = this.isEditor;
      data['user_id'] = this.user_id;
      data['udata'] = this.udata;
      data['user_type'] = this.user_type;
      data['model'] = escape(JSON.stringify(this.pageData.model[selectors[index].attributes[0].nodeName]));
      if (this.pageData.model._rawSession !== undefined)
      {
        // console.log(this.pageData.model._rawSession);
        data['rawSession'] = escape(JSON.stringify(this.pageData.model._rawSession));
      }
      data['allModels'] = pageData.allModels;
      data['code'] = selectors[index].attributes[0].nodeName;
      this.pageData.html = this.pageData.html.replace(selectors[index].attributes[0].nodeName + '="' + selectors[index].attributes[0].nodeValue + '"', `[data]='` + JSON.stringify(data) + `' (output)='setGlobalData($event)'`);
    }


    this.compileToComponent('dynamic', this.pageData.html).then((factory: ComponentFactory<any>) =>
    {
      this.cmpRef = this.target.createComponent(factory);
      this.cmpRef.instance.isEditor = this.isEditor;
      this.cmpRef.instance.pageData = this.pageData;
      this.cmpRef.instance.page = this.page;
      if (this.layoutGroupObj !== undefined)
      {
        this.cmpRef.instance.layoutGroupObj = this.layoutGroupObj;
      }
      if (this.group !== undefined)
      {
        this.cmpRef.instance.group = this.group;
      }
      this.cmpRef.instance.w_js = this.w_js;
      setTimeout(() =>
      {
        this.loadingRouteConfig = false;
      }, 2000);
    });
  }


  metaFunction(title: any, meta: any)
  {
    this.title.setTitle(title);
    const metadata = [];
    Object.keys(meta).forEach(key =>
    {
      if (key !== 'title')
      {
        if (typeof (meta[key]) === 'object')
        {
          if (key === 'name')
          {
            Object.keys(meta.name).forEach(key1 =>
            {
              metadata.push({ name: key1, content: meta.name[key1] });
            });
          }
          if (key === 'property')
          {
            Object.keys(meta.property).forEach(key1 =>
            {
              metadata.push({ property: key1, content: meta.property[key1] });
            });
          }
        }
        else
        {
          metadata.push({ name: key, content: meta[key] });
        }
      }
    });
    this.meta.addTags(metadata);
  }

  stringToHTML(str: string)
  {
    const dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  }
  ngOnDestroy()
  {
    if (this.cmpRef)
    {
      this.cmpRef.destroy();
    }
  }
  private compileToComponent(selector: string, htmltemplate: string): Promise<ComponentFactory<any>>
  {
    @Component({ selector, template: htmltemplate })
    class DynamicComponent implements OnInit, AfterViewInit
    {
      // @Input()
      // model: any;
      @Input()
      pageData: any;
      @Input()
      modelcode: any;
      @Input()
      w_js: any;
      @Input()
      layoutGroupObj: any;
      @Input()
      group: any;

      service: any;
      allInputs: any = [];
      constructor(private router: Router, private fireauth: AngularFireAuth, @Inject(DOCUMENT) private doc, private _snackBar: MatSnackBar, private sanitizer: DomSanitizer, private modalService: NgbModal, @Inject(DynamicService) service)
      {
        this.service = service;
      }
      loadScript(url: string)
      {
        const body = this.doc.body;
        const script = this.doc.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.async = false;
        script.defer = false;
        body.appendChild(script);
      }
      ngOnInit()
      {
      }
      ngAfterViewInit()
      {
        this.w_js.forEach((js: string) =>
        {
          js = js.replace(/"/g, '');
          this.loadScript(js);
        });
        if (this.group !== undefined)
        {
          this.layoutGroupObj[this.group].js.forEach((g_js: string) =>
          {
            g_js = g_js.replace(/"/g, '');
            this.loadScript(g_js);
          });
        }
      }
    }
    @NgModule({
      imports: [
        CommonModule,
        SelectorModule,
        NgPipesModule,
        BrowserModule,
        RouterModule,

      ],
      declarations: [DynamicComponent],
    })
    class DynamicModule { }

    return this.compiler.compileModuleAndAllComponentsAsync(DynamicModule).then(
      factory => factory.componentFactories.find(x => x.componentType === DynamicComponent)
    );

  }

}


