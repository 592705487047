import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class McontentService {

  host = environment.host;

  constructor(private httpClient: HttpClient) { }

  addContent(record) {
    return this.httpClient.post(`${this.host + "contents/madd"}`, record);
  }
  addMultipleContent(default_content:any, other_languages:any) {
    var record = {
      default_content : default_content,
      other_languages : other_languages
    }
    return this.httpClient.post(`${this.host + "contents/multiple_madd"}`, record);
  }
  getAllContent(record) {
    return this.httpClient.post(`${this.host + "contents/mget"}`, record);
  }

  getSingleContentDetails(record) {
    return this.httpClient.post(`${this.host + "contents/multiple/one"}`, record);
  }

  getMultiLanguageContent(record) {
    return this.httpClient.post(`${this.host + "contents/multiple/language/one"}`, record);
  }

  deleteContent(record) {
    return this.httpClient.put(`${this.host + "contents/mdelete"}`, record);
  }

  deleteMultiLanguageContent(record){
    return this.httpClient.put(`${this.host + "contents/multiple/language/delete"}`, record);
  }

  updateContent(record) {
    return this.httpClient.put(`${this.host + "contents/mupdate/"}`, record);
  }

  addUpdateContent(record){
    return this.httpClient.put(`${this.host + "contents/maddupdate/"}`, record);
  }

  getAllField(model_id) {
    return this.httpClient.get(`${this.host + "field/modelfield/"}` + model_id);
  }

  getCodeContent(record) {
    return this.httpClient.post(`${this.host + "contents/getcodecontent/"}`, record);
  }

  updateActivity(record) {
    return this.httpClient.put(`${this.host + "contents/multiple/language/status_change"}`, record);
  }

  UpdateMultipleContentOfPage(record) {
    return this.httpClient.post(`${this.host + "pages/multi-edit"}`, record);
  }
  allField(key) {
    return this.httpClient.get(`${this.host + "contents/allfileld/"}` + key);
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
