/* eslint-disable */
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ModelService } from '../model.service';
declare var $ :any;
import { ModelListComponent } from '../model-list/model-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultipleEditComponent } from '../../light-box/multiple-edit/multiple-edit.component';
// import { FieldAddComponent } from "../../field/field-add/field-add.component";
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-model-add',
  templateUrl: './model-add.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css','./model-add.component.scss'],
})
export class ModelAddComponent implements OnInit {
  name: any;
  type = 'default'
  loadingRouteConfig: any;
  record: any = [];
  code: any;
  bitrixService: any;
  section_id: any;
  shortcode: any;
  all_sections: any;

  data1: any;

  @Input()
  data: any;
  _isRedirect: any;
  random: any;

  @Input()
  component: any;

  @Input()
  apieData: any;
  
  spinner: boolean;
  newModel = new EventEmitter();

  constructor(private _snackBar: MatSnackBar, private service: ModelService, 
    private list: ModelListComponent,
    private multi_edit: MultipleEditComponent, 
    // private field_add: FieldAddComponent
    ) {
    this.loadingRouteConfig = true;
    this.shortcode = false;
    this.spinner = false;
  }

  ngOnInit() {
    if(this.data.model !== null && this.data.model !== undefined){
      // console.log(this.data.model);
      this.name = this.data.newName;
      this.type = this.data.model.type;
    }

    this._isRedirect = this.data._isRedirect;
    this.loadingRouteConfig = false;
    if(this.apieData.w_app.includes('Bitrix'))
    {
      this.service.getBitrixSection().subscribe((sections: any) => {
        this.all_sections = sections;
      })
    }
    this.random = Math.floor(Math.random() * 1000001);
  }

  add() {
    if (this.type === "bitrix") {
      this.code = this.name + "_" + this.random;
      if ($("#contentname").val().length > 0) {
        this.shortcode = true
      } else {
        this.shortcode = false
      }
    }
  }


  availableInArray(arr, element) {
    if (arr !== undefined) {
      if (arr.includes(element)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  

  clicktocopy(inputElement) {
    if (inputElement.value !== '') {
      inputElement.disabled = false;
      inputElement.select();
      document.execCommand('copy');
      inputElement.setSelectionRange(0, 0);
      inputElement.disabled = true;
      this._snackBar.open('Copied to clipboard', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }

  }


  createModel() {
    this.spinner = true;
    if(this.name == 'users'){
      this._snackBar.open('You cant create collection with this name', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      this.spinner = false;
    }
    else{
       // console.log(this.code)
    // console.log(this.section_id)
    if (this.type === 'bitrix') {
      this.record = {
        "name": this.name,
        "code": this.code,
        "type": this.type,
        "service": this.bitrixService,
        "section_id": this.section_id,
      };
      // console.log(this.record)
      this.service.addModel(this.record).subscribe((model: any) => {
        this.spinner = false;
        this._snackBar.open(model.status, '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.list.ngOnInit();
        this.list.component = 'model_list';
        this.list.pageEvent.pageIndex = 0;
      })
    }
    else {
      this.record = {
        "name": this.name,
        "type": this.type,
        "class": "user"
      };
      this.service.addModel(this.record).subscribe((model: any) => {
        this.data1 =
        {
          _isRedirect: this._isRedirect,
          _isDirected: true,
          fieldOf: 'model',
          "model_id": model.model_id,
        }
        this.newModel.emit({ name: this.name, key: model.model_id });
        this.spinner = false;
        this._snackBar.open(model.status, '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        // this.list.ngOnInit();
        // console.log(this.data1);
        this.component = 'field_add';
      });
    }
    }
   
  }
  back() {
    if (this._isRedirect === 'model_list') {
      this.list.component = 'model_list';
      this.list.pageEvent.pageIndex = 0;
    }
    else if (this._isRedirect === 'multiple_edit') {
      this.multi_edit.component = 'multiple_edit';
    }
    // else if (this._isRedirect === 'field_add') {
    //   this.multi_edit.component = 'field_add';
    // }
  }

}
