import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  host = environment.host;
  constructor(private httpClient: HttpClient) { }
  getLibrary() {
    return this.httpClient.get(`${this.host + "library/"}`);
  }
  deleteLibrary(record) {
    return this.httpClient.put(`${this.host + "library/delete"}`, record);
  }

  addContent(record) {
    return this.httpClient.post(`${this.host + "contents/single/add/"}`, record);
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
