/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { LightBoxService } from '../light-box.service';

@Component({
  selector: 'app-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './image-edit.component.scss'],
})
export class ImageEditComponent implements OnInit {

  @Input()
  model: any;
  @Input()
  contents: any;
  @Input()
  type: any;
  @Input()
  modal: any;
  @Input()
  pageData: any;
  @Input()
  uid: any;
  @Input()
  dynamicContent: any;


  data: any;
  component: any;
  contentType: any = 'static';
  fields: any;
  loaderOverField: boolean;
  modelType: any;
  selectedField: any;


  constructor(private service: LightBoxService) { }

  ngOnInit() {
    if (this.dynamicContent !== undefined) {
      this.dynamicContent = JSON.parse(unescape(this.dynamicContent))
    }
    this.data = {
      ...this.data,
      _image: this.contents._value,
      _shortCode: this.contents._shortCode,
      _isDirected: true,
      _type: 'image_edit',
      _isSingle: true,
      model: this.model,
      page_id: this.pageData.page_id,
      contentType: this.contentType
    }
  }


  getField() {
    this.loaderOverField = true;
    if (this.contentType === 'dynamic') {
      if (this.pageData.pageDetails.model_id !== undefined) {
        this.service.getModelFields(this.pageData.pageDetails.model_id).subscribe((fields: any) => {
          this.modelType = fields.model_type;
          if (fields !== undefined) {
            this.fields = fields.data;
            this.loaderOverField = false;
          }
        });
      }
      else {
        if (this.pageData.pageDetails.sessionVariable !== undefined) {
          var record = {
            page_id: this.pageData.page_id,
            uid: this.uid
          }
          this.service.getSession(record).subscribe((fields: any) => {
            // console.log(fields)
            this.fields = fields;
            this.loaderOverField = false;
          })
        }
      }
    }
  }


  loadContent() {
    this.data['field'] = this.selectedField.name;
    this.data['dynamicContent'] = this.dynamicContent.dynamicName;
    this.data['contentType'] = this.contentType;
  }


  back() {
    this.modal.dismiss();
  }

  open_library() {
    this.component = 'library_list'
    // console.log("Open Galley Here")
  }
}
