/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { CodeListComponent } from '../code-list/code-list.component';
import { CodeService } from '../code.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-code-view',
  templateUrl: './code-view.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './code-view.component.scss'],
})
export class CodeViewComponent implements OnInit {
  date = Date;

  @Input()
  data: any;
  objectKeys = Object.keys;
  code_details: any = [];
  loadingRouteConfig: any;



  constructor(private service: CodeService, private list: CodeListComponent) {
    this.loadingRouteConfig = true;
  }

  ngOnInit() {
    this.code_details = this.data.code;
    this.loadingRouteConfig = false;
  }

  back() {
    this.list.component = 'code_list';
  }

}
