/* eslint-disable */
import { Component, OnInit,Input } from '@angular/core';
import { LightBoxService } from '../light-box.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormEditComponent } from "./../form-edit/form-edit.component";



@Component({
  selector: 'app-restore-form',
  templateUrl: './restore-form.component.html',
  styleUrls: ['./restore-form.component.scss'],
})
export class RestoreFormComponent implements OnInit {
  loadingRouteConfig: any;
  name: any;
  confirm_name: any;
  spinner: boolean;

  @Input()
  data:any;
  @Input()
  component:any;

  constructor(private _snackBar: MatSnackBar,private service: LightBoxService, private form_edit: FormEditComponent) { }

  ngOnInit() {
    // console.log("hello");
    // console.log(this.data);
    this.name = this.data.shortcode;
    this.loadingRouteConfig = false;

  }

  back() {
    this.form_edit.component = 'form_edit';
  }

  restore() {
    this.spinner = true;
    if (this.name.trim() === this.confirm_name.trim()) {
      // console.log(this.data);
      var record = {
      shortcode: this.data.shortcode,
      model: this.data.model,
      page_id: this.data.page_id
    }
    // console.log(record);

    this.service.refreshShortCode(record).subscribe((result: any) => {
        // console.log(result);
        this.spinner = false;
        this._snackBar.open('Form Restored Successfully', '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        window.location.reload();
        // this.form_edit.ngOnInit();
        // this.form_edit.component = 'form_edit';
      })
    }
    else {
      this.spinner = false;
      this._snackBar.open('please provide correct name', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }
  }

}
