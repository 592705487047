import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DynamicService {
  host = environment.host;
  data: any = {};
  apieBoolean: boolean = true;
  constructor(private httpClient: HttpClient) { }
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  getPage(page) {
    return this.httpClient.post(`${this.host + "contents/loadpage"}`, page, this.options);
  }
  getAllPageContent(record) {
    return this.httpClient.post(`${this.host + "contents/allpagecontent"}`, record, this.options);
  }
  submitForm(record) {
    return this.httpClient.post(`${this.host + "bitrix/submitform"}`, record, this.options);
  }
  setSession(record) {
    return this.httpClient.post(`${this.host + "bitrix/set-session"}`, record, this.options);
  }
}