/* eslint-disable */
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { McontentService } from '../mcontent.service';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MlistComponent } from '../mlist/mlist.component';
import { MultipleEditComponent } from "../../light-box/multiple-edit/multiple-edit.component";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-madd',
  templateUrl: './madd.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './madd.component.scss'],
})
export class MaddComponent implements OnInit {
  loadingRouteConfig: any;
  refreshLoading: any;
  isStart: any;
  record: any = [];
  all_fields: any = [];
  field_name: any = [];
  form: FormGroup;
  model_type: any;
  model_id: any;
  model_name: any;
  removable: any = true;

  @Input()
  data: any;

  @Input()
  component: any;

  @Output()
  emitedVallue = new EventEmitter();

  mdownloadURL: any = [];
  sdownloadURL: any;
  _isSingle: boolean;
  collection: any;
  spinner: boolean;
  fcname: any;
  _isRedirect: any;

  emptyObject: boolean = false;

  static_single_dropdown_search: boolean = false;
  static_single_dropdown_tempArray: any;
  dynamic_single_dropdown_search: boolean = false;
  dynamic_single_dropdown_tempArray: any;
  static_multiple_dropdown_search: boolean = false;
  static_multiple_dropdown_tempArray: any;
  dynamic_multiple_dropdown_search: boolean = false;
  dynamic_multiple_dropdown_tempArray: any;

  isFirstInputField: boolean = true;
  all_dynamic_dropdown_field_name = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  default_language:any;
  selected_languages:any;

  _selected_language:any;

  @ViewChild('optInput', { static: true }) optInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @ViewChild('optInput2', { static: true }) optInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2', { static: true }) matAutocomplete2: MatAutocomplete;


  all_contents:any = [];
  dataSource = new MatTableDataSource(this.all_contents);
  displayedColumns: string[] = [];
  table_contents: any = [];
  all_languages: any;
  tab_disable:false;

  @ViewChild('sort',{read:MatSort,static: true}) sort: MatSort;
  language_details: any;



  constructor(private _snackBar: MatSnackBar, private service: McontentService, private fb: FormBuilder,
    private list: MlistComponent,
    private multiple_edit: MultipleEditComponent) 
    {

    this.loadingRouteConfig = true;
    this.spinner = false;
      
    
  }

  add(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
    }
    if (input) {
      input.value = '';
    }
    this.form.controls[field_name].setValue('');
  }

  remove(opt: string, field_name): void {
    this.form.controls[field_name].value.forEach(obj => {
      if (obj == opt) {
        const index = this.form.controls[field_name].value.indexOf(obj);
        this.form.controls[field_name].value.splice(index, 1);
      }

    });
  }

  selected(event: MatAutocompleteSelectedEvent, field_name): void {
    if (this.form.controls[field_name].value == '' || this.form.controls[field_name].value == null || this.form.controls[field_name].value == undefined) {
      this.form.controls[field_name].setValue([]);
      this.form.controls[field_name].value[this.form.controls[field_name].value.length] = event.option.viewValue;
    }
    else {
      var is_option_exist = false;
      this.form.controls[field_name].value.forEach((obj, index) => {
        if (obj == event.option.viewValue) {
          is_option_exist = true;
        }
        if (index == this.form.controls[field_name].value.length - 1) {
          if (is_option_exist == true) {
            this._snackBar.open('you have already selected this option', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
          }
          else {
            this.form.controls[field_name].value[this.form.controls[field_name].value.length] = event.option.viewValue;
          }
        }
      });

    }

    // this.optInput.nativeElement.value = '';

    this.form.controls['static_multiple_dropdown_search_field'].setValue('');
  }

  add2(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
    }
    if (input) {
      input.value = '';
    }

    this.form.controls[field_name].setValue('');
  }

  remove2(opt: string, field_name): void {
    this.form.controls[field_name].value.forEach(obj => {
      if (obj.id == opt['id']) {
        const index = this.form.controls[field_name].value.indexOf(obj);
        this.form.controls[field_name].value.splice(index, 1);
      }

    });
  }

  selected2(event: MatAutocompleteSelectedEvent, field_name, linked_field_name): void {
    if (this.isArray(this.form.controls[field_name].value) == false || this.form.controls[field_name].value.length == 0) {
      this.form.controls[field_name].setValue([]);
      const length = this.form.controls[field_name].value.length;
      this.form.controls[field_name].value[length] = {};
      this.form.controls[field_name].value[length]['id'] = event.option.id;
      this.form.controls[field_name].value[length][linked_field_name] = event.option.viewValue;
    }
    else {
      var is_option_exist = false;
      this.form.controls[field_name].value.forEach((obj, index) => {
        if (obj.id == event.option.id) {
          is_option_exist = true;
        }
        if (index == this.form.controls[field_name].value.length - 1) {
          if (is_option_exist == true) {
            this._snackBar.open('you have already selected this option', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
          }
          else {
            const length = this.form.controls[field_name].value.length;
            this.form.controls[field_name].value[length] = {};
            this.form.controls[field_name].value[length]['id'] = event.option.id;
            this.form.controls[field_name].value[length][linked_field_name] = event.option.viewValue;
          }
        }
      });


    }
    this.form.controls['dynamic_multiple_dropdown_search_field'].setValue('');
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '50',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  // this.form.get('names').statusChanges.subscribe(
  //   status => this.chipList.errorState = status === 'INVALID'
  // );

  ngOnInit() {
    if (this.data !== undefined) {
      this.model_id = this.data.model_id;
      this.collection = this.data.collection;
      this.model_name = this.data.model_name;
      this._isRedirect = this.data._isRedirect;
      console.log(this.data.languages);
      this.language_details = JSON.parse(this.data.languages);
      this.default_language = this.language_details.default;
      this._selected_language = this.default_language.code;
      this.selected_languages = this.language_details.all_selected;

      this.form = new FormGroup(
        {
          'static_multiple_dropdown_search_field': new FormControl(''),
          'dynamic_multiple_dropdown_search_field': new FormControl('')
        }
      );
      this.form.controls['static_multiple_dropdown_search_field'].valueChanges.subscribe(value => {
        if (document.activeElement.id !== '' && document.activeElement.id !== null && document.activeElement.id !== 'undefined') {
          this.onFormControlValueChange(document.activeElement.id, value);
        }
      });
      this.form.controls['dynamic_multiple_dropdown_search_field'].valueChanges.subscribe(value => {
        if (document.activeElement.id !== '' && document.activeElement.id !== null && document.activeElement.id !== 'undefined') {
          this.onFormControlValueChange(document.activeElement.id, value);
        }
      });

      if (this._isRedirect == 'multiple_edit') {
        this.directedFromMultiEdit();
      }

      if (this._isRedirect == 'mlist') {
        this.directedFromMlist();
      }

      if ((Object.keys(this.form.value).length <= 2) === true) {
        this.emptyObject = true;
      }
    }
  }

  directedFromMultiEdit() {
    this.service.getAllField(this.model_id).subscribe((fields: any) => {
      var isDropDown = false;
      fields.data.forEach((field, index) => {
        this.field_name.push(field.name);

        if (field.type === 'dropdown' || field.type === 'multi_dropdown') {
          // ---------------------------------------------- validation ------------------------------------------------------
          if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
            field.isRequired = 'false';
          }
          if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
            field.requiredErrMsg = '';
          }
          // ---------------------------------------------- End -------------------------------------------------------------
          isDropDown = true;
          if (field.l_code !== undefined) {
            const record = {
              field_id: field.l_field_id,
              code: field.l_code
            }
            this.service.getCodeContent(record).subscribe((codecontent: any) => {
              if (codecontent.status === undefined) {
                field.optionData = codecontent;
              }
              this.loadingRouteConfig = false;
            });
          }
          else {
            field.optionData = field.staticOption;
            this.loadingRouteConfig = false;
          }
        }
        else {
          // ---------------------------------------------- validation ------------------------------------------------------
          if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
            field.isRequired = 'false';
          }
          if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
            field.requiredErrMsg = '';
          }
          if(field.patterns == '' || field.patterns == null || field.patterns == undefined){
            field.patterns = '';
          }
          if(field.patternsErrMsg == '' || field.patternsErrMsg == null || field.patternsErrMsg == undefined){
            field.patternsErrMsg = '';
          }
          if(field.minLength == '' || field.minLength == null || field.minLength == undefined){
            field.minLength = '0';
          }
          if(field.minLengthErrMsg == '' || field.minLengthErrMsg == null || field.minLengthErrMsg == undefined){
            field.minLengthErrMsg = '';
          }
          if(field.placeholder == '' || field.placeholder == null || field.placeholder == undefined){
            field.placeholder = '';
          }

          // ---------------------------------------------- End -------------------------------------------------------------
          if (!isDropDown) {
            if (index === fields.data.length - 1)
              this.loadingRouteConfig = false;
          }
        }
        this.all_fields.push(field);
      });
      this.create_Form();
    });
  }

  directedFromMlist() {
   
    this.service.getAllField(this.model_id).subscribe((fields: any) => {
      if(fields.data.length !== 0){
        fields.data.forEach((field, index) => {
          this.field_name.push(field.name);
  
          if (field.type === 'dropdown' || field.type === 'multi_dropdown') {
            // ---------------------------------------------- validation ------------------------------------------------------
            if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
              field.isRequired = 'false';
            }
            if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
              field.requiredErrMsg = '';
            }
            // ---------------------------------------------- End -------------------------------------------------------------
            // isDropDown = true;
            if (field.l_code !== undefined) {
              this.all_dynamic_dropdown_field_name.push(field.name);
              field.optionData = {
                data: { _data: [] }
              }
              if (index === fields.data.length - 1)
                this.loadingRouteConfig = false;
              // });
            }
            else {
              field.optionData = field.staticOption;
              if (index === fields.data.length - 1)
                this.loadingRouteConfig = false;
            }
          }
          else {
            // ---------------------------------------------- validation ------------------------------------------------------
            if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
              field.isRequired = 'false';
            }
            if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
              field.requiredErrMsg = '';
            }
            if(field.patterns == '' || field.patterns == null || field.patterns == undefined){
              field.patterns = '';
            }
            if(field.patternsErrMsg == '' || field.patternsErrMsg == null || field.patternsErrMsg == undefined){
              field.patternsErrMsg = '';
            }
            if(field.minLength == '' || field.minLength == null || field.minLength == undefined){
              field.minLength = '0';
            }
            if(field.minLengthErrMsg == '' || field.minLengthErrMsg == null || field.minLengthErrMsg == undefined){
              field.minLengthErrMsg = '';
            }
            if(field.placeholder == '' || field.placeholder == null || field.placeholder == undefined){
              field.placeholder = '';
            }

            // ---------------------------------------------- End -------------------------------------------------------------
            // if(!isDropDown)
            // {
            if (index === fields.data.length - 1)
              this.loadingRouteConfig = false;
            // }
          }
          this.all_fields.push(field);
        });
      }
      else{
        this.loadingRouteConfig = false;
      }
      
      this.create_Form();
      if(this.data.content !== null && this.data.content !== undefined){
        if(this.data.content[0]){
          Object.keys(this.data.content[0]).forEach((key,idx) => {
            if(this.form.controls[key]){
              this.form.controls[key].setValue(this.data.content[0][key]);
            }
            if(idx ==  Object.keys(this.data.content[0]).length - 1){
              this.checkObj();
            }
          });
        }
      }
    });
  }

  isArray(obj) {
    return Array.isArray(obj);
  }

  callLibrary(fcname) {
    this.fcname = fcname.name;
    let promise = new Promise((resolve, reject) => {
      if (fcname.type === "file") {
        this._isSingle = true
      }
      else (
        this._isSingle = false
      )
      resolve(this._isSingle);
    })
    promise.then((result1: any) => {
      this.data =
      {
        ...this.data,
        _type: "multiple_content_add",
        _isDirected: true,
        _isSingle: result1
      }
      this.component = 'library_list';
    })
  }

  create_Form() {
    this.field_name.forEach((multi_field_name, index) => {
      this.form.addControl(multi_field_name, new FormControl(''));
      this.form.controls[multi_field_name].valueChanges.subscribe(value => {
        this.onFormControlValueChange(multi_field_name, value);
      })
    });
  }

  checkObj() {
    if ((Object.keys(this.form.value).length === 0) === false) {
      if (this.isFirstInputField == true) {
        this.refreshLoading = true;
        if (this.all_dynamic_dropdown_field_name.length !== 0) {
          this.all_dynamic_dropdown_field_name.forEach((field_name, index) => {
            let promise = new Promise((resolve, reject) => {
              this.all_fields.map((field, idx) => {
                let promise1 = new Promise((resolve1, reject1) => {
                  if (field.name == field_name) {
                    const record = {
                      field_id: field.l_field_id,
                      code: field.l_code
                    }
                    this.service.getCodeContent(record).subscribe((codecontent: any) => {
                      resolve1(true);
                      if (codecontent.status === undefined) {
                        this.all_fields[idx].optionData = codecontent;
                      }
                    })

                  }
                  else{resolve1(true);}
                })
                promise1.then((result1: any) => {
                  if (idx == this.all_fields.length - 1) { resolve(true); }

                })

              });
            });
            promise.then((result: any) => {
              if (index == this.all_dynamic_dropdown_field_name.length - 1) {
                this.isFirstInputField = false;
                this.refreshLoading = false;
                if (document.activeElement.id !== '' && document.activeElement.id !== null && document.activeElement.id !== 'undefined') {
                  this.onFormControlValueChange(document.activeElement.id, this.form.controls[document.activeElement.id].value);
                }

              }
            });

          });
        }
        else {
          this.refreshLoading = false;
        }
      }
      else {
        if (document.activeElement.id !== '' && document.activeElement.id !== null && document.activeElement.id !== 'undefined') {
          this.onFormControlValueChange(document.activeElement.id, this.form.controls[document.activeElement.id].value);
        }
      }
    }
  }

  addContent() {
    this.spinner = true;
    delete this.form.value.static_multiple_dropdown_search_field;
    delete this.form.value.dynamic_multiple_dropdown_search_field;
    
    var default_content = {
      ...this.form.value,
      collection: this.collection,
      _lang : this.default_language.code
    }

    this.service.addMultipleContent(default_content, this.selected_languages).subscribe((contents: any) => {
      this.form.value.id = contents.content_id;
      this.spinner = false;
      this._snackBar.open('Content added Successfully', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      if (this._isRedirect == 'multiple_edit') {
        this.emitedVallue.emit(this.form.value);
        this.multiple_edit.component = 'multiple_edit';
      }
      else if (this._isRedirect == 'mlist') {
        var content_id = contents.content_id;
        this.data = {
          ...this.data,
          model_id: this.model_id,
          collection: this.collection,
          model_name: this.model_name,
          content_id,
          _isRedirect: "mlist",
          languages : JSON.stringify(this.language_details)
        };
        this.component = 'medit';
        // this.list.ngOnInit();
        // this.list.component = 'mlist';
        // this.list.pageEvent.pageIndex = 0;
      }
    });
  }

  setImage(event) {
    if (this._isSingle === false) {
      let promise = new Promise((resolve, reject) => {
        for (var i = 0; i < event.length; i++) {
          this.mdownloadURL.push(event[i]);
        }
        resolve(this.mdownloadURL);
      })
      promise.then((result: any) => {
        this.form.controls[this.fcname].setValue(result);
      })
    }
    else {
      this.sdownloadURL = event;
      this.form.controls[this.fcname].setValue(this.sdownloadURL);
    }
  }

  back() {
    if (this._isRedirect == 'multiple_edit') {
      this.multiple_edit.component = 'multiple_edit';
    }
    else if (this._isRedirect == 'mlist') {
      this.list.component = 'mlist';
      this.list.pageEvent.pageIndex = 0;
    }

  }
  
  onFormControlValueChange(field_name, value) {
    this.emptyObject = false;
    this.static_single_dropdown_search = false;
    this.static_single_dropdown_tempArray = null;
    this.dynamic_single_dropdown_search = false;
    this.dynamic_single_dropdown_tempArray = null;
    this.static_multiple_dropdown_search = false;
    this.static_multiple_dropdown_tempArray = null;
    this.dynamic_multiple_dropdown_search = false;
    this.dynamic_multiple_dropdown_tempArray = null;
    try {
      this.all_fields.map(field => {
        if (field.name == field_name) {
          if (field.type == 'dropdown') {
            if (this.isArray(field.optionData) == true) {
              if (value == "") {
                this.static_single_dropdown_search = false;
                this.form.controls.field_name.setValue(null);
              }
              else {
                this.static_single_dropdown_search = true;
                this.static_single_dropdown_tempArray = null;
                this.static_single_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(value.toLowerCase()));
              }
            }
            else {
              if (value == "") {
                this.dynamic_single_dropdown_search = false;
                this.form.controls.field_name.setValue(null);
              }
              else {
                this.dynamic_single_dropdown_search = true;
                this.dynamic_single_dropdown_tempArray = null;
                this.dynamic_single_dropdown_tempArray = {
                  optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                }
                const linked_field_name = field.optionData.field;
                field.optionData.data._data.map(o => {
                  if (o[linked_field_name].toLowerCase().includes(value.toLowerCase())) {
                    this.dynamic_single_dropdown_tempArray.optionData.data._data.push(o);
                  }
                });
              }
            }


          }

          else if (field.type == 'multi_dropdown') {
            if (this.isArray(field.optionData) == true) {
              const multiple_dropdown_search_field = 'static_multiple_dropdown_search_field';
              if (this.form.controls[multiple_dropdown_search_field].value == "") {
                this.static_multiple_dropdown_search = false;
                this.form.controls[multiple_dropdown_search_field].setValue('');
              }
              else {
                this.static_multiple_dropdown_search = true;
                this.static_multiple_dropdown_tempArray = null;
                this.static_multiple_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(this.form.controls[multiple_dropdown_search_field].value.toLowerCase()));
              }
            }
            else {
              const multiple_dropdown_search_field = 'dynamic_multiple_dropdown_search_field';
              if (this.form.controls[multiple_dropdown_search_field].value == "") {
                this.dynamic_multiple_dropdown_search = false;
              }
              else {
                this.dynamic_multiple_dropdown_search = true;
                this.dynamic_multiple_dropdown_tempArray = null;
                this.dynamic_multiple_dropdown_tempArray = {
                  optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                }
                const linked_field_name = field.optionData.field;
                field.optionData.data._data.map(o => {
                  if (o[linked_field_name].toLowerCase().includes(this.form.controls[multiple_dropdown_search_field].value.toLowerCase())) {
                    this.dynamic_multiple_dropdown_tempArray.optionData.data._data.push(o);
                  }
                });
              }
            }
          }
          else {
            // console.log('not a dropdown field');
            this.static_single_dropdown_search = false;
            this.dynamic_single_dropdown_search = false;
            this.static_multiple_dropdown_search = false;
            this.dynamic_multiple_dropdown_search = false;
          }
        }
      });
    } catch { }
  }

  change_language(item: any){
    // console.log(item.tab.textLabel);
    var lang = item.tab.textLabel;
    if(this.default_language.language == lang){
      this._selected_language = this.default_language.code;
      // console.log(this._selected_language);
    }
    else{
      this.selected_languages.forEach(item => {
        if(item.language == lang){
          this._selected_language = item.code;
          // console.log(this._selected_language);
        }
      })
    }
  }
}
