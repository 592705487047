/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { ModelService } from '../model.service';
import { ModelListComponent } from '../model-list/model-list.component';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-model-edit',
  templateUrl: './model-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './model-edit.component.scss'],
})
export class ModelEditComponent implements OnInit {
  name: any;
  type: any;
  record: any = [];

  @Input()
  data: any;

  constructor(private service: ModelService, private list: ModelListComponent) { }

  ngOnInit() {

    this.service.getOneModel(this.data).subscribe((model: any) => {
      this.name = model.name;
      this.type = model.type;
    })

  }
  back() {
    this.list.component = 'model_list';
    this.list.pageEvent.pageIndex = 0;
  }
  updateModel() {
    this.record = {
      "name": this.name,
      "type": this.type,
    };
    this.service.updateModel(this.data, this.record).subscribe(data => {
      this.list.component = 'model_list';
      this.list.pageEvent.pageIndex = 0;
      alert('Data Updated Successfully.');
    })
  }
}
