import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  @Input()
  modal: any;
  @Input()
  apieData: any;

  constructor(private auth: AngularFireAuth,private router: Router) { }

  ngOnInit() { }

  logout() {
    this.auth.signOut().then(() =>{
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate(['/sameRoute']));
    });
  }
}
