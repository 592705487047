/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { CodeService } from '../code.service';
import { ModelListComponent } from '../../model/model-list/model-list.component';
import { ModelService } from '../../model/model.service';

@Component({
  selector: 'app-code-list',
  templateUrl: './code-list.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './code-list.component.scss'],
})
export class CodeListComponent implements OnInit {
  loadingRouteConfig: boolean;
  refreshLoading: boolean;
  add: boolean;
  all_codes: any;

  @Input()
  data: any;

  @Input()
  component: any;
  model_id: any;
  model_name: any;


  constructor(private service: CodeService, private list: ModelListComponent, private model_service: ModelService) {
    this.loadingRouteConfig = true;
  }

  ngOnInit() {
    this.refreshLoading = true;
    // console.log(this.data);
    this.model_id = this.data.model_id;
    this.model_name = this.data.model_name;
    this.service.getAllCodes(this.model_id).subscribe((codes: any) => {
      // console.log(codes);
      if (codes.length === 0) {
        this.add = true;
        this.loadingRouteConfig = false;
        this.refreshLoading = false;
      }
      else {
        this.add = false;
        this.all_codes = codes;
        this.loadingRouteConfig = false;
        this.refreshLoading = false;
      }

    });

  }

  back() {
    this.list.component = this.model_service.base_component;
  }

  copyQuery(query){
    this.refreshLoading = true;
    // console.log(query);
    const newName = query.code.substring(0,query.code.length-7);
      // console.log(query);
      this.data = {
        ...this.data,
        query: query,
        newName: newName,
        model_id: this.model_id,
      _isRedirect: "code_list"
      }
      this.refreshLoading = false;
      this.component = 'code_add';
      // console.log(this.data.query);
  }

  addCode() {
    this.data = {
      ...this.data,
      model_id: this.model_id,
      _isRedirect: "code_list"
    };
    this.component = 'code_add';
  }

  viewCodeDetail(obj) {
    this.data = {
      ...this.data,
      model_id: this.model_id,
      code: obj,
      _isRedirect: "code_list"
    };
    this.component = 'code_view';
  }

  deleteCode(obj) {
    this.data = {
      ...this.data,
      model_id: this.model_id,
      code: obj,
      _isRedirect: "code_list"
    };
    this.component = 'code_delete';
  }

}
