import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';



import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { JitCompiler } from '@angular/compiler';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { Firebase } from '@ionic-native/firebase/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { LoginPageModule } from './login/login.module';
import { SharedModule } from './admin/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { FieldAddComponent } from './admin/shared/field/field-add/field-add.component';
import { FieldListComponent } from './admin/shared/field/field-list/field-list.component';
import { MlistComponent } from './admin/shared/multiple-content/mlist/mlist.component';
import { CodeListComponent } from './admin/shared/short-code/code-list/code-list.component';
import { ModelListComponent } from './admin/shared/model/model-list/model-list.component';
import { MaddComponent } from './admin/shared/multiple-content/madd/madd.component';
import { MultipleEditComponent } from './admin/shared/light-box/multiple-edit/multiple-edit.component';
import { ImageEditComponent } from './admin/shared/light-box/image-edit/image-edit.component';
import { MeditComponent } from './admin/shared/multiple-content/medit/medit.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDividerModule } from '@angular/material/divider';
import { MatNativeDateModule } from '@angular/material/core';





import { MaterialFileInputModule } from 'ngx-material-file-input';
import { RegisterComponent } from './login/register/register.component';
import { RedirectComponent } from './login/redirect/redirect.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { InstallComponent } from './install/install.component';
import { NotfoundComponent } from './error/notfound/notfound.component';
import { OtherComponent } from './error/other/other.component';
import { MaintenanceComponent } from './error/maintenance/maintenance.component';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { AceEditorModule } from 'ng2-ace-editor';
import {NgxPaginationModule} from 'ngx-pagination';
import { SplitViewModule } from 'ngx-split-view';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng9OdometerModule } from 'ng9-odometer';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    RedirectComponent,
    InstallComponent,
    MaintenanceComponent,
    NotfoundComponent,
    OtherComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IonicModule.forRoot({animated: false}),
    AppRoutingModule,
    NgxPaginationModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AngularEditorModule,
    LoginPageModule,
    SharedModule,
    BrowserAnimationsModule,
    AngularFireStorageModule,
    MaterialFileInputModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    NgxMatIntlTelInputModule,
    AceEditorModule,
    SplitViewModule,
    // FontAwesomeModule
    Ng9OdometerModule.forRoot()
  ],
  providers: [
    Firebase,
    StatusBar,
    SplashScreen,
    AppComponent,
    FieldAddComponent,
    FieldListComponent,
    MlistComponent,
    CodeListComponent,
    MaddComponent,
    ModelListComponent,
    MultipleEditComponent,
    ImageEditComponent,
    MeditComponent,
    AngularFireAuthGuard,
    NgxImageCompressService,
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy, useExisting: JitCompiler }
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
