/* eslint-disable */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paragraph-loader',
  templateUrl: './paragraph-loader.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css','./paragraph-loader.component.scss'],
})
export class ParagraphLoaderComponent implements OnInit {


  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }


  constructor() { }

  ngOnInit() {

  }

}
