import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatProgressBarModule, } from '@angular/material/progress-bar';
import { ParagraphLoaderComponent } from './paragraph-loader/paragraph-loader.component';
import { BtnLoaderComponent } from './btn-loader/btn-loader.component'
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatProgressBarModule
  ],
  declarations: [
      ParagraphLoaderComponent,
      BtnLoaderComponent
  ],
  exports: [
    ParagraphLoaderComponent,
    BtnLoaderComponent
  ]
})
export class LoaderModule { }