/* eslint-disable max-len */
/* eslint-disable */
import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SharedService } from '../../admin/shared/shared.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {

  loaderUrl: string;
  logo: string;
  loadingRouteConfig: boolean;

  constructor(private service: SharedService, private pageTitle: Title, @Inject(DOCUMENT) private doc,) { }

  createLink(type, url) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }

  ngOnInit() {
    this.pageTitle.setTitle('404-page not found');
    this.loaderUrl = 'https://firebasestorage.googleapis.com/v0/b/ ' + environment.firebase.storageBucket + '/o/APIE_ASSETS%2Floader.gif?alt=media' ;
    this.service.getSettings().subscribe((setting: any) => {
      if(setting.w_logo === undefined)
      {
        this.logo = '../../../assets/apie_img/apie-logo.png';
      }
      else
      {
        this.logo = setting.w_logo;
      }
      if(setting.w_fav === undefined)
      {
        this.createLink('icon','../../../assets/apie_img/apie-fav.png');
      }
      else
      {
        this.createLink('icon', setting.w_fav);
      }
      this.loadingRouteConfig = false;
      // console.log(this.logo)
    });
  }

}
