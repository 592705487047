/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../library.service';

@Component({
  selector: 'app-library-edit',
  templateUrl: './library-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css','./library-edit.component.scss'],
})
export class LibraryEditComponent implements OnInit {

  constructor(private service: LibraryService) { }

  ngOnInit() {

  }

}
