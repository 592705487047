/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { ModelService } from '../model.service';
declare var $ :any;
import { resolve4 } from 'dns';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './model-list.component.scss'],
})
export class ModelListComponent implements OnInit {
  @Input()
  modal: any;

  @Input()
  component: any;

  @Input()
  apieData: any;

  loadingRouteConfig: any;
  refreshLoading: any;
  lyaout_id: any;
  all_models: any = [];
  all_universal_models: any;
  add: boolean;
  data: any;
  searchbox: any = false;
  input_model: any;
  search_model: any = {
    pinned: [],
    notpinned: []
  };
  notFound: boolean;
  showclr: boolean;
  closeSearch: boolean;

  myProduct: any;
  redirect:any;
  pageSizeOptions = [10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100];
  pageEvent: PageEvent;

  searchIsFocused: any = false;

  constructor(private service: ModelService,private model_service: ModelService) {
    this.loadingRouteConfig = true;
    this.showclr = false;
    this.closeSearch = true;
    this.notFound = false;
  }

  ngOnInit() {
    this.refreshLoading = true;
    if(!this.pageEvent){
      this.pageEvent = {
        length: 0,
        pageSize: 10,
        pageIndex: 0
      };
    }
    var record = {
      uid: this.apieData.uid,
      pinned: "true"
    }
    this.service.getAllModels(record).subscribe((models: any) => {
      // console.log(models);
      if (models.notpinned.length <= 1 && models.pinned.length === 0) {
        this.add = true;
        this.loadingRouteConfig = false;
        this.refreshLoading = false;
      }
      else {
        this.add = false;
        this.all_models = models;
        if (this.all_models.pinned.length !== 0) {
          this.search_model.pinned = this.all_models.pinned;
          this.service.sortAlphabetically(this.all_models.pinned, "name");
        }
        if (this.all_models.notpinned.length !== 0) {
          this.search_model.notpinned = this.all_models.notpinned;
          this.service.sortAlphabetically(this.all_models.notpinned, "name");
        }
        this.loadingRouteConfig = false;
        this.refreshLoading = false;
      }
    })

  }

  onChange(event) {
    var result_pinned = this.search_model.pinned.filter(o =>  o.name.toLowerCase().includes(event.toLowerCase()));
    var result_unPinned = this.search_model.notpinned.filter(o =>  o.name.toLowerCase().includes(event.toLowerCase()));
    if (result_pinned.length !== 0) {
      this.all_models.pinned = result_pinned;
      this.notFound = false;
    }
    else {
      this.all_models.pinned = [];
    }
    if (result_unPinned.length !== 0) {
      this.all_models.notpinned = result_unPinned;
      this.notFound = false;
    }
    else {
      this.all_models.notpinned = [];
    }
    if (result_pinned.length == 0 && result_unPinned.length == 0) {
      this.notFound = true;
    }
    if ($("#searchbox").val().length > 0) {
      this.showclr = true
      this.closeSearch = false
    } else {
      this.showclr = false;
      this.closeSearch = true;
      this.all_models.pinned = this.search_model.pinned;
      this.all_models.notpinned = this.search_model.notpinned;
    }
  }

  cancel() {
    this.input_model = '';
    this.showclr = false;
    this.closeSearch = true;
    this.all_models.pinned = this.search_model.pinned;
    this.all_models.notpinned = this.search_model.notpinned;
  }

  Pin(sel_model) {
    var record = {
      uid: this.apieData.uid,
      model_id: sel_model.key
    }
    // console.log(record);
    this.service.updatePinnedCollection(record).subscribe((result: any) => {
      // console.log(result);
    });
    this.all_models.notpinned.map(model => {
      if (sel_model.key === model.key) {
        var index = this.all_models.notpinned.indexOf(model);
        this.all_models.notpinned.splice(index, 1);
        this.all_models.pinned.push(model);
        this.service.sortAlphabetically(this.all_models.pinned, "name");
      }
    });
  }

  unPin(sel_model) {
    var record = {
      uid: this.apieData.uid,
      model_id: sel_model.key
    }
    // console.log(record);
    this.service.updatePinnedCollection(record).subscribe((result: any) => {
      // console.log(result);
    });
    this.all_models.pinned.map(model => {
      if (sel_model.key === model.key) {
        var index = this.all_models.pinned.indexOf(model);
        this.all_models.pinned.splice(index, 1);
        this.all_models.notpinned.push(model);
        this.service.sortAlphabetically(this.all_models.notpinned, "name");
      }
    });

  }

  listField(model) {
    this.data = {
      ...this.data,
      model_id: model.key,
      model_name: model.name,
      _isRedirect: this.component
    };
    this.model_service.base_component = this.component;
    this.component = 'field_list';
  }

  makeRandomCode(length) {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  copyModel(model) {
    this.refreshLoading = true;
    // console.log(model);
    const newName = model.name + this.makeRandomCode(6);
    // console.log(model);
    this.data = {
      ...this.data,
      _isRedirect: 'model_list',
      model: model,
      newName: newName
    }
    this.refreshLoading = false;
    this.component = 'model_add';
    // console.log(this.data.model);
  }

  addModel() {
    this.data = {
      ...this.data,
      _isRedirect: 'model_list'
    };
    this.component = 'model_add';
  }

  listCode(model) {
    this.model_service.base_component = this.component;
    this.data = {
      ...this.data,
      model_id: model.key,
      model_name: model.name
    };
    this.component = 'code_list';
  }

  deleteModel(obj) {
    this.data = obj;
    this.component = 'model_delete';
  }

  listContent(model) {
    this.data = {
      ...this.data,
      model_id: model.key,
      collection: 'MD_' + model.name,
      model_name: model.name,
      _redirect : this.component,
      languages : this.apieData.languages
    };
    this.service.base_component = this.component;
    // console.log(this.data)
    this.component = 'mlist';
  }


  back(){
    this.modal.dismiss('Cross click');
  }

}

