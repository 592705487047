import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { RegisterComponent } from './login/register/register.component';
import { ForgotComponent } from './login/forgot/forgot.component';
import { RedirectComponent } from './login/redirect/redirect.component';
import { InstallComponent } from './install/install.component';
import { NotfoundComponent } from './error/notfound/notfound.component';
import { OtherComponent } from './error/other/other.component';
import { MaintenanceComponent } from './error/maintenance/maintenance.component';

import { HomePageModule } from "./home/home.module";
import { LoginPageModule } from "./login/login.module";


const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);


const routes: Routes = [
  // {
  //   path: 'admin',
  //   loadChildren: './admin/admin.module#AdminPageModule',
  //   canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  // },
  {
    path: 'install',
    component: InstallComponent
  },
  {
    path: '404',
    component: NotfoundComponent
  },
  {
    path: '500',
    component: OtherComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgot',
    component: ForgotComponent
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: 'login',
    loadChildren: () => LoginPageModule
  },
  {
    path: '',
    loadChildren:() => HomePageModule,
  },
  {
    path: ':page',
    loadChildren:() => HomePageModule,
  },
  {
    path: ':page/:page',
    loadChildren:() => HomePageModule,
  },
  {
    path: ':page/:page/:page',
    loadChildren:() => HomePageModule,
  },
  {
    path: ':page/:page/:page/:page',
    loadChildren:() => HomePageModule,
  },
  {
    path: ':page/:page/:page/:page/:page',
    loadChildren:() => HomePageModule,
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
