/* eslint-disable */
import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { McontentService } from '../mcontent.service';
declare var $ :any;
import { ModelListComponent } from '../../model/model-list/model-list.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModelService } from '../../model/model.service';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-mlist',
  templateUrl: './mlist.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './mlist.component.scss'],
})
export class MlistComponent implements OnInit {
  loadingRouteConfig: any;
  refreshLoading: any;
  page_id: string;
  multiple_contents: any = [];
  all_contents: any = [];
  objectKeys = Object.keys;
  @Input()
  data: any;
 
  @Input()
  component: any;
  model_id: any;
  collection: any;
  model_name: any;
  pageSizeOptions = [10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100];
  pageEvent: PageEvent;

  list_view: any;
  grid_view: any;
  table_view: any;
  dataSource = new MatTableDataSource(this.all_contents);
  displayedColumns: string[] = [];
  all_languages: any;
  all_language_contents:any = [];
  other_language_content:any = [];
  tab_disable:boolean;  
  isDefaultLanguageSelected:boolean = true;
  showclr: boolean;
  closeSearch: boolean;

  isStart: any;
  record: any = [];
  all_fields: any = [];
  model_type: any;
  removable: any = true;
  changedIdArray: any = [];


  static_single_dropdown_search: boolean = false;
  static_single_dropdown_tempArray: any;
  dynamic_single_dropdown_search: boolean = false;
  dynamic_single_dropdown_tempArray: any;
  static_multiple_dropdown_search: boolean = false;
  static_multiple_dropdown_tempArray: any;
  dynamic_multiple_dropdown_search: boolean = false;
  dynamic_multiple_dropdown_tempArray: any;

  isFirstInputField: boolean = true;
  all_dynamic_dropdown_field_name = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  newRowsIds: any = [];
  filter: any;

  @ViewChild('optInput', { static: true }) optInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @ViewChild('optInput2', { static: true }) optInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2', { static: true }) matAutocomplete2: MatAutocomplete;

  columnSearchValue = {};
  searchIsFocused: any = false;

  constructor(private service: McontentService,
    private list: ModelListComponent,
    private model_service: ModelService,
    private mcontent_service: McontentService,
    private _snackBar: MatSnackBar
  ) {
    this.showclr = false;
    this.closeSearch = true;
  }

  add(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
    }
    if (input) {
      input.value = '';
    }

    // this.form.controls[field_name].setValue('');
  }

  remove(opt: string, id, field_name): void {
    let promise = new Promise((resolve,reject)=>{
      if(this.dataSource.data.length !== 0){
        this.dataSource.data.forEach(obj => {
          if (obj['id'] === id) {
            const index = this.dataSource.data.indexOf(obj);
            // console.log(index);
            var option_array: any = [];
            if(this.dataSource.data[index][field_name]){
              option_array = this.dataSource.data[index][field_name];
            }
            else{
              option_array = [];
            }
            var result = {
              option_array: option_array,
              index: index
            }
            resolve(result)
          }
        });
      }
    });
    promise.then((result)=>{
      // console.log(result);
      var option_array = result['option_array'];
      if(option_array.length !== 0){
        option_array.forEach(obj => {
          if (obj == opt) {
            delete option_array[obj];
            this.dataSource.data[result['index']][field_name] = option_array;
            this.all_contents = this.dataSource.data;
          }
        });
      }
    });

    
  }

  selected(event, id, field_name): void {
    let promise = new Promise((resolve,reject)=>{
      if(this.dataSource.data.length !== 0){
        this.dataSource.data.forEach(obj => {
          if (obj['id'] === id) {
            const index = this.dataSource.data.indexOf(obj);
            const isExist = this.changedIdArray.indexOf(obj['id']);
          if(isExist == -1){
            this.changedIdArray.push(obj['id']);
            }
            // console.log(index);
            var option_array: any = [];
            var option_length: any;
            if(this.dataSource.data[index][field_name]){
              option_array = this.dataSource.data[index][field_name];
              option_length = option_array.length;
            }
            else{
              option_length = 0;
            }
            var result = {
              option_array: option_array,
              option_length: option_length,
              index: index
            }
            resolve(result)
          }
        });
      }
    });
    promise.then((result)=>{
      var option_array = result['option_array'];
      var option_length = result['option_length'];

      if (this.isArray(option_array) == false || option_length == 0) {
        option_array = [];
        option_array[option_length] = event.option.viewValue;
        this.dataSource.data[result['index']][field_name] = option_array;
        this.all_contents = this.dataSource.data;
      }
      else {
        var is_option_exist = false;
        option_array.forEach((obj, idx) => {
          if (obj == event.option.viewValue) {
            is_option_exist = true;
          }
          if (idx == option_length - 1) {
            if (is_option_exist == true) {
              this._snackBar.open('you have already selected this option', '', {
                duration: 2500,
                horizontalPosition: "left",
                panelClass: ['custom-snackbar']
              });
            }
            else {
              option_array[option_length] = event.option.viewValue;
              this.dataSource.data[result['index']][field_name] = option_array;
              this.all_contents = this.dataSource.data;
            }
          }
        });
  
      }
    });

  }


  add2(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      // this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }

  remove2(opt: string, id, field_name): void {
    let promise = new Promise((resolve,reject)=>{
      if(this.dataSource.data.length !== 0){
        this.dataSource.data.forEach(obj => {
          if (obj['id'] === id) {
            const index = this.dataSource.data.indexOf(obj);
            // console.log(index);
            var option_array: any = [];
            if(this.dataSource.data[index][field_name]){
              option_array = this.dataSource.data[index][field_name];
            }
            else{
              option_array = [];
            }
            var result = {
              option_array: option_array,
              index: index
            }
            resolve(result)
          }
        });
      }
    });
    promise.then((result)=>{
      // console.log(result);
      var option_array = result['option_array'];
      if(option_array.length !== 0){
        option_array.forEach(obj => {
          if (obj.id == opt['id']) {
            option_array = this.removeByAttr(option_array, 'id', obj.id);
            this.dataSource.data[result['index']][field_name] = option_array;
            this.all_contents = this.dataSource.data;
          }
        });
      }
    });
    
  }

  removeByAttr(arr, attr, value){
    var i = arr.length;
    while(i--){
       if( arr[i] 
           && arr[i].hasOwnProperty(attr) 
           && (arguments.length > 2 && arr[i][attr] === value ) ){ 

           arr.splice(i,1);

       }
    }
    return arr;
}

selected2(event: MatAutocompleteSelectedEvent, linked_field_name,id, field_name): void {
  let promise = new Promise((resolve,reject)=>{
    if(this.dataSource.data.length !== 0){
      this.dataSource.data.forEach(obj => {
        if (obj['id'] === id) {
          const index = this.dataSource.data.indexOf(obj);
          const isExist = this.changedIdArray.indexOf(obj['id']);
          if(isExist == -1){
            this.changedIdArray.push(obj['id']);
            }
          // console.log(index);
          var option_array: any = [];
          var option_length: any;
          if(this.dataSource.data[index][field_name]){
            option_array = this.dataSource.data[index][field_name];
            option_length = option_array.length;
          }
          else{
            option_length = 0;
          }
          var result = {
            option_array: option_array,
            option_length: option_length,
            index: index
          }
          resolve(result)
        }
      });
    }
  });
  promise.then((result)=>{
    // console.log(result);
    var option_array = result['option_array'];
    var option_length = result['option_length'];
    if (this.isArray(option_array) == false || option_length == 0) {
      // console.log('1');
      option_array = [];
      option_array[option_length] = {};
      option_array[option_length]['id'] = event.option.id;
      option_array[option_length][linked_field_name] = event.option.viewValue;
      this.dataSource.data[result['index']][field_name] = option_array;
      this.all_contents = this.dataSource.data;
    }
    else {
      // console.log('2');
      var is_option_exist = false;
      option_array.forEach((obj, idx) => {
        if (obj.id == event.option.id) {
          is_option_exist = true;
        }
        if (idx == option_length - 1) {
          // console.log(is_option_exist);
          if (is_option_exist == true) {
            this._snackBar.open('you have already selected this option', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
          }
          else {
            option_array[option_length] = {};
            option_array[option_length]['id'] = event.option.id;
            option_array[option_length][linked_field_name] = event.option.viewValue;
            this.dataSource.data[result['index']][field_name] = option_array;
            this.all_contents = this.dataSource.data;
          }
        }
      });


    }
  });

}

  leftNav() {
    $(".apie_navbar-nav").animate({ scrollLeft: "-=100px" });
  }

  rightNav() {
    $(".apie_navbar-nav").animate({ scrollLeft: "+=100px" });
  }
  @ViewChild( MatSort, {static: true }) sort: MatSort;
  @ViewChild(MatPaginator, {static: true} ) paginator: MatPaginator;
  

  ngOnInit() {
    if(!this.pageEvent){
      this.pageEvent = {
        length: this.all_contents.length,
        pageSize: 10,
        pageIndex: 0
      };
    }
    this.loadingRouteConfig = true;
    this.refreshLoading = true;

    this.all_languages = JSON.parse(this.data.languages);
    // console.log(this.all_languages);

     // this.dataSource = null;
    this.all_fields = [];
    this.all_contents = [];
    this.displayedColumns = [];
    this.newRowsIds = [];
    var current_content_view = localStorage.getItem('current_content_view');
    if(current_content_view){
      if(current_content_view == 'list_view'){
        this.list_view = true;
        }
        else{
          this.table_view = true;
        }
    }
    else{
      this.table_view = true;
    }
    
    this.model_id = this.data.model_id;
    this.collection = this.data.collection;
    this.model_name = this.data.model_name;
    if (this.model_name) {
      const dotIndex = this.model_name.indexOf(".");
      if (dotIndex !== -1) {
        var toRemove = this.model_name.substring(dotIndex - 7, dotIndex);
        var temp_model_name = this.model_name.replace(toRemove, '').trim();
        this.model_name = temp_model_name;
      }

    }

    this.service.getAllField(this.model_id).subscribe((fields: any) => {
      // console.log(fields);
      if(fields.data.length !== 0){
        fields.data.forEach((field, index) => {

          // ---------------------------------------------- validation ------------------------------------------------------
          if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
            field.isRequired = 'false';
          }
          if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
            field.requiredErrMsg = '';
          }
          if(field.patterns == '' || field.patterns == null || field.patterns == undefined){
            field.patterns = '';
          }
          if(field.patternsErrMsg == '' || field.patternsErrMsg == null || field.patternsErrMsg == undefined){
            field.patternsErrMsg = '';
          }
          if(field.minLength == '' || field.minLength == null || field.minLength == undefined){
            field.minLength = '0';
          }
          if(field.minLengthErrMsg == '' || field.minLengthErrMsg == null || field.minLengthErrMsg == undefined){
            field.minLengthErrMsg = '';
          }
          if(field.placeholder == '' || field.placeholder == null || field.placeholder == undefined){
            field.placeholder = '';
          }

          // ---------------------------------------------- End -------------------------------------------------------------
  
          if (field.type === 'dropdown' || field.type === 'multi_dropdown') {
            if (field.l_code !== undefined) {
              this.all_dynamic_dropdown_field_name.push(field.name);
              field.optionData = {
                data: { _data: [] }
              }
            }
            else {
              field.optionData = field.staticOption;
            }
          }
          else {
          }
          this.all_fields.push(field);
          if(index === fields.data.length - 1){
            this.optionApi();
          }
        });
      }

    });
    
    var record = {
      model_id: this.model_id,
      collection: this.collection
    }
    this.service.getAllContent(record).subscribe((contents: any) => {
      console.log(contents);
      this.all_language_contents = contents['data'];
      if(contents['data'].length > 0){
        this.all_contents = contents['data'].filter((item)=>item.default_content_id == undefined);
      }

      this.dataSource = new MatTableDataSource(this.all_contents);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.all_fields.forEach((field,index) => {
        if (field.name !== 'id') {
          if(index == 0){
            this.displayedColumns.push('Action');
          }
          this.displayedColumns.push(field.name);
        }
        // if(index == this.all_fields.length - 1){
        //   this.displayedColumns.push('Action');
        // }
      });
      this.loadingRouteConfig = false;
      this.refreshLoading = false;
    });


  }

  change_lang(event){
    this.all_contents = [];
    var lang_code;
    if(this.all_languages.default.language == event.tab.textLabel) {
      this.isDefaultLanguageSelected = true;
      lang_code = this.all_languages.default.code;
    }
    else{
      this.isDefaultLanguageSelected = false;
      this.all_languages.all_selected.forEach(__lang => {
        if(__lang.language == event.tab.textLabel){
          lang_code = __lang.code;
        }
      })
    }

    this.all_language_contents.forEach(_content => {
      if(_content._lang == lang_code){
        this.all_contents.push(_content);
        // console.log(this.all_contents);
      }
    })

    // console.log(this.paginator);
    this.changedIdArray = [];
    this.newRowsIds = [];
    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
}

  optionApi(){
      if (this.all_dynamic_dropdown_field_name.length !== 0) {
        this.all_dynamic_dropdown_field_name.forEach((field_name, index) => {
          let promise = new Promise((resolve, reject) => {
            this.all_fields.map((field, idx) => {
              let promise1 = new Promise((resolve1, reject1) => {
                if (field.name == field_name) {
                  const record = {
                    field_id: field.l_field_id,
                    code: field.l_code
                  }
                  this.service.getCodeContent(record).subscribe((codecontent: any) => {
                    resolve1(true);
                    if (codecontent.status === undefined) {
                      if(this.all_fields[idx]){
                        this.all_fields[idx].optionData = codecontent;
                      }
                    }
                  })

                }
                else{resolve1(true);}
              })
              promise1.then((result1: any) => {
                if (idx == this.all_fields.length - 1) { resolve(true); }

              })

            });
          });
          promise.then((result: any) => {
            if (index == this.all_dynamic_dropdown_field_name.length - 1) {
              this.isFirstInputField = false;
            }
          });

        });
      }
  }

  onDropdownChange(event,field_name){
        this.updateDropdownOptions(field_name, event.target.value);
  }

  applyFilter(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
    this.filter = this.dataSource.filter;
    if ($("#searchbox").val().length > 0) {
      this.showclr = true;
      this.closeSearch = false;
    } else {
      this.showclr = false;
      this.closeSearch = true;
    }
  }

  applyColumnFilter(event,field_name,col_idx){
    console.log('Hello 1');
    this.columnSearchValue[field_name] = event.target.value;
    console.log(this.columnSearchValue);
    // this.dataSource.filter = JSON.stringify(this.columnSearchValue);
    this.dataSource.filterPredicate(this.all_contents,JSON.stringify(this.columnSearchValue));
    // this.dataSource.filterPredicate(this.all_contents,event.target.value);


    this.dataSource.filterPredicate = (data: any, filter: string)  => {
      console.log('Hello 2');
      var filterJSON = JSON.parse(filter);


      if (data.length !== 0) {
        data.forEach((content, idx) => {
          // console.log(content);
          Object.keys(filterJSON).forEach(filtered_name => {
            if (content[filtered_name].includes(filterJSON[filtered_name])) {
              console.log('true');
              console.log(content);
              return true;
            }
            else {
              return false;
            }
          });
        });
      }
      else {
        return false;
      }
     
     
    };
    
  }

  cancel() {
    this.showclr = false;
    this.closeSearch = true;
    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  mlistEditorPage(editor_value, id, field_name){
    this.table_view = false;
    if(editor_value == null || editor_value == undefined){
      editor_value = '';
    }

    if(this.all_contents.length !== 0){
      this.all_contents.forEach(obj => {
        if (obj.id === id) {
          const index = this.all_contents.indexOf(obj);
          // console.log(index);
          this.data = {
            ...this.data,
            index: index,
          }
          const isExist = this.changedIdArray.indexOf(obj.id);
          if(isExist == -1){
            this.changedIdArray.push(obj.id);
            }
        }
      });
    }

    this.data = {
     ...this.data,
     type: 'mlist',
      editor_value: editor_value,
      key: field_name
    };
    // console.log(this.data)
    this.component = 'mlist_editor';
  }

  callLibrary(id, field_name) {
    this.table_view = false;
    if(this.all_contents.length !== 0){
      this.all_contents.forEach(obj => {
        if (obj.id === id) {
          const index = this.all_contents.indexOf(obj);
          // console.log(index);
          this.data = {
            ...this.data,
            index: index,
          }
          const isExist = this.changedIdArray.indexOf(obj.id);
          if(isExist == -1){
            this.changedIdArray.push(obj.id);
            }
        }
      });
    }
    this.data = {
       ...this.data,
      _type: "mlist",
      _isDirected: true,
      _isSingle: true,
      key: field_name
    }
    this.component = 'library_list';
  }

  setImage(event) {
    this.refreshLoading = true;
    // console.log(event);
    this.all_contents[this.data.index][this.data.key] = event;
    this.table_view = true;
    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.refreshLoading = false;
  }

  setEditor(event) {
    this.refreshLoading = true;
    // console.log(event);
    this.all_contents[this.data.index][this.data.key] = event;
    this.table_view = true;
    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.refreshLoading = false;
  }

  sortContent(name) {
    this.all_contents = this.all_contents.sort(
      function (a, b) {
        if (a[name].toLowerCase() < b[name].toLowerCase()) {
          return -1;
        } else if (a[name].toLowerCase() > b[name].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    );
    if (this.table_view === true) {
      this.dataSource = new MatTableDataSource(this.all_contents);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.filter;
    }
  }

  listView() {
    this.list_view = true;
    this.grid_view = false;
    this.table_view = false;
    localStorage.setItem('current_content_view','list_view');
    // if(this.all_language_contents){
    //  this.all_contents = this.all_language_contents.filter((item)=>item.default_content_id == undefined);
    // }
  }

  gridView() {
    this.list_view = false;
    this.grid_view = true;
    this.table_view = false;
    localStorage.setItem('current_content_view','grid_view');
  }

  tableView() {
    // console.log("hii");
    this.list_view = false;
    this.grid_view = false;
    this.table_view = true;
    localStorage.setItem('current_content_view','table_view');
  }

  selectToString(array) {
    // console.log(array);
    // console.log(array.map(o => o.value).toString());
    return array.map(o => o.name).toString();

  }

  typeOf(value) {
    return typeof value;
  }

  isArray(obj) {
    return Array.isArray(obj);
  }

  toggle(content) {
    if(this.newRowsIds.includes(content.id)){
      if(this.all_contents.length !== 0){
        this.all_contents.forEach(obj => {
          if (obj.id === content.id) {
            obj['isActive'] = content.isActive;
            this.dataSource = new MatTableDataSource(this.all_contents);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.filter = this.filter;
            this.refreshLoading = false;
          }
          else{
            this.refreshLoading = false;
            }
        });
      }
     }
     else{
      const record = {
        collection: this.collection,
        content_id: content.id,
        isActive: content.isActive
      };
      this.service.updateActivity(record).subscribe((actibity: any) => {
      });
     }
    
  }

  copyMContent(content) {
  //  console.log(content);
    if(this.table_view == true){
        this.add_copy_row(content);
    }
    else{
      this.refreshLoading = true;
      var record = {
        "model_id": this.model_id,
        "collection": this.collection,
        "content_id": content.id
      }
      this.service.getSingleContentDetails(record).subscribe((content: any) => {
        // console.log(content);
        this.data = {
          ...this.data,
          content: content,
          model_id: this.model_id,
          collection: this.collection,
          model_name: this.model_name,
          _isRedirect: 'mlist',
        }
        this.refreshLoading = false;
        this.component = 'madd';
      });
    }

  }

  addContent() {
    this.data = {
      ...this.data,
      model_id: this.model_id,
      collection: this.collection,
      model_name: this.model_name,
      _isRedirect: 'mlist',
      languages : JSON.stringify(this.all_languages)
    };
    // console.log(this.data)
    this.component = 'madd';
  }

  back() {
    // console.log(this.model_service.base_component);
    this.list.component = this.model_service.base_component;
  }

  editMultipleContent(content_id: any) {
    // console.log(content_id);
    this.data = {
      ...this.data,
      model_id: this.model_id,
      collection: this.collection,
      model_name: this.model_name,
      content_id,
      _isRedirect: "mlist",
      languages : JSON.stringify(this.all_languages)
    };
    this.component = 'medit';
  }

  deleteContent(content_id: any) {
    if(this.newRowsIds.includes(content_id)){
     this.setDeleteField(content_id);
    }
    else{
      this.data = {
        ...this.data,
        model_id: this.model_id,
        collection: this.collection,
        _isRedirect: 'mlist',
        content_id,
      };
      this.component = 'mdelete';
    }
  }

  setDeleteField(content_id) {
    this.refreshLoading = true;
    if(this.all_contents.length !== 0){
      this.all_contents.forEach(obj => {
        if (obj.id === content_id) {
          this.all_contents = this.removeByAttr(this.all_contents, 'id', obj.id);
          this.dataSource = new MatTableDataSource(this.all_contents);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.filter = this.filter;
          this.refreshLoading = false;
        }
        else{
          this.refreshLoading = false;
          }
      });
    }
    else{
    this.refreshLoading = false;
    }

  }

  change_value(event, key, field_name) {
    if(this.all_contents.length !== 0){
      this.all_contents.forEach(obj => {
        if (obj.id === key) {
          const index = this.all_contents.indexOf(obj);
          if(index !== -1){
          this.all_contents[index][field_name] = event.target.value;
          }
          const isExist = this.changedIdArray.indexOf(obj.id);
          if(isExist == -1){
            this.changedIdArray.push(obj.id);
            }
        }
      });
    }
    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
  }
  
  onOptionSelect(event, key, field_name) {
    // console.log(event.option.value);
    if(this.all_contents.length !== 0){
      this.all_contents.forEach(obj => {
        if (obj.id === key) {
          const index = this.all_contents.indexOf(obj);
          // console.log(index);
          if(index !== '' && index !== null && index !== undefined){
          this.all_contents[index][field_name] = event.option.value;
          // console.log('matched');
          // console.log(this.all_contents[index][field_name]);
          const isExist = this.changedIdArray.indexOf(obj.id);
          if(isExist == -1){
            this.changedIdArray.push(obj.id);
            }
          this.dataSource = new MatTableDataSource(this.all_contents);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.filter = this.filter;

          }
        }
      });
    }
   
  }

  add_row(isActive) {

    var data1 = {}

    // console.log(this.all_fields);
    if(this.all_fields){
      if(this.all_fields.length !== 0){
        this.all_fields.forEach(field => {
          if(field.type == 'multi_dropdown' || field.type == 'multi_file'){
            data1[field.name] = [];
          }
          else{
            data1[field.name] = '';
          }
        });
      }
    }

    data1['collection'] = this.collection;

    data1['collection_id'] = "";

    data1['createdAt'] = "";

    var new_row_id = this.makeid(6); 
    this.newRowsIds.push(new_row_id);
    data1['id'] = new_row_id;

    data1['isActive'] = isActive;

    // this.all_contents.push(data1);
    this.all_contents.splice(0, 0, data1);

    this.dataSource = new MatTableDataSource(this.all_contents);
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.sort.direction = "asc";
    this.dataSource.sort = this.sort;
    this.filter = '';
    this.dataSource.filter = this.filter;

  }

  add_copy_row(content) {

    var data1 = {}

    // console.log(this.all_fields);
    if(this.all_fields){
      if(this.all_fields.length !== 0){
        this.all_fields.forEach(field => {
          if(field.type == 'multi_dropdown' || field.type == 'multi_file'){
            data1[field.name] = content[field.name];
          }
          else{
            data1[field.name] = content[field.name];
          }
        });
      }
    }

    data1['collection'] = this.collection;

    data1['collection_id'] = "";

    data1['createdAt'] = "";

    var new_row_id = this.makeid(6); 
    this.newRowsIds.push(new_row_id);
    data1['id'] = new_row_id;

    data1['isActive'] = content.isActive;

    // this.all_contents.push(data1);
    this.all_contents.splice(0, 0, data1);

    this.dataSource = new MatTableDataSource(this.all_contents);
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.sort.direction = "asc";
    this.dataSource.sort = this.sort;
    // this.filter = '';
    this.dataSource.filter = this.filter;

  }

  makeid(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  delete_row(i) {
    this.all_contents.splice(i, 1);

    // this.dataSource = this.all_contents;

    // delete this.all_contents.paginator;

    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;

  }

  update() {
    this.tab_disable = true;
    let promise = new Promise((resolve,reject)=>{
      if(this.newRowsIds.length !==  0){
        if(this.dataSource.data.length !==  0){
          this.dataSource.data.forEach((content,idx) => {
            const isNew = this.newRowsIds.indexOf(content['id']);
            if(isNew !== -1){
              content['id'] = '';
            }
            if(idx == this.dataSource.data.length - 1){
              resolve(true);
            }
          });
        }
        else{
          resolve(true);
        }
      }
      else{
        resolve(true);
      }
    });
    promise.then((result)=>{
      var newData = {
        data : [],
        languages : this.all_languages
      }
      // console.log(this.dataSource.data);
      delete this.dataSource.paginator;
      for (let index = 0; index < this.dataSource.data.length; index++) {
        if(this.dataSource.data[index]['id'] !== ''){
          const isExist = this.changedIdArray.indexOf(this.dataSource.data[index]['id']);
          if(isExist !== -1){
            newData.data.push(this.dataSource.data[index]);
            }
        }
        else{
          this.dataSource.data[index]['_lang'] = this.all_languages.default.code;
          newData.data.push(this.dataSource.data[index]);
        }
      }
      // console.log(this.dataSource.data);
      // console.log(this.changedIdArray);
      console.log(newData);
      if(newData.data.length == 0){
        this._snackBar.open('No changes found', '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.tab_disable = false;
      }
      else{
        this.mcontent_service.addUpdateContent(newData).subscribe(respond => {
          if(respond['status'] == "succcess"){
            this._snackBar.open('Content updated Successfully', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
            this.newRowsIds = [];
            this.list.component = this.model_service.base_component;
            // this.ngOnInit();
            // this.ngAfterViewInit();
            // this.tableView();
          }
          this.tab_disable = false;
        });
      }
    }); 
  }

  updateDropdownOptions(field_name, value) {
    // console.log(field_name);
    // this.emptyObject = false;
    this.static_single_dropdown_search = false;
    this.static_single_dropdown_tempArray = null;
    this.dynamic_single_dropdown_search = false;
    this.dynamic_single_dropdown_tempArray = null;
    this.static_multiple_dropdown_search = false;
    this.static_multiple_dropdown_tempArray = null;
    this.dynamic_multiple_dropdown_search = false;
    this.dynamic_multiple_dropdown_tempArray = null;
    try {
      this.all_fields.map(field => {
        if (field.name == field_name) {
          if (field.type == 'dropdown') {
            if (this.isArray(field.optionData) == true) {
              if (value == "") {
                this.static_single_dropdown_search = false;
                // this.form.controls.field_name.setValue(null);
              }
              else {
                this.static_single_dropdown_search = true;
                this.static_single_dropdown_tempArray = null;
                this.static_single_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(value.toLowerCase()));
              }
            }
            else {
              if (value == "") {
                this.dynamic_single_dropdown_search = false;
                // this.form.controls.field_name.setValue(null);
              }
              else {
                this.dynamic_single_dropdown_search = true;
                this.dynamic_single_dropdown_tempArray = null;
                this.dynamic_single_dropdown_tempArray = {
                  optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                }
                const linked_field_name = field.optionData.field;
                field.optionData.data._data.map(o => {
                  if (o[linked_field_name].toLowerCase().includes(value.toLowerCase())) {
                    this.dynamic_single_dropdown_tempArray.optionData.data._data.push(o);
                  }
                });
              }
            }


          }

          else if (field.type == 'multi_dropdown') {
            if (this.isArray(field.optionData) == true) {
              if (value == "") {
                this.static_multiple_dropdown_search = false;
              }
              else {
                this.static_multiple_dropdown_search = true;
                this.static_multiple_dropdown_tempArray = null;
                this.static_multiple_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(value.toLowerCase()));
              }
            }
            else {
              if (value == "") {
                this.dynamic_multiple_dropdown_search = false;
              }
              else {
                this.dynamic_multiple_dropdown_search = true;
                this.dynamic_multiple_dropdown_tempArray = null;
                this.dynamic_multiple_dropdown_tempArray = {
                  optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                }
                const linked_field_name = field.optionData.field;
                field.optionData.data._data.map(o => {
                  if (o[linked_field_name].toLowerCase().includes(value.toLowerCase())) {
                    this.dynamic_multiple_dropdown_tempArray.optionData.data._data.push(o);
                  }
                });
              }
            }
          }
          else {
            this.static_single_dropdown_search = false;
            this.dynamic_single_dropdown_search = false;
            this.static_multiple_dropdown_search = false;
            this.dynamic_multiple_dropdown_search = false;
          }
        }
      });
    } catch { }
  }



}
