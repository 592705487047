/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable */
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../admin/shared/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  wait: any;
  hide = true;
  email: any;
  password: any;
  error: any;
  logo: any;
  loadingRouteConfig: boolean;
  loaderUrl: string;


  constructor(private router: Router, private fireauth: AngularFireAuth,@Inject(DOCUMENT) private doc,private pageTitle: Title,
    private _snackBar: MatSnackBar, private service: SharedService) {
    this.wait = false;
    this.loadingRouteConfig = true;
  }

  createLink(type, url) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }

  ngOnInit() {
    // this.fireauth.authState.subscribe(user => {
    //   if(user === null)
    //   {
    //     this.router.navigate(['/']);
    //     this.loadingRouteConfig = false;
    //   }
    // })
    // this.pageTitle.setTitle("Login to Site");
    this.loaderUrl = 'https://firebasestorage.googleapis.com/v0/b/ ' + environment.firebase.storageBucket + '/o/APIE_ASSETS%2Floader.gif?alt=media' ;
    this.service.getSettings().subscribe((setting: any) => {
      if (setting.w_logo === undefined) {
        this.logo = '../../assets/apie_img/apie-logo.png';
      }
      else {
        this.logo = setting.w_logo;
      }
      if(setting.w_fav === undefined)
      {
        this.createLink('icon','../../assets/apie_img/apie-fav.png');
      }
      else
      {
        this.createLink('icon', setting.w_fav);
      }
      this.loadingRouteConfig = false;
    });
  }


  login() {
    this.wait = true;
    this.fireauth.signInWithEmailAndPassword(this.email, this.password).then(res => {
      if (res.user) {
        this.wait = false;
        this._snackBar.open('Login Success', '', {
          duration: 2500,
          horizontalPosition: 'center',
          panelClass: ['custom-snackbar']
        });
        this.router.navigate(['/']);
      }
    })
      .catch(err => {
        this.wait = false;
        this.error = err.message;
        this._snackBar.open('Login failed -' + this.error, '', {
          duration: 2500,
          horizontalPosition: 'center',
          panelClass: ['custom-snackbar']
        });
      });

  }

}
