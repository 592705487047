/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { CodeService } from '../code.service';

@Component({
  selector: 'app-code-edit',
  templateUrl: './code-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css','./code-edit.component.scss'],
})
export class CodeEditComponent implements OnInit {

  constructor(private service: CodeService) { }

  ngOnInit() {

  }

}
