/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  host = environment.host;

  constructor(private httpClient: HttpClient) { }

  getSettings() {
    return this.httpClient.get(`${this.host + 'settings/'}`);
  }
  getOneSetting(setting_id) {
    return this.httpClient.get(`${this.host + 'settings/'}` + setting_id);
  }
  updateSetting(record) {
    return this.httpClient.put(`${this.host + 'settings/update'}` , record);
  }
  getAllPages() {
    return this.httpClient.get(`${this.host + 'pages/'}`);
  }
  installWebsite(record) {
    return this.httpClient.post(`${this.host + 'settings/install'}`,record);
  }
  moveLibrary(record) {
    return this.httpClient.put(`${this.host + 'library/move'}`, record);
  }

  addUser(record) {
    // console.log(record)
    return this.httpClient.post(`${this.host + 'users/add'}`, record);
  }

  getAllUserInvitation(token) {
    return this.httpClient.get(`${this.host + 'users/invitation/'}` + token);
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  updateLanguage(record) {
    // console.log(record);
    return this.httpClient.post(`${this.host + 'settings/updateversion'}` ,record);
  }

}
