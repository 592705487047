/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { FieldService } from '../field.service';
import { FieldListComponent } from '../field-list/field-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-field-edit',
  templateUrl: './field-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './field-edit.component.scss'],
})
export class FieldEditComponent implements OnInit {
  name: any;

  @Input()
  data1: any;

  loadingRouteConfig: any;
  requiredErrMsg: any;
  patterns: any;
  patternsErrMsg: any;
  minLength: any;
  minLengthErrMsg: any;
  isRequired: any;

  record: any = [];
  spinner: boolean;
  order: any;
  type: any;
  placeholder: any;
  l_code: any;
  staticOption: any = [];
  static_option_input: any;
  static_prev_length: any;

  constructor(private _snackBar: MatSnackBar, private service: FieldService, private list: FieldListComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  ngOnInit() {
    if (this.data1) {
      if (this.data1.field) {
        // console.log(this.data1.field);
        this.name = this.data1.field.name;
        this.patterns = this.data1.field.patterns;
        this.order = this.data1.field.order
        if (this.data1.field.isRequired) {
          this.isRequired = this.data1.field.isRequired;
        }
        else {
          this.isRequired = 'false';
        }
        this.type = this.data1.field.type;
        this.patternsErrMsg = this.data1.field.patternsErrMsg;
        this.requiredErrMsg = this.data1.field.requiredErrMsg;
        this.minLength = this.data1.field.minLength;
        this.minLengthErrMsg = this.data1.field.minLengthErrMsg;
        this.placeholder = this.data1.field.placeholder;
        this.l_code = this.data1.field.l_code;
        this.staticOption = this.data1.field.staticOption;
        if(this.data1.field.staticOptionLength){
          this.static_prev_length = this.data1.field.staticOptionLength;
        }
        else{
          if(this.data1.field.staticOption){
            this.data1.field.staticOptionLength = this.data1.field.staticOption.length;
            this.static_prev_length = this.data1.field.staticOption.length;
          }
          
        }
        this.loadingRouteConfig = false;
      }
      else {
        this.loadingRouteConfig = false;
      }
    }
    else {
      this.loadingRouteConfig = false;
    }
  }
  back() {
    this.list.component = 'field_list';
  }

  addStaticOption() {
    if (this.static_option_input !== '' && this.static_option_input !== null && this.static_option_input !== undefined) {
      if (this.staticOption) {
        this.staticOption.push(this.static_option_input);
        this.static_option_input = '';
      }
    }
    else {
      this._snackBar.open('Option can not be blank.', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }

  }

  deleteStaticOption(index) {
    this.staticOption.splice(index, 1);
  }

  updateField() {
    this.spinner = true;
    this.record = {
      "name": this.name,
      "patterns": this.patterns,
      "patternsErrMsg": this.patternsErrMsg,
      "requiredErrMsg": this.requiredErrMsg,
      "minLength": this.minLength,
      "minLengthErrMsg": this.minLengthErrMsg,
      "placeholder": this.placeholder,
      "order": this.order,
      "isRequired": this.isRequired,
      "field_id": this.data1.field.key,
      "model_id": this.data1.model_id,
    };
    if(this.staticOption){
    if(this.staticOption.length !== 0){
      this.record.staticOption = this.staticOption;
    }
    }
    // console.log(this.record);
    this.service.updateField(this.record).subscribe(data => {
      this.spinner = false;
      this._snackBar.open('Field Updated Successfully.', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      delete this.data1.field.staticOptionLength;
      this.list.ngOnInit();
      this.list.component = 'field_list';
    })
  }
}
