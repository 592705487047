/* eslint-disable */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  email: any;
  loadingRouteConfig: boolean;
  o_id: any;
  link: any;
  loaderUrl: string;

  constructor(private route: ActivatedRoute, private router: Router, private fireauth: AngularFireAuth) {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.link = params['link'];
    });
    this.loadingRouteConfig = true;
  }

  ngOnInit() {
    this.loaderUrl = "https://firebasestorage.googleapis.com/v0/b/ " + environment.firebase.storageBucket + "/o/APIE_ASSETS%2Floader.gif?alt=media" ;
    // this.service.getUid(this.email).subscribe((data: any) => {
      this.router.navigate([this.link + '/' + this.email]);
      this.loadingRouteConfig = false;
    // });
  }
}
