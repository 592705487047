/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { ModelService } from '../model.service';
import { ModelListComponent } from '../model-list/model-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-model-delete',
  templateUrl: './model-delete.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './model-delete.component.scss'],
})
export class ModelDeleteComponent implements OnInit {
  loadingRouteConfig: any;
  name: any;
  confirm_name: any;

  @Input()
  data: any;
  spinner: boolean;

  constructor(private _snackBar: MatSnackBar, private service: ModelService, private list: ModelListComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  ngOnInit() {
      this.name = this.data.name;
      this.loadingRouteConfig = false;
  }

  back() {
    this.list.component = 'model_list';
    this.list.pageEvent.pageIndex = 0;
  }
  delete() {
    this.spinner = true;
    if (this.name.trim() === this.confirm_name.trim()) {
      // console.log(this.data);
      this.service.deleteModel(this.data.key).subscribe(data => {
        // console.log("hii");
        // console.log(data);
        this.spinner = false;
        this._snackBar.open('Collection Deleted Successfully', '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.list.ngOnInit();
        this.list.component = 'model_list';
        this.list.pageEvent.pageIndex = 0;
      })
    }
    else {
      this.spinner = false;
      this._snackBar.open('please provide correct name', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }
  }

}


