/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { LibraryListComponent } from '../library-list/library-list.component';
import { LibraryService } from '../library.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-library-delete',
  templateUrl: './library-delete.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css','./library-delete.component.scss'],
})
export class LibraryDeleteComponent implements OnInit {

  @Input()
  data: any;
  loadingRouteConfig: boolean;
  spinner: boolean;

  constructor(private _snackBar: MatSnackBar, private service: LibraryService, private list: LibraryListComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  ngOnInit() {
    // console.log(this.data);
    this.loadingRouteConfig = false;
  }


  delete() {
    this.spinner = true;
    this.data.imageName.forEach(image => {
      var record = {
        fileName: image
      }
      this.service.deleteLibrary(record).subscribe((items: any) => {
        this.spinner = false;
        this._snackBar.open('Selected library files deleted successfully', '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.list.ngOnInit();
        this.list.component = 'library_list'
      })
    });
  }


  back() {
    this.list.component = 'library_list'
  }
}
