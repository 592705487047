import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CodeService {

  host = environment.host;

  constructor(private httpClient: HttpClient) { }
  addCode(record, model_id) {
    return this.httpClient.post(`${this.host + "query/add/"}` + model_id, record);
  }
  getAllField(model_id) {
    return this.httpClient.get(`${this.host + "field/modelfield/"}` + model_id);
  }
  getAllCodes(model_id) {
    return this.httpClient.get(`${this.host + "query/"}` + model_id);
  }
  deleteCode(code_id) {
    return this.httpClient.delete(`${this.host + "query/delete/"}` + code_id);
  }
  getOneCode(code_id) {
    return this.httpClient.get(`${this.host + "query/getone/"}` + code_id);
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}
