const projectId = 'incsolutions-c5a08';
import * as crypto from 'crypto-js';

// const host = 'http://localhost:5000/' + projectId + '/us-central1/apie/api/';
const host = 'https://us-central1-' + projectId + '.cloudfunctions.net/app/api/';

const getConfig = () =>
{
  let config: string;
  const request = new XMLHttpRequest();
  try
  {
    request.open('GET', host + 'config', false);  // `false` makes the request synchronous
    request.send(null);

    if (request.status === 200)
    {
      config = request.responseText;
    }

    return JSON.parse(config);
  } catch (e)
  {
    console.error('environment:getConfig: unable to get api key : ', e);
  }

  return config;
};
const firbase: any = crypto.AES.decrypt(getConfig(), 'fsfsfdsfsrewdwdxcasdwdfeftgrefewddw');
const envData = {
  production: false,
  projectId,
  host,
  firebase: JSON.parse(firbase.toString(crypto.enc.Utf8)).firebase,
};
export const environment = envData;
