/* eslint-disable */
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../admin/shared/shared.service';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss'],
})
export class InstallComponent implements OnInit {
  installLOader: boolean;
  email: any;
  password: any;
  name: any;
  number: any;
  repassword: any;
  title: any;
  metaTags: any;
  hideSetting: boolean;
  type: any = 'common';
  hide = true;
  rehide = true;
  passwordError: any = {};
  isPasswordInvalid: any;

  FullScreen: boolean;
  Normal: boolean;
  options: any = {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true
  };

  constructor(private router: Router, private service: SharedService, private fireauth: AngularFireAuth, private pageTitle: Title,
    private route: ActivatedRoute, private _snackBar: MatSnackBar, @Inject(DOCUMENT) private doc) {
    this.installLOader = true;
    this.hideSetting = true;
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
    });
    this.FullScreen = true;
    this.Normal = false;
    this.metaTags = `{"keyword": "Apie"}`;
    
    this.loadScript('mode-javascript.js');
    this.loadScript('mode-html.js');
    this.loadScript('mode-css.js');
    this.loadScript('mode-scss.js');
    this.loadScript('mode-json.js');
    this.loadScript('theme-github.js');
    this.loadScript('ext-searchbox.js');
    this.loadScript('ext-options.js');
    this.loadScript('ext-settings_menu.js');
    this.loadScript('ext-spellcheck.js');
    this.loadScript('ext-statusbar.js');
    this.loadScript('ext-prompt.js');
    this.loadScript('ext-keybinding_menu.js');
    this.loadScript('ext-language_tools.js');
    this.loadScript('ext-error_marker.js');
    this.loadScript('keybinding-vscode.js');
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = false;
    body.appendChild(script);
  }

  createLink(type, url) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }


  ngOnInit() {
    // this.pageTitle.setTitle("Install Your Site");
    this.createLink("icon","../../assets/apie_img/apie-fav.png");
    this.service.getSettings().subscribe((setting: any) => {
      if (setting.msg === undefined) {
        this.router.navigate(['/']);
      }
      this.installLOader = false;
    });
  }

  validatePassword(password: string) {
    let hasLower = false;
    let hasUpper = false;
    let hasNum = false;
    let hasSpecial = false;
    let hasEight = false;

    const lowercaseRegex = new RegExp("(?=.*[a-z])");// has at least one lower case letter
    if (lowercaseRegex.test(password)) {
      hasLower = true;
    }

    const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
    if (uppercaseRegex.test(password)) {
      hasUpper = true;
    }

    const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
    if (numRegex.test(password)) {
      hasNum = true;
    }

    const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");// has at least one special char
    if (specialcharRegex.test(password)) {
      hasSpecial = true;
    }

    if(password){
      if(password.length >= 8){
        hasEight = true;
      }
    }
    

    if(hasLower == false || hasUpper == false || hasNum == false || hasSpecial == false || hasEight == false)
    {
      this.isPasswordInvalid = true;
    }
    else{
      this.isPasswordInvalid = false;
    }
      this.passwordError = {
        hasLower: hasLower,
        hasUpper: hasUpper,
        hasNum: hasNum,
        hasSpecial: hasSpecial,
        hasEight: hasEight
      }

  }

  next() {
    this.hideSetting = false;
  }

  submit() {

    this.installLOader = true;
    var metaTagsResponce = this.isMetaTagsCorrect();

    // const metaData = JSON.parse(this.metaTags);
    if (metaTagsResponce.isCorrect == true) {
      const metaData = JSON.parse(this.metaTags);
      var user = {
        name: this.name,
        email: this.email,
        number: this.number,
      }
      var setting = {
        "title": this.title,
        "metaData": metaData,
        "s_mail": this.email,
      }
  
      if (this.password === this.repassword) {
        this.fireauth.createUserWithEmailAndPassword(this.email, this.password).then((res: any) => {
          // console.log(res);
          var record = {
            user: user,
            setting: setting,
            uid: res.user.uid,
          }
          // console.log(record)
          this.service.installWebsite(record).subscribe((install: any) => {
            this.installLOader = false;
            this.router.navigate(['/' + install.page]);
            this._snackBar.open(install.msg, '', {
              duration: 2500,
              horizontalPosition: "center",
              panelClass: ['custom-snackbar']
            });
          })
        }).catch(err => {
          this.installLOader = false;
          this._snackBar.open('Login failed -' + err.message, '', {
            duration: 2500,
            horizontalPosition: "center",
            panelClass: ['custom-snackbar']
          });
        });
      }
      else {
        this.installLOader = false;
        this._snackBar.open('Enter Correct Password', '', {
          duration: 2500,
          horizontalPosition: "center",
          panelClass: ['custom-snackbar']
        });
      }
    }
    else {
      this._snackBar.open('Please correct your metadata (ERROR LINE NO = ' + metaTagsResponce.error_line_no + ')', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      this.installLOader = false;
    }
   

  }

  isMetaTagsCorrect() {
    var mtaTagsEditor = document.querySelector('#metaTags');
    if (mtaTagsEditor.querySelector('div.ace_gutter-cell.ace_error')) {
      var innerHtmlOfMetaTagError = mtaTagsEditor.querySelector('div.ace_gutter-cell.ace_error').innerHTML.trim();
      const indexOfTag = innerHtmlOfMetaTagError.indexOf('<');
      var error_line_no = innerHtmlOfMetaTagError.substring(0, indexOfTag);
      var record1 = {
        isCorrect: false,
        error_line_no: error_line_no
      }
      return record1;
    }
    else {
      var record2 = {
        isCorrect: true,
        error_line_no: ''
      }
      return record2
    }
  }

  viewFullScreen(id) {
    let elem = document.getElementById(id);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      this.Normal = true;
      this.FullScreen = false;
    }
  }
  backtoNormal() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      this.Normal = false;
      this.FullScreen = true;
    }
  }


}
