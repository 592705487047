/* eslint-disable */
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FieldService } from '../field.service';
import { FieldListComponent } from '../field-list/field-list.component';
import { ModelListComponent } from '../../model/model-list/model-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MultipleEditComponent } from '../../light-box/multiple-edit/multiple-edit.component';

@Component({
  selector: 'app-field-add',
  templateUrl: './field-add.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './field-add.component.scss'],
})
export class FieldAddComponent implements OnInit {
  name: any;
  placeholder: any;
  staticOption: any;

  @Input()
  data1: any;

  @Input()
  component: any;

  @Input()
  apieData: any;

  type: any = 'text';
  loadingRouteConfig: any;
  record: any = [];
  hide: boolean;
  code: any;
  all_codefield: any = [];
  field_id: any;
  all_models: any;
  selectedModel: any;
  filteredModels: any;
  all_codes: any;
  model_id: any;
  data: any;
  requiredErrMsg: any;
  patterns: any;
  patternsErrMsg: any;
  minLength: any;
  minLengthErrMsg: any;
  isRequired: any = "true";
  loaderOverField1: any;
  loaderOverField2: any;
  spinner: boolean;
  _isRedirect: any;
  option: any;
  optArray: any;
  all_fields: any;
  selectedName: string;
  filteredField: any;
  m_input: any = 'false';

  @ViewChild('field_add_form', {static: true}) field_add_form: any;

  constructor(private _snackBar: MatSnackBar, private service: FieldService, private list: FieldListComponent, private mlist: ModelListComponent, private multi_edit: MultipleEditComponent) {
    this.loadingRouteConfig = true;
    this.loaderOverField1 = false;
    this.loaderOverField2 = false;
    this.hide = false;
    this.spinner = false;
  }

  ngOnInit() {
    // console.log(this.data1);
    this.model_id = this.data1.model_id;
    this._isRedirect = this.data1._isRedirect;
    if (this.data1.fieldOf === 'model') {
      this.loadingRouteConfig = false;
    }
    else {
      if (this.apieData.w_app.includes('Bitrix')) {
        this.service.getAllField(this.model_id).subscribe((field: any) => {
          // console.log(field);
          this.loadingRouteConfig = false;
          this.all_fields = field.data;
          this.filteredField = field.data;
        });
      }

    }
  }

  onFieldTypeChange(){
    if(this.type == 'email'){
      this.patterns = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,20}$';
      this.patternsErrMsg = 'Not a valid email';
    }
    else if(this.type == 'number'){
      this.patterns = '^[0-9]*$';
      this.patternsErrMsg = 'Pattern not matching-Only numbers are allowed';
    }
    else{
      this.patterns = '';
      this.patternsErrMsg = '';
    }
  }

  optChange() {
    if (this.option === 'dynamic') {
      var record = {
        pinned: "false"
      }
      this.service.getAllModels(record).subscribe((models: any) => {
        this.all_models = models;
        this.filteredModels = models;
      });
    }
  }


  show() {
    this.hide = true;
  }

  addCode() {
    this.data = {
      ...this.data,
      model_id: this.selectedModel.key,
      _isRedirect: 'field_add'
    }
    this.component = 'code_add';
  }



  availableInArray(arr, element) {
    if (arr !== undefined) {
      if (arr.includes(element)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }


  codeField() {
    this.field_id = null;
    this.all_codefield = [];
    this.loaderOverField2 = true;
    this.service.getCodeField(this.code).subscribe(codefield => {
      this.all_codefield = codefield;
      this.loaderOverField2 = false;
    });
  }

  displayFn(model) {
    if (model === null || model === undefined) {
      return '';
    } else {
      return model.name;
    }
  }

  onModelChange() {
    try {
      if (this.selectedModel == "") {
        this.selectedModel = null;
      };
      this.code = null;
      this.field_id = null;
      this.all_codefield = [];
      this.all_codes = [];
      this.filteredModels = this.all_models.filter(o => o.name.toLowerCase().includes(this.selectedModel.toLowerCase()));
    } catch { }
  }

  onFieldChange() {
    try {
      if (this.selectedName == "") {
        this.selectedName = null;
      };
      this.filteredField = this.all_fields.filter(o => o.name.toLowerCase().includes(this.selectedName.toLowerCase()));
    } catch { }
  }

  modelCode() {
    this.loaderOverField1 = true;
    this.service.getAllCodes(this.selectedModel.key).subscribe((codes: any) => {
      this.all_codes = codes;
      this.loaderOverField1 = false;
    });
  }

  setCode(event) {
    this.loaderOverField1 = true;
    let record = {
      "key": event.key,
      "code": event.code
    }
    this.all_codes.push(record);
    this.code = event.code;
    this.loaderOverField1 = false;
  }
  createfield(type) {
    if(this.staticOption){
    this.optArray = this.staticOption.split('\n');
    }
    if (this.data1.fieldOf === 'model') {
      this.record = {
        "name": this.name,
        "model_id": this.model_id,
        "type": this.type,
        "l_code": this.code,
        "l_field_id": this.field_id,
        "placeholder": this.placeholder,
        "patterns": this.patterns,
        "patternsErrMsg": this.patternsErrMsg,
        "requiredErrMsg": this.requiredErrMsg,
        "minLength": this.minLength,
        "minLengthErrMsg": this.minLengthErrMsg,
        "isRequired": this.isRequired,
        "staticOption": this.optArray,
        "fieldOf": 'model'
      }
    }
    else {
      if (this.data1.type === 'bitrix') {
        this.record = {
          "name": this.selectedName,
          "model_id": this.model_id,
          "type": this.type,
          "l_code": this.code,
          "l_field_id": this.field_id,
          "placeholder": this.placeholder,
          "patterns": this.patterns,
          "patternsErrMsg": this.patternsErrMsg,
          "requiredErrMsg": this.requiredErrMsg,
          "minLength": this.minLength,
          "minLengthErrMsg": this.minLengthErrMsg,
          "isRequired": this.isRequired,
          "staticOption": this.optArray,
          "fieldOf": 'form',
          "step_id": this.data1.step_id,
          "step_type": this.data1.step_type,
        };
      }
      else {
        this.record = {
          "name": this.name,
          "model_id": this.model_id,
          "type": this.type,
          "l_code": this.code,
          "l_field_id": this.field_id,
          "placeholder": this.placeholder,
          "patterns": this.patterns,
          "patternsErrMsg": this.patternsErrMsg,
          "requiredErrMsg": this.requiredErrMsg,
          "minLength": this.minLength,
          "minLengthErrMsg": this.minLengthErrMsg,
          "isRequired": this.isRequired,
          "staticOption": this.optArray,
          "fieldOf": 'form',
          "step_id": this.data1.step_id,
          "step_type": this.data1.step_type,
          "m_input": this.m_input
        };
      }
    }

    this.spinner = true;
    // console.log(this.record);
    this.service.addField(this.record).subscribe(field => {
      // console.log(field);
      this.spinner = false;
      this._snackBar.open('New Field is Added', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
 
      if (type == 'exit') {
        if (this.data1._isDirected === false) {
          this.list.component = 'field_list'
          this.list.ngOnInit();
        }
        else {
          this.mlist.component = 'model_list'
          this.mlist.ngOnInit();
        }
        if (this._isRedirect === 'multiple_edit') {
          this.multi_edit.component = 'multiple_edit';
        }
      }
      else {
        this.name = '';
        this.type = 'text';
        this.placeholder = '';
        this.patterns = undefined;
        this.record = [];
        this.option = undefined;
        this.code = undefined;
        this.field_id = undefined;
        this.spinner = false;
        this.optArray = [];
        this.staticOption = '';
        this.isRequired = "true";
        this.selectedModel = undefined;
        this.requiredErrMsg = undefined;
        this.patternsErrMsg = undefined;
        this.minLength = undefined;
        this.minLengthErrMsg = undefined;
        if(Object.keys(this.field_add_form.form.controls).length !== 0){
          Object.keys(this.field_add_form.form.controls).forEach((control_name)=>{
            this.field_add_form.form.controls[control_name].markAsUntouched();
          });
        }
        
      }

    })

  }
  back() {
    if (this.data1._isDirected === false) {
      this.list.component = 'field_list';
      this.list.formCanvas.nativeElement.style.display = "block";
      // this.list.ngOnInit();
    }
    else {
      this.mlist.component = 'model_list';
      this.mlist.ngOnInit();
    }
    if (this._isRedirect === 'multiple_edit') {
      this.multi_edit.component = 'multiple_edit';
    }
  }
}
