/* eslint-disable */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MlistComponent } from "./../mlist/mlist.component";
import { MultipleEditComponent } from "./../../light-box/multiple-edit/multiple-edit.component";
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-mlist-editor',
  templateUrl: './mlist-editor.component.html',
  styleUrls: ['./mlist-editor.component.scss'],
})
export class MlistEditorComponent implements OnInit {
  @Input()
  data: any;

  @Input()
  component: any;

  @Output()
  emitedVallue = new EventEmitter();
   
  editor_value: any;
  loadingRouteConfig: any;
  refreshLoading: any;
  spinner: boolean;


  constructor(private mlist: MlistComponent, private multiple_edit: MultipleEditComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
   }

   editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '50',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  ngOnInit() {
    // console.log(this.data);
    this.editor_value = this.data.editor_value;
    this.loadingRouteConfig = false;
  }

  back(){
    if(this.data.type == 'mlist'){
      this.mlist.component = 'mlist';
      this.mlist.table_view = true;
    }
    else if(this.data.type == 'multiple_edit'){
      this.multiple_edit.component = 'multiple_edit';
      this.multiple_edit.table_view = true;
    }
  }
  updateEditor(){
    // console.log(this.editor_value);
    if(this.data.type == 'mlist'){
    this.mlist.component = 'mlist';
    }
    else if(this.data.type == 'multiple_edit'){
      this.multiple_edit.component = 'multiple_edit';
    }
    this.emitedVallue.emit(this.editor_value);
  }

}
