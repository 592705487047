/* eslint-disable */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { McontentService } from '../mcontent.service';
import { MlistComponent } from '../mlist/mlist.component';
import { MultipleEditComponent } from "../../light-box/multiple-edit/multiple-edit.component";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-mdelete',
  templateUrl: './mdelete.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './mdelete.component.scss'],
})
export class MdeleteComponent implements OnInit {

  loadingRouteConfig: any;
  value: any;
  confirm_name: any;

  @Input()
  data: any;
  @Input()
  component: any;
  @Output()
  emitedVallue = new EventEmitter();

  content_id: any;
  collection: any;
  model_id: any;
  spinner: boolean;
  _isRedirect: any;

  constructor(private _snackBar: MatSnackBar, private service: McontentService, private list: MlistComponent,
    private multiple_edit: MultipleEditComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  ngOnInit() {
    // console.log(this.data);
    this.model_id = this.data.model_id;
    this.collection = this.data.collection
    this.content_id = this.data.content_id;
    this._isRedirect = this.data._isRedirect;
    this.loadingRouteConfig = false;
  }

  back() {
    if (this._isRedirect == 'multiple_edit') {
      this.multiple_edit.component = 'multiple_edit';
    }
    else if (this._isRedirect == 'mlist') {
      this.list.component = 'mlist';
      this.list.pageEvent.pageIndex = 0;
    }
  }
  delete() {
    this.spinner = true;
    var record = {
      "collection": this.collection,
      "content_id": this.content_id,
    }
    // console.log(this.value);
    // console.log(this.confirm_name);
    if (this.confirm_name.trim() === 'Delete') {
      this.service.deleteMultiLanguageContent(record).subscribe((data: any) => {
        this.spinner = false;
        this._snackBar.open('Content deleted Successfully', '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        if (this._isRedirect == 'multiple_edit') {
          this.emitedVallue.emit(this.content_id);
          this.multiple_edit.component = 'multiple_edit';
        }
        else if (this._isRedirect == 'mlist') {
          this.emitedVallue.emit(this.content_id);
          this.list.component = 'mlist';
          this.list.pageEvent.pageIndex = 0;
          // this.list.ngOnInit();
        }

      });
    }
    else {
      this.spinner = false;
      this._snackBar.open('"please type correct name', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }
  }

}

