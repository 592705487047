/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable curly */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LibraryService } from '../library.service';
// import { AddComponent } from '../../single-content/add/add.component';
import { MaddComponent } from '../../multiple-content/madd/madd.component';
import { ImageEditComponent } from '../../light-box/image-edit/image-edit.component';
import { MeditComponent } from '../../multiple-content/medit/medit.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LightBoxService } from '../../light-box/light-box.service';
// import { SettingListComponent } from '../../settings/setting-list/setting-list.component';
// import { ElementAddComponent } from '../../elements/element-add/element-add.component';
// import { ElementEditComponent } from '../../elements/element-edit/element-edit.component';
import { MlistComponent } from '../../multiple-content/mlist/mlist.component';
import { MultipleEditComponent } from '../../light-box/multiple-edit/multiple-edit.component';

@Component({
  selector: 'app-library-list',
  templateUrl: './library-list.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './library-list.component.scss'],
})
export class LibraryListComponent implements OnInit {
  loadingRouteConfig: any;
  refreshLoading: any;
  items: any;
  Path: any = '';
  Level: any = 0;
  @Input()
  modal: any;

  @Input()
  component: any;
  @Input()
  data: any;

  @Output()
  imageData = new EventEmitter();
  selectedImage: any = [];
  imageName: any = [];
  url: any;
  spinner: boolean;
  isDirected: any;
  type: any;
  isSingle: any;
  data1: any;
  allImages: any = [];
  filteredImages: any[];
  Folders: any[] = [];

  constructor(private service: LibraryService, private _snackBar: MatSnackBar, private madd: MaddComponent, private medit: MeditComponent, private mlist: MlistComponent,
    private image_edit: ImageEditComponent,private multiple_edit: MultipleEditComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  ngOnInit() {
    this.Level = 0;
    this.isDirected = this.data._isDirected;
    this.type = this.data._type;
    this.isSingle = this.data._isSingle;
    this.service.getLibrary().subscribe(async (items: any) => {
      this.allImages = items.data;
      this.getReadyWithImage(items.data);
      this.loadingRouteConfig = false;
      this.refreshLoading = false;
    });
  }



  async getReadyWithImage(fileArr: any[]) {
    let arr = [];
    let nameArr = [];
    fileArr.forEach(ele => {
      if (ele.name.includes('/')) {
        if (!ele.name.includes('APIE_ASSETS/') && !ele.name.includes('APIE_WEBSITE/')) {
          let obj = {
            ...ele,
            extraSize: []
          };
          obj['name'] = obj['name'].split('/')[0] + '/';
          if (!nameArr.includes(obj['name'])) {
            nameArr.push(obj['name']);
          }
        }
      }
      else {
        let obj = {
          ...ele,
          extraSize: []
        };
        if (obj['name'].includes('_s.')) {
          let pieces = obj['name'].split('_s.');
          pieces.pop();
          let fileSplitName = pieces.join('_');
          obj['extraSize'] = fileArr.filter(o => !o.name.includes('/') && o.name.includes(fileSplitName) && fileSplitName.split('_').length === o.name.split('_').length - 1);
          if (obj['extraSize'].length === 1 || obj['extraSize'].length === 0) {
            let smallPices = fileSplitName.split('_');
            if (smallPices.length > 1) {
              smallPices.pop();
              fileSplitName = smallPices.join('_');
              obj['extraSize'] = fileArr.filter(o => !o.name.includes('/') && o.name.includes(fileSplitName.trim()) && fileSplitName.split('_').length === o.name.split('_').length - 2);
              arr.push(obj);
            }
            else
              arr.push(obj);
          }
          else
            arr.push(obj);
        }
        else if (this.fileExt(obj['name']) !== 'image') {
          obj['extraSize'].push(ele);
          arr.push(obj);
        }
      }
    });
    await Promise.all(arr);
    this.Folders = nameArr;
    this.filteredImages = arr;
    console.log(this.filteredImages)
  }



  async getReadyWithFolderImage(fileArr: any[], name: string) {
    let arr = [];
    let nameArr = [];
    fileArr.forEach(ele => {
      let filename = ele.name.substring(ele.name.indexOf(name) + name.length, ele.name.length);
      if (filename.includes('/')) {
        let obj = {
          ...ele,
          extraSize: []
        };
        obj['name'] = filename.split('/')[0] + '/';
        if (!nameArr.includes(obj['name'])) {
          nameArr.push(obj['name']);
        }
      }
      else {
        let obj = {
          ...ele,
          extraSize: []
        };
        obj['name'] = filename;
        if (obj['name'].includes('_s.')) {
          let pieces = obj['name'].split('_s.');
          pieces.pop();
          let fileSplitName = pieces.join('_');
          obj['extraSize'] = fileArr.filter(o => o.name.includes(fileSplitName) && o.name.substring(o.name.indexOf(name) + name.length, o.name.length).length === obj['name'].length );
          if (obj['extraSize'].length === 1 || obj['extraSize'].length === 0) {
            let smallPices = fileSplitName.split('_');
            if (smallPices.length > 1) {
              smallPices.pop();
              fileSplitName = smallPices.join('_');
              obj['extraSize'] = fileArr.filter(o => o.name.includes(fileSplitName.trim()) && o.name.substring(o.name.indexOf(name) + name.length, o.name.length).length === obj['name'].length);
              arr.push(obj);
            }
            else
              arr.push(obj);
          }
          else
            arr.push(obj);
        }
        else if (this.fileExt(obj['name']) !== 'image') {
          obj['extraSize'].push(ele);
          arr.push(obj);
        }
      }
    });
    await Promise.all(arr);
    this.Folders = nameArr;
    this.filteredImages = arr;
  }



  folderClick(name) {
    this.Level = this.Level + 1;
    this.loadingRouteConfig = true;
    this.refreshLoading = true;
    this.Path = this.Path + name;
    let arrbyName = this.allImages.filter(o => o.name.includes(name));
    this.getReadyWithFolderImage(arrbyName, this.Path);
    this.loadingRouteConfig = false;
    this.refreshLoading = false;
  };



  back() {
    // console.log(this.isDirected)
    if (this.isDirected === true) {
      this.Folders = [];
      if (this.Level === 0) {
        if (this.type === 'multiple_content_add') {
          this.madd.component = 'madd';
        }
        else if (this.type === 'image_edit') {
          this.image_edit.component = '!library_list';
        }
        else if (this.type === 'multiple_content_edit') {
          this.medit.component = 'medit';
        }
        else if (this.type === 'mlist') {
          this.spinner = false;
          this.mlist.component = 'mlist';
          this.mlist.table_view = true;
        }
        else if (this.type === 'multiple_edit') {
          this.spinner = false;
          this.multiple_edit.component = 'multiple_edit';
          this.multiple_edit.table_view = true;
        }
      }
      else {
        const level = this.Level = this.Level - 1;
        // console.log(level)
        if (level === 0) {
          this.Path = '';
          this.getReadyWithImage(this.allImages);
        } else {
          console.log(this.Path);
          const path = this.Path = this.Path.split('/', level).join('/') + '/';
          console.log(path);
          let arrbyName = this.allImages.filter(o => o.name.includes(path));
          this.getReadyWithFolderImage(arrbyName, this.Path);
        }
      }
    }
    else {
      if (this.Level === 0) {
        this.modal.dismiss('Cross click');
      } else {
        const level = this.Level = this.Level - 1;
        // console.log(level)
        if (level === 0) {
          this.Path = '';
          this.getReadyWithImage(this.allImages);
        } else {
          const path = this.Path = this.Path.split('/', level).join('/') + '/';
          let arrbyName = this.allImages.filter(o => o.name.includes(path));
          this.getReadyWithFolderImage(arrbyName, this.Path);
        }
      }
    }
  }



  fileExt(name) {
    if (name && name.includes('.pdf')) {
      return 'pdf';
    }
    else if (name && name.includes('.js')) {
      return 'js';
    }
    else if (name && name.includes('.css')) {
      return 'css';
    }
    else if (name && name.includes('.docx')) {
      return 'docx';
    }
    else if (name && name.includes('.gif')) {
      return 'gif';
    }
    else if (name && name.includes('.svg')) {
      return 'svg';
    }
    else {
      return 'image';
    }
  }


  addLibrary() {
    // console.log(this.Path)
    this.data1 = {
      path: this.Path,
      type: this.type
    };
    this.component = 'library_add';
  }


  checkChange(event) {
    if (this.isSingle === false) {
      // console.log(event);
    }
  }



  selectImage() {
    this.spinner = true;
    // console.log(this.data)
    let promise = new Promise((resolve, reject) => {
      if (this.isSingle === false) {
        this.allImages.forEach(file => {
          if (file.checked === true) {
            this.selectedImage.push(file.url + '&token=' + file.token);
          }
        });
      }
      else {
        this.selectedImage = this.url;
      }
      resolve(this.selectedImage);
    });
    promise.then((result: any) => {
      // console.log(result);
      if (result === undefined || result.length === 0) {
        if (this.type !== 'setting_list' || this.type !== 'element_add' || this.type !== 'element_edit') {
          this.spinner = false;
          this._snackBar.open('Please Choose a image', '', {
            duration: 2500,
            horizontalPosition: 'left',
            panelClass: ['custom-snackbar']
          });
        }
        else {
          this.spinner = false;
          this._snackBar.open('Please Choose a image', '', {
            duration: 2500,
            horizontalPosition: 'left',
            panelClass: ['custom-snackbar']
          });
        }
      }
      else {
        if (this.isDirected === true) {
          this.imageData.emit(result);
          if (this.type === 'multiple_content_add') {
            this.spinner = false;
            this.madd.component = 'madd';
          }
          else if (this.type === 'image_edit') {
            if(this.data.contentType === 'static') {
              if (this.data._shortCode === null) {
                let record = {
                  name: this.data.model,
                  contentType: 'static',
                  description: result,
                  page_id: this.data.page_id,
                  model: this.data.model
                };
                if(this.data.model.includes('apiecommon')) {
                  record['code'] = this.data.model;
                }
                else{
                  record['code'] = this.data.model + "_" + Math.floor(Math.random() * 1000001);
                }
                this.service.addContent(record).subscribe((content: any) => {
                  this.spinner = false;
                  this.image_edit.component = '!library_list';
                  this.image_edit.contents._value = result;
                  this._snackBar.open('New Image is Updated', '', {
                    duration: 2500,
                    horizontalPosition: 'left',
                    panelClass: ['custom-snackbar']
                  });
                });
              }
              else {
                const record1 = {
                  name: this.data.model,
                  code: this.data._shortCode,
                  contentType: 'static',
                  description: result,
                  page_id: this.data.page_id,
                  model: this.data.model
                };
                this.service.addContent(record1).subscribe((content: any) => {
                  this.spinner = false;
                  this.image_edit.component = '!library_list';
                  this.image_edit.contents._value = result;
                  this._snackBar.open('Image is Updated', '', {
                    duration: 2500,
                    horizontalPosition: 'left',
                    panelClass: ['custom-snackbar']
                  });
                });
              }
            }
            else {
              const record1 = {
                name: this.data.model,
                code: this.data._shortCode,
                url: this.url,
                model: this.data.model,
                page_id:  this.data.page_id,
                contentType: this.data.contentType,
                field: this.data.field,
                dynamicName: this.data.dynamicContent
              };
              this.service.addContent(record1).subscribe((content: any) => {
                this.spinner = false;
                this.image_edit.component = '!library_list';
                this.image_edit.contents._value = result;
                this._snackBar.open('Image is Updated', '', {
                  duration: 2500,
                  horizontalPosition: 'left',
                  panelClass: ['custom-snackbar']
                });
              });
            }
          }
          else if (this.type === 'multiple_content_edit') {
            this.spinner = false;
            this.medit.component = 'medit';
          }
          else if (this.type === 'mlist') {
            this.spinner = false;
            this.mlist.component = 'mlist';
            this.mlist.table_view = true;
          }
          else if (this.type === 'multiple_edit') {
            this.spinner = false;
            this.multiple_edit.component = 'multiple_edit';
            this.multiple_edit.table_view = true;
          }
        }
      }
    });
  }



  deleteImage() {
    // this.selectedImage = [];
    this.filteredImages.forEach((file, index) => {
      // console.log(file)
      if (file.checked === true) {
        file.extraSize.forEach(element => {
          this.selectedImage.push(element.url + '&token=' + element.token);
          this.imageName.push(element.name);
        });
      }
      if (index === this.filteredImages.length - 1) {
        this.data = {
          ...this.data,
          selectedImage: this.selectedImage,
          imageName: this.imageName,
        };
        this.component = 'library_delete';
      }
    });
  }



  clicktocopy(urlElem) {
    urlElem.select();
    document.execCommand('copy');
    this._snackBar.open('Copied to clipboard', '', {
      duration: 2500,
      horizontalPosition: 'left',
      panelClass: ['custom-snackbar']
    });
  }
}
