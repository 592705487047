import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatDividerModule } from "@angular/material/divider";
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialFileInputModule } from 'ngx-material-file-input';
// import { AdminBarComponent } from './admin-bar/admin-bar.component';
// import { CustomFormsModule } from "./custom-forms/custom-forms.module";
// import { pageModule } from './pages/page.module';
import { FieldModule } from './field/field.module';
// import { LayoutModule } from './layout/layout.module';
import { ModelModule } from './model/model.module';
// import { ContentModule } from './single-content/content.module';
import { McontentModule } from './multiple-content/mcontent.module';
import { CodeModule } from './short-code/code.module';
import { LightBoxModule } from './light-box/light-box.module';
import { LibraryModule } from './library/library.module';
// import { ElementModule } from './elements/elements.module';
// import { SettingModule } from './settings/setting.module';
// import { UserModule } from './users/user.module';
// import { InsatllableApplicationModule } from './application/application.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { LogoutComponent } from './logout/logout.component';
// import { cmsModule } from './cms-config/cms.module';
// import { PageModelComponent } from './cms-config/page-model/page-model.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxJsonViewerModule,
    // pageModule,
    // CustomFormsModule,
    // ContentModule,
    FieldModule,
    // LayoutModule,
    ModelModule,
    McontentModule,
    CodeModule,
    ReactiveFormsModule,
    MaterialFileInputModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    LightBoxModule,
    LibraryModule,
    // ElementModule,
    // SettingModule,
    // UserModule,
    // InsatllableApplicationModule,
    // cmsModule
  ],
  declarations: [
    // AdminBarComponent,
    LogoutComponent,
    SidePanelComponent
  ],
  exports: [
    // AdminBarComponent,
    LogoutComponent,
    SidePanelComponent
  ],
})
export class SharedModule { }