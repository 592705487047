/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { CodeListComponent } from '../code-list/code-list.component';
import { CodeService } from '../code.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-code-delete',
  templateUrl: './code-delete.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './code-delete.component.scss'],
})
export class CodeDeleteComponent implements OnInit {

  loadingRouteConfig: any;
  code: any;
  confirm_code: any;

  @Input()
  data: any;
  spinner: boolean;

  constructor(private _snackBar: MatSnackBar, private service: CodeService, private list: CodeListComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  ngOnInit() {
    this.code = this.data.code.code;
    this.loadingRouteConfig = false;
  }

  back() {
    this.list.component = 'code_list';
  }
  delete() {
    this.spinner = true;
    if (this.code === this.confirm_code.trim()) {
      this.service.deleteCode(this.data.code.key).subscribe(data => {
        this.spinner = false;
        this._snackBar.open('Query Deleted Successfully', '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.list.ngOnInit();
        this.list.component = 'code_list';
      })
    }
    else {
      this.spinner = false;
      this._snackBar.open("please provide correct query", '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }
  }

}


