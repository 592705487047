/* eslint-disable */
import { Component, OnInit, Input, ComponentFactory, AfterViewInit, Inject, NgModule, Compiler, ComponentRef, ViewChild, ViewContainerRef, ViewEncapsulation, Output, EventEmitter, OnDestroy, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
// import { MatSnackBar, MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatStepperModule, MatDatepickerModule, MatDialogModule, MatDividerModule, MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule, MatTreeModule } from '@angular/material';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatDividerModule } from "@angular/material/divider";
import { MatNativeDateModule } from '@angular/material/core';
import { DomSanitizer, BrowserModule } from '@angular/platform-browser';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicService } from '../dynamic.service';
import { CommonModule, JsonPipe, DOCUMENT } from '@angular/common';
import { NgPipesModule } from 'ngx-pipes';
import { SharedModule } from 'src/app/admin/shared/shared.module';
import { LoaderModule } from 'src/app/admin/shared/loaders/loader.module';
import { FormsModule } from '@angular/forms';
import { OwlModule } from 'ngx-owl-carousel';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { promise } from 'protractor';
import { resolve } from 'url';
declare var $: any;
import { DateAdapter } from '@angular/material/core'
import { AngularFireStorage } from '@angular/fire/storage';
import { SidePanelComponent } from 'src/app/admin/shared/side-panel/side-panel.component';
import { TextMaskModule } from 'angular2-text-mask';


import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],

})
export class SelectorComponent implements OnInit, AfterViewInit {
  @Input()
  data: any;
  private cmpRef: ComponentRef<any>;
  loadingRouteConfig: boolean;
  @ViewChild('selector', { read: ViewContainerRef, static: true }) target: ViewContainerRef;


  constructor(private compiler: Compiler) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    $('mat-tooltip-component div:contains("Tooltiptext")').remove();
    this.compileToComponent('selector', unescape(this.data._html), unescape(this.data._scss), unescape(this.data._js), unescape(this.data._functions)).then((factory: ComponentFactory<any>) => {
      this.cmpRef = this.target.createComponent(factory);
      this.cmpRef.instance.model = JSON.parse(unescape(this.data.model));
      if (this.data.rawSession !== undefined) {
        this.cmpRef.instance.rawSession = JSON.parse(unescape(this.data.rawSession));
      }
      // if (this.data.e_css !== "undefined") {
      //   this.cmpRef.instance.e_css = JSON.parse(unescape(this.data.e_css));
      // }
      // if (this.data.e_js !== "undefined") {
      //   this.cmpRef.instance.e_js = JSON.parse(unescape(this.data.e_js));
      // }

      this.cmpRef.instance.isEditor = this.data.isEditor;
      this.cmpRef.instance.user_id = this.data.user_id;
      this.cmpRef.instance.udata = this.data.udata;
      this.cmpRef.instance.user_type = this.data.user_type;
      this.cmpRef.instance.pageID = this.data.pageID;
      if (this.data.dynamicContent !== undefined) {
        this.cmpRef.instance.dynamicContent = JSON.parse(unescape(this.data.dynamicContent));
      }
      this.cmpRef.instance.code = this.data.code;
      this.cmpRef.instance.allModels = this.data.allModels;
      const pageData = {};
      pageData["pageDetails"] = this.data.pageDetails;
      this.cmpRef.instance.pageData = pageData;
      this.loadingRouteConfig = false;
    });
  }
  private compileToComponent(selector: string, htmltemplate: string, scss: string, js: string, functions: string): Promise<ComponentFactory<any>> {
    @Component({ selector, template: htmltemplate, styles: [scss] })
    class DynamicSelectorComponent implements OnInit, AfterContentChecked, AfterViewInit {
      @Input()
      model: any;
      @Input()
      pageID: any;
      @Input()
      pageData: any;
      @Input()
      dynamicContent: any;
      @Input()
      isEditor: any;
      @Input()
      user_id: any;
      @Input()
      udata: any;
      @Input()
      user_type: any
      @Input()
      code: any;
      @Input()
      allModels: any;
      @Input()
      rawSession: any;
      @Output() output = new EventEmitter();


      service: any;
      allInputs: any = [];
      domain: string;
      page: any;
      formObj: any = {};

      token: any = {};
      host: any;

      constructor(@Inject(DOCUMENT) private document, private router: Router,
        private route: ActivatedRoute, private fireauth: AngularFireAuth, private _snackBar: MatSnackBar,
        private sanitizer: DomSanitizer, private modalService: NgbModal, @Inject(DynamicService) service,
        private storage: AngularFireStorage, private _adapter: DateAdapter<any>) {
        this.host = environment.host;
        this.service = service;
        this.page = this.route['_routerState'].snapshot.url.substring(1);
      }

      customTrackBy(index: number, obj: any): any {
        return index;
      }


      submit(val) {
        // console.log(val);
        $('#' + val.loader).show();
        const apie = this;
        let buttonModel;
        // console.log(val.form)
        if (val.form === undefined) {
          buttonModel = eval("this.model." + val.button);
          if (val.storageType === 'localStorage') {
            var localVar = eval("this.model." + val.localVar);
            localStorage.clear();
            localStorage.setItem('localVar', JSON.stringify(localVar));
            this.router.navigate(['/' + buttonModel['_url']]);
          }
          else {
            var record = {
              uid: this.user_id,
              userType: this.user_type,
              pageModel: apie.model,
              buttonModel: buttonModel
            }
            this.service.setSession(record).subscribe((data: any) => {
              this.router.navigate(['/' + buttonModel['_url']]);
              $('#' + val.loader).hide();
            });
          }
        }
        else {
          const fmodel = eval("this.model." + val.form);
          buttonModel = eval("this.model." + val.button);
          const promise = new Promise((resolve, reject) => {

            Object.keys(fmodel).forEach(key => {
              if (typeof (fmodel[key]) === 'object') {
                this.formObj[key] = fmodel[key]['_input']
              }
              else {
                this.formObj[key] = fmodel[key]
              }
            })
            resolve(this.formObj)
            // console.log(this.extModel)
          })
          promise.then((fvalue: any) => {
            // console.log(fvalue)
            if (val.type === 'login_password') {
              var email = fmodel['email']._input;
              var password = fmodel['password']._input;
              this.fireauth.signInWithEmailAndPassword(email, password).then(res => {
                // console.log(res.user.uid);
                $('#' + val.loader).hide();
                if (res.user) {
                  this._snackBar.open('Login Success', '', {
                    duration: 2500,
                    horizontalPosition: "center",
                    panelClass: ['custom-snackbar']
                  });
                  setTimeout(() => {
                  }, 3000);
                }
              }).catch(err => {
                this._snackBar.open('Login failed -' + err.message, '', {
                  duration: 2500,
                  horizontalPosition: "center",
                  panelClass: ['custom-snackbar']
                });
              });
            }
            else if (val.type === 'login_link') {
              // this.domain = this.document.location.protocol +'//'+ this.document.location.hostname + ':my_port' ;
              this.domain = this.document.location.protocol + '//' + this.document.location.hostname;
              // console.log(this.domain)
              var email = fmodel['email']._input;
              var link = buttonModel['_url'];
              var actionCodeSettings = {
                url: this.domain + "/" + 'redirect?email=' + email + '&link=' + link,
                handleCodeInApp: true,
              }
              this.fireauth.sendSignInLinkToEmail(email, actionCodeSettings).then(res => {
                $('#' + val.loader).hide();
                this._snackBar.open('Sign in link Send to your Email', '', {
                  duration: 2500,
                  horizontalPosition: "center",
                  panelClass: ['custom-snackbar']
                });
                setTimeout(() => {
                }, 3000);
              }).catch(err => {
                // console.log(err.message)
                this._snackBar.open('failed -' + err.message, '', {
                  duration: 2500,
                  horizontalPosition: "center",
                  panelClass: ['custom-snackbar']
                });
              });
            }
            else if (val.type === 'register_password') {
              var email = fmodel['email']._input;
              var password = fmodel['password']._input;
              this.fireauth.createUserWithEmailAndPassword(email, password).then((res: any) => {
                $('#' + val.loader).hide();
                if (res.user) {
                  this._snackBar.open('Registration Success', '', {
                    duration: 2500,
                    horizontalPosition: "center",
                    panelClass: ['custom-snackbar']
                  });
                  setTimeout(() => {
                  }, 3000);

                }
              }).catch(err => {
                this._snackBar.open('Registration failed -' + err.message, '', {
                  duration: 2500,
                  horizontalPosition: "center",
                  panelClass: ['custom-snackbar']
                });
              })
            }
            else {
              fvalue['type'] = val.type;
              fvalue['uid'] = this.user_id;
              fvalue['userType'] = this.user_type;
              fvalue['page_id'] = apie.pageID;
              fvalue['pageModel'] = apie.model;
              fvalue['buttonModel'] = buttonModel;

              // console.log(fvalue)
              this.service.submitForm(fvalue).subscribe((data: any) => {
                // console.log(data)
                $('#' + val.loader).hide();
                sessionStorage.setItem('response', data);
                if (buttonModel['_url'] !== "#") {
                  this.router.navigate(['/' + buttonModel['_url']]);
                }
                this._snackBar.open('your form is Submitted', '', {
                  duration: 2500,
                  horizontalPosition: "center",
                  panelClass: ['custom-snackbar']
                });
                setTimeout(() => {
                }, 3000);
              });
            }

            var clearModel = {};
            eval('clearModel = apie.model.' + val.form);
            // console.log(clearModel)
            Object.keys(clearModel).forEach(c_model => {
              if (typeof (clearModel[c_model]) === 'object' && Array.isArray(clearModel[c_model]) === false) {
                eval("apie.model." + val.form + "." + c_model + "._input = ''");
              }
            })
          })
        }
      }
      //// custom functions
      custom(func) {
        var apie = this;
        var APIE_CREDENTIAL = apie.user_id;
        if (apie.pageData.pageDetails.sessionVariable !== undefined) {
          var APIE_PAGE_VAR = apie.pageData.pageDetails.sessionVariable
        }
        eval(functions + ' ' + func);
      }

      getSubMenus(model, id) {
        return eval("this.model." + model + "._data.filter(o => o._parent === id)");
      }
      arrayToString(array) {
        if (array) {
          return array.map(o => o.value).toString().replace(/,/g, ' ');
        }
      }

      apieUrlSanitizer(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }

      apieComparer(o1: any, o2: any): boolean {
        return o1.id === o2.id;
      }

      apieDiscountedPrice(obj) {
        var price;
        if (obj.discount_type !== undefined) {
          if (obj.discount_type === 'flat') {
            if (obj.price.includes('$')) {
              price = parseFloat(obj.price.replace(/\$/g, '')) - parseFloat(obj.discount);
              if (price <= 0) {
                return '$0';
              }
              else {
                price = '$' + price;
                return price;
              }
            }
          }
          else {
            if (obj.price.includes('$')) {
              price = parseFloat(obj.price.replace(/\$/g, '')) - (parseFloat(obj.price.replace(/\$/g, '')) * (parseFloat(obj.discount) / 100));
              if (price <= 0) {
                return '$0';
              }
              else {
                price = '$' + price;
                return price;
              }
            }
          }
        }
      }

      apieLocalizeDate(dateLocalCode: string) {
        this._adapter.setLocale(dateLocalCode);
      }

      apieMoveItemInArray(array, previousIndex, currentIndex) {
        moveItemInArray(array, previousIndex, currentIndex);
      }

      apieParseFloat(num) {
        return parseFloat(num);
      }

      apieStringify(obj) {
        return JSON.stringify(obj)
      }
      apieStringToInt(string) {
        return parseInt(string);
      }
      apieSubStringUrl(url) {
        var decode_url = decodeURIComponent(url);
        var short_url = decode_url.substring(decode_url.lastIndexOf('/') + 1, decode_url.lastIndexOf('?'));
        return short_url;
      }
      apieGetCurrentDate(sp) {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //As January is 0.
        var yyyy = today.getFullYear();

        if (dd < 10) {
          dd = 0 + dd;
        }
        if (mm < 10) {
          mm = 0 + mm;
        };
        // console.log(mm+'/'+dd+'/'+yyyy);
        return (mm + sp + dd + sp + yyyy);
      }

      ////////////////////
      openPanel(model, type, event) {
        // console.log(type)
        // console.log(model)
        // console.log(event);
        if (event) {
          event.stopPropagation();
        }
        let modalFields;
        if (type === 'multiple' || type === 'bitrix') {
          const fields = Object.keys(eval('this.model.' + model + '._data')[0]);
          modalFields = fields;
        } else if (type === 'form') {
          const fields = Object.keys(eval('this.model.' + model));
          modalFields = fields;
        } else {
          modalFields = null;
        }
        const modalReference = this.modalService.open(SidePanelComponent,
          {
            scrollable: true,
            size: 'lg',
            centered: false,
            backdropClass: 'apie_modal-backdrop',
            windowClass: 'custom-modal animated slideInLeft'
          });

        $(document).off('focusin.modal');
        modalReference.componentInstance.type = type;
        modalReference.componentInstance.allModels = this.allModels;
        modalReference.componentInstance.pageData = this.pageData;
        modalReference.componentInstance.pageData['page_id'] = this.pageID;
        if (this.dynamicContent !== undefined) {
          modalReference.componentInstance.dynamicContent = escape(JSON.stringify(this.dynamicContent));
        }
        if (type === 'single_submit') {
          modalReference.componentInstance.model = this.code + '.' + model['button'];
          modalReference.componentInstance.formModel = this.code + '.' + model['form'];
          modalReference.componentInstance.contents = eval('this.model.' + model['button']);
        }
        else {
          modalReference.componentInstance.model = this.code + '.' + model;
          modalReference.componentInstance.contents = eval('this.model.' + model);
        }
        modalReference.componentInstance.fields = modalFields;
        modalReference.componentInstance.uid = this.user_id;
        modalReference.componentInstance.udata = this.udata;
        modalReference.componentInstance.modal = modalReference;
        return false;
      }
      setGlobalData(event) {
        // console.log('Hello');
        // console.log(event);
      }
      ngOnInit() {
        // console.log(this.e_js);      
      }

      loadScript(scriptData: string, type) {
        const head = this.document.head;
        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        if (type === 'url') {
          script.src = scriptData;
        }
        else {
          script.innerHTML = scriptData;
        }
        script.async = false;
        script.defer = false;
        head.appendChild(script);
      }

      createLink(type, url) {
        const link: HTMLLinkElement = this.document.createElement('link');
        link.setAttribute('rel', type);
        link.setAttribute('href', url);
        this.document.head.appendChild(link);
      }

      scrollIntoView(url) {
        // console.log('hello')
        url = url.substring(url.lastIndexOf('#') + 1, url.length);
        // console.log(url)
        var elmnt = document.getElementById(url);
        elmnt.scrollIntoView();
      }

      ngAfterViewInit() {
        const apie = this;
        eval(js);
      }


      ngAfterContentChecked() {
        // if(this.service.apieBoolean === true)
        // {
        //   console.log('hello')
        //   this.service.apieBoolean = false;
        if (this.page.includes('#')) {
          this.scrollIntoView(this.page)
        }
        // }
      }
    }
    @NgModule({
      imports: [
        CommonModule,
        NgPipesModule,
        BrowserModule,
        RouterModule,
        SharedModule,
        LoaderModule,
        FormsModule,
        OwlModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        TextMaskModule,
        MatTreeModule,
        NgPipesModule,
        MaterialFileInputModule,
        NgxMatIntlTelInputModule,
        NgxPaginationModule,
        PortalModule,
        ScrollingModule,
        A11yModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule],
      declarations: [DynamicSelectorComponent],
    })
    class DynamicSelectorModule { }

    return this.compiler.compileModuleAndAllComponentsAsync(DynamicSelectorModule).then(
      factory => factory.componentFactories.find(x => x.componentType === DynamicSelectorComponent)
    );

  }

}
