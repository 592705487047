import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorComponent } from './selector.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../admin/shared/shared.module';
import { LoaderModule } from '../../admin/shared/loaders/loader.module';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import {DragDropModule} from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [SelectorComponent],
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    SharedModule,
    LoaderModule,
    FormsModule,
    OwlModule,
    NgxMatIntlTelInputModule
  ],
  exports: [SelectorComponent],
})
export class SelectorModule { }
