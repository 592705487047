/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { FieldListComponent } from '../field-list/field-list.component';
import { FieldService } from '../field.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-field-delete',
  templateUrl: './field-delete.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './field-delete.component.scss'],
})
export class FieldDeleteComponent implements OnInit {
  loadingRouteConfig: any;
  name: any;
  confirm_name: any;

  @Input()
  data1: any;
  spinner: boolean;

  constructor(private _snackBar: MatSnackBar, private service: FieldService, private list: FieldListComponent) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  ngOnInit() {
    this.name = this.data1.field.name;
    this.loadingRouteConfig = false;
  }

  back() {
    this.list.component = 'field_list';
  }
  delete() {
    this.spinner = true;
    var record = {
      "field_id": this.data1.field.key,
      "model_id": this.data1.model_id,
    }
    if (this.name.trim() === this.confirm_name.trim()) {
      this.service.deleteField(record).subscribe(data => {
        this.spinner = false;
        this._snackBar.open('Field Deleted Successfully.', '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.list.ngOnInit();
        this.list.component = 'field_list';
      })
    }
    else {
      this.spinner = false;
      this._snackBar.open("please provide correct name", '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      this.list.ngOnInit();
    }
  }

}


