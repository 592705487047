/* eslint-disable */
import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit {
  @Input()
  component: any;
  data: any;
  @Input()
  udata: any;
  apieData: any;
  _isAdmin: any;

  @Input()
  modal: any;

  @Input()
  model: any;
  @Input()
  contents: any;
  @Input()
  fields: any;
  @Input()
  type: any;
  @Input()
  pageData: any;
  @Input()
  dynamicContent: any;
  @Input()
  form: any;
  @Input()
  uid: any;
  @Input()
  formModel: any;
  @Input()
  allModels: any;

  @ViewChild('dialog', { read: TemplateRef, static: true }) dialog: TemplateRef<any>;
  constructor(private _snackBar: MatSnackBar, private fireauth: AngularFireAuth, public matDialog: MatDialog) { }


  ngOnInit() {
    if (this.udata !== undefined) {
      if (this.udata.modalName === 'pages') {
        this.apieData = {
          ...this.udata
        }
        this.component = 'page_list';

      } else if (this.udata.modalName === 'contents') {
        this.apieData = {
          ...this.udata
        }
        this.component = 'list';

      } else if (this.udata.modalName === 'models') {
        this.apieData = {
          ...this.udata
        }
        this.component = 'model_list';

      } else if (this.udata.modalName === 'forms') {
        this.apieData = {
          ...this.udata
        }
        this.component = 'form_list';

      } else if (this.udata.modalName === 'library') {
        this.data = {
          ...this.udata,
          _isDirected: false,
          _isSingle: false,
          _type: "library_list"
        }
        this.component = 'library_list';

      } else if (this.udata.modalName === 'catalog') {
        this.apieData = {
          ...this.udata,
          component: name
        }
        this.component = 'catalog';
      }
      else if (this.udata.modalName === 'setting') {
        this.data = {
          ...this.udata,
        }
        this.component = 'cms_config';
      }
      else if (this.udata.modalName === 'page_model') {
        this.data = {
          ...this.udata,
        }
        this.component = 'page_model';
      }
      else if (this.udata.modalName === 'logout') {
        this.data = {
          ...this.udata,
        }
        this.component = 'logout';
      }

      // this.allModels = JSON.parse(unescape(this.udata.model));
      if (this.udata.role.includes('admin')) {
        this._isAdmin = true;
      }
      else {
        this._isAdmin = false;
      }
    }
  }


  openModal(name) {
    this.type = undefined;
    if (name === 'pages') {
      this.apieData = {
        ...this.udata
      }
      this.component = 'page_list';

    } else if (name === 'contents') {
      this.apieData = {
        ...this.udata
      }
      this.component = 'list';

    } else if (name === 'models') {
      this.apieData = {
        ...this.udata,
        uid: this.udata.uid,
        w_app: this.udata.w_app
      }
      this.component = 'model_list';

    } else if (name === 'forms') {
      this.apieData = {
        ...this.udata
      }
      this.component = 'form_list';

    } else if (name === 'library') {
      this.data = {
        ...this.udata,
        _isDirected: false,
        _isSingle: false,
        _type: "library_list"
      }
      this.component = 'library_list';

    } else if (name === 'catalog') {
      this.data = {
        ...this.udata,
        uid: this.udata.uid,
        component: name
      }
      this.component = 'catalog';
    }
    else if (name === 'setting') {
      this.data = {
        ...this.udata,
      }
      this.component = 'cms_config';
    }
    else if (name === 'page_model') {
      this.data = {
        ...this.udata,
      }
      this.component = 'page_model';
    }
    else if (name === 'logout') {
      this.apieData = {
        ...this.udata
      }
      this.component = 'logout';
    }
  }


  availableInArray(arr, element) {
    if (arr !== undefined) {
      if (arr.includes(element)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  
}
