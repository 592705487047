/* eslint-disable */
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
declare var $ :any;
import { LightBoxService } from '../light-box.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-multiple-edit',
  templateUrl: './multiple-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './multiple-edit.component.scss'],
})
export class MultipleEditComponent implements OnInit {
  loadingRouteConfig: boolean;
  refreshLoading: boolean;
  optionFields: any = [];
  fieldsForm: FormGroup;
  form_template: any = [];

  searchIsFocused: any = false;

  @Input()
  model: any;
  @Input()
  contents: any;
  @Input()
  fields: any;
  @Input()
  type: any;
  @Input()
  modal: any;

  @Input()
  apieData: any;

  @Input()
  dynamicContent: any;

  @Input()
  pageData: any;

  lyaout_id: string;
  all_models: any;
  selectedModel: any;
  filteredModels: any = [];
  all_codes: any = [];
  all_contents: any = [];
  loaderOverField: boolean;
  pageSizeOptions = [10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100];

  list_view: any;
  table_view: any;
  dataSource = new MatTableDataSource(this.all_contents);
  displayedColumns: string[] = [];
  all_languages: any;

  data: any;
  component: any;
  collection: any = 'not_exists';
  spinner: boolean;
  existing_coll: any
  bitrixModel: any = [];
  bitrixField: FormGroup;
  selectedBitrixModel: any;
  is_option_choosed: any = false;
  model_id: any;
  collectionName: any;
  showclr: boolean;
  closeSearch: boolean;

  isStart: any;
  record: any = [];
  all_fields: any = [];
  model_type: any;
  removable: any = true;
  changedIdArray: any = [];

  static_single_dropdown_search: boolean = false;
  static_single_dropdown_tempArray: any;
  dynamic_single_dropdown_search: boolean = false;
  dynamic_single_dropdown_tempArray: any;
  static_multiple_dropdown_search: boolean = false;
  static_multiple_dropdown_tempArray: any;
  dynamic_multiple_dropdown_search: boolean = false;
  dynamic_multiple_dropdown_tempArray: any;

  isFirstInputField: boolean = true;
  all_dynamic_dropdown_field_name = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  newRowsIds: any = [];
  filter: any;
  ln_code: any;
  contents_ids: any = [];
  default_code: any;
  isDefaultContent: any = false;

  @ViewChild('optInput', { static: true }) optInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @ViewChild('optInput2', { static: true }) optInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2', { static: true }) matAutocomplete2: MatAutocomplete;

  constructor(private route: ActivatedRoute, private service: LightBoxService, private _snackBar: MatSnackBar) {
    this.loadingRouteConfig = true;
    this.loaderOverField = false;
    this.spinner = false;
    this.showclr = false;
    this.closeSearch = true;
  }

  leftNav() {
    $(".multieditForm-wrap").animate({ scrollLeft: "-=100px" });
  }
  rightNav() {
    $(".multieditForm-wrap").animate({ scrollLeft: "+=100px" });
  }
  displayFn(model) {
    if (model === null || model === undefined) {
      return '';
    } else {
      return model.name;
    }
  }


  availableInArray(arr, element) {
    if (arr !== undefined) {
      if (arr.includes(element)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  

  displayFnOpt(opt) {
    if (opt === null || opt === undefined) {
      return '';
    } else {
      return opt;
    }
  }

  add(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
    }
    if (input) {
      input.value = '';
    }

    // this.form.controls[field_name].setValue('');
  }

  remove(opt: string, id, field_name): void {
    let promise = new Promise((resolve,reject)=>{
      if(this.dataSource.data.length !== 0){
        this.dataSource.data.forEach(obj => {
          if (obj['id'] === id) {
            const index = this.dataSource.data.indexOf(obj);
            // console.log(index);
            var option_array: any = [];
            if(this.dataSource.data[index][field_name]){
              option_array = this.dataSource.data[index][field_name];
            }
            else{
              option_array = [];
            }
            var result = {
              option_array: option_array,
              index: index
            }
            resolve(result)
          }
        });
      }
    });
    promise.then((result)=>{
      // console.log(result);
      var option_array = result['option_array'];
      if(option_array.length !== 0){
        option_array.forEach(obj => {
          if (obj == opt) {
            delete option_array[obj];
            this.dataSource.data[result['index']][field_name] = option_array;
            this.all_contents = this.dataSource.data;
          }
        });
      }
    });

    
  }

  selected(event, id, field_name): void {
    let promise = new Promise((resolve,reject)=>{
      if(this.dataSource.data.length !== 0){
        this.dataSource.data.forEach(obj => {
          if (obj['id'] === id) {
            const index = this.dataSource.data.indexOf(obj);
            const isExist = this.changedIdArray.indexOf(obj['id']);
          if(isExist == -1){
            this.changedIdArray.push(obj['id']);
            }
            // console.log(index);
            var option_array: any = [];
            var option_length: any;
            if(this.dataSource.data[index][field_name]){
              option_array = this.dataSource.data[index][field_name];
              option_length = option_array.length;
            }
            else{
              option_length = 0;
            }
            var result = {
              option_array: option_array,
              option_length: option_length,
              index: index
            }
            resolve(result)
          }
        });
      }
    });
    promise.then((result)=>{
      var option_array = result['option_array'];
      var option_length = result['option_length'];

      if (this.isArray(option_array) == false || option_length == 0) {
        option_array = [];
        option_array[option_length] = event.option.viewValue;
        this.dataSource.data[result['index']][field_name] = option_array;
        this.all_contents = this.dataSource.data;
      }
      else {
        var is_option_exist = false;
        option_array.forEach((obj, idx) => {
          if (obj == event.option.viewValue) {
            is_option_exist = true;
          }
          if (idx == option_length - 1) {
            if (is_option_exist == true) {
              this._snackBar.open('you have already selected this option', '', {
                duration: 2500,
                horizontalPosition: "left",
                panelClass: ['custom-snackbar']
              });
            }
            else {
              option_array[option_length] = event.option.viewValue;
              this.dataSource.data[result['index']][field_name] = option_array;
              this.all_contents = this.dataSource.data;
            }
          }
        });
  
      }
    });

  }


  add2(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      // this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }

  remove2(opt: string, id, field_name): void {
    let promise = new Promise((resolve,reject)=>{
      if(this.dataSource.data.length !== 0){
        this.dataSource.data.forEach(obj => {
          if (obj['id'] === id) {
            const index = this.dataSource.data.indexOf(obj);
            // console.log(index);
            var option_array: any = [];
            if(this.dataSource.data[index][field_name]){
              option_array = this.dataSource.data[index][field_name];
            }
            else{
              option_array = [];
            }
            var result = {
              option_array: option_array,
              index: index
            }
            resolve(result)
          }
        });
      }
    });
    promise.then((result)=>{
      // console.log(result);
      var option_array = result['option_array'];
      if(option_array.length !== 0){
        option_array.forEach(obj => {
          if (obj.id == opt['id']) {
            option_array = this.removeByAttr(option_array, 'id', obj.id);
            this.dataSource.data[result['index']][field_name] = option_array;
            this.all_contents = this.dataSource.data;
          }
        });
      }
    });
    
  }

  removeByAttr(arr, attr, value){
    var i = arr.length;
    while(i--){
       if( arr[i] 
           && arr[i].hasOwnProperty(attr) 
           && (arguments.length > 2 && arr[i][attr] === value ) ){ 

           arr.splice(i,1);

       }
    }
    return arr;
}

selected2(event: MatAutocompleteSelectedEvent, linked_field_name,id, field_name): void {
  let promise = new Promise((resolve,reject)=>{
    if(this.dataSource.data.length !== 0){
      this.dataSource.data.forEach(obj => {
        if (obj['id'] === id) {
          const index = this.dataSource.data.indexOf(obj);
          const isExist = this.changedIdArray.indexOf(obj['id']);
          if(isExist == -1){
            this.changedIdArray.push(obj['id']);
            }
          // console.log(index);
          var option_array: any = [];
          var option_length: any;
          if(this.dataSource.data[index][field_name]){
            option_array = this.dataSource.data[index][field_name];
            option_length = option_array.length;
          }
          else{
            option_length = 0;
          }
          var result = {
            option_array: option_array,
            option_length: option_length,
            index: index
          }
          resolve(result)
        }
      });
    }
  });
  promise.then((result)=>{
    // console.log(result);
    var option_array = result['option_array'];
    var option_length = result['option_length'];
    if (this.isArray(option_array) == false || option_length == 0) {
      // console.log('1');
      option_array = [];
      option_array[option_length] = {};
      option_array[option_length]['id'] = event.option.id;
      option_array[option_length][linked_field_name] = event.option.viewValue;
      this.dataSource.data[result['index']][field_name] = option_array;
      this.all_contents = this.dataSource.data;
    }
    else {
      // console.log('2');
      var is_option_exist = false;
      option_array.forEach((obj, idx) => {
        if (obj.id == event.option.id) {
          is_option_exist = true;
        }
        if (idx == option_length - 1) {
          // console.log(is_option_exist);
          if (is_option_exist == true) {
            this._snackBar.open('you have already selected this option', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
          }
          else {
            option_array[option_length] = {};
            option_array[option_length]['id'] = event.option.id;
            option_array[option_length][linked_field_name] = event.option.viewValue;
            this.dataSource.data[result['index']][field_name] = option_array;
            this.all_contents = this.dataSource.data;
          }
        }
      });


    }
  });

}

  @ViewChild( MatSort, {static: true }) sort: MatSort;
  @ViewChild(MatPaginator, {static: true} ) paginator: MatPaginator;

  ngOnInit() {
    this.all_contents = [];
    this.all_contents = this.contents._data;
    console.log(this.all_contents);
    this.all_contents.forEach(element => {
      this.contents_ids.push(element.id);
    });
    this.all_languages = JSON.parse(this.apieData.languages);
    this.default_code = JSON.parse(this.apieData.languages).default.code;
    this.all_fields = [];
    this.displayedColumns = [];
    this.newRowsIds = [];
    var current_content_view = localStorage.getItem('current_content_view');
    if(current_content_view){
      if(current_content_view == 'list_view'){
        this.list_view = true;
        }
        else{
          this.table_view = true;
        }
    }
    else{
      this.table_view = true;
    }
    if(this.dynamicContent !== undefined)
    {
      this.dynamicContent = JSON.parse(unescape(this.dynamicContent));
    }
    this.refreshLoading = true;
    this.bitrixField = new FormGroup(
      {}
    );
    this.component = 'multiple_edit';
    var record = {
      pinned: "false"
    }
      // console.log(this.contents._shortCode);
    if(this.contents._shortCode == null){
      this.service.getAllModels(record).subscribe((models: any) => {
        // console.log(models);
        models.forEach(model => {
          if (model.type === 'bitrix') {
            this.bitrixModel.push(model)
          }
          else {
            this.filteredModels.push(model)
          }
        });
        this.all_models = models;
        this.service.sortAlphabetically(this.filteredModels, "name");
        this.list_view = true;
        this.loadingRouteConfig = false;
        this.refreshLoading = false;
      });
    }

    else{ 
      // console.log(this.contents_ids);
      var ids = {
        ids: this.contents_ids
      }

    this.service.getColData(this.contents._shortCode, ids).subscribe((result: any) => {
      // console.log(result);
      if(result.data){
        if(result.data[0]){
          // console.log(result.data[0]);
          if(result.data[0]._lang == this.default_code){
            this.isDefaultContent = true;
          }
          // console.log(this.isDefaultContent);
        }
      }
      this.all_contents = result.data;
      this.model_id = result.key;
      this.collectionName = result.collectionName;
      if(result.fieldOrder){
      if(result.fieldOrder.length !== 0){
        result.fieldOrder.forEach((field, index) => {

          // ---------------------------------------------- validation ------------------------------------------------------
          if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
            field.isRequired = 'false';
          }
          if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
            field.requiredErrMsg = '';
          }
          if(field.patterns == '' || field.patterns == null || field.patterns == undefined){
            field.patterns = '';
          }
          if(field.patternsErrMsg == '' || field.patternsErrMsg == null || field.patternsErrMsg == undefined){
            field.patternsErrMsg = '';
          }
          if(field.minLength == '' || field.minLength == null || field.minLength == undefined){
            field.minLength = '0';
          }
          if(field.minLengthErrMsg == '' || field.minLengthErrMsg == null || field.minLengthErrMsg == undefined){
            field.minLengthErrMsg = '';
          }
          if(field.placeholder == '' || field.placeholder == null || field.placeholder == undefined){
            field.placeholder = '';
          }

          // ---------------------------------------------- End -------------------------------------------------------------
  
          if (field.type === 'dropdown' || field.type === 'multi_dropdown') {
            if (field.l_code !== undefined) {
              this.all_dynamic_dropdown_field_name.push(field.name);
              field.optionData = {
                data: { _data: [] }
              }
            }
            else {
              field.optionData = field.staticOption;
            }
          }
         
          this.all_fields.push(field);
          if(index === result.fieldOrder.length - 1){
            this.optionApi();
            // console.log(this.all_fields);
          }
        });
      }
    }
      this.dataSource = new MatTableDataSource(this.all_contents);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // console.log(this.all_fields);
      this.all_fields.forEach((field,index) => {
        if (field.name !== 'id') {
          this.displayedColumns.push(field.name);
        }
        if(index == this.all_fields.length - 1 && this.isDefaultContent == true){
          this.displayedColumns.push('Action');
        }
      });
      this.loadingRouteConfig = false;
      this.refreshLoading = false;
    });
    }
   
    this.fields.forEach(field => {
      this.bitrixField.addControl(field, new FormControl(''));
    });
    
  }

  back(){
    this.modal.dismiss('Cross click');
  }

  optionApi(){
    if (this.all_dynamic_dropdown_field_name.length !== 0) {
      this.all_dynamic_dropdown_field_name.forEach((field_name, index) => {
        let promise = new Promise((resolve, reject) => {
          this.all_fields.map((field, idx) => {
            let promise1 = new Promise((resolve1, reject1) => {
              if (field.name == field_name) {
                const record = {
                  field_id: field.l_field_id,
                  code: field.l_code
                }
                this.service.getCodeContent(record).subscribe((codecontent: any) => {
                  resolve1(true);
                  if (codecontent.status === undefined) {
                    if(this.all_fields[idx]){
                      this.all_fields[idx].optionData = codecontent;
                    }
                  }
                })

              }
              else{resolve1(true);}
            })
            promise1.then((result1: any) => {
              if (idx == this.all_fields.length - 1) { resolve(true); }

            })

          });
        });
        promise.then((result: any) => {
          if (index == this.all_dynamic_dropdown_field_name.length - 1) {
            this.isFirstInputField = false;
          }
        });

      });
    }
}

onDropdownChange(event,field_name){
  this.updateDropdownOptions(field_name, event.target.value);
}

applyFilter(event : any) {
  let filterValue = event.target.value;
  filterValue = filterValue.trim(); // Remove whitespace
  filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  this.dataSource.filter = filterValue;
  this.filter = this.dataSource.filter;
  if ($("#searchbox").val().length > 0) {
    this.showclr = true;
    this.closeSearch = false;
  } else {
    this.showclr = false;
    this.closeSearch = true;
  }
}

cancel() {
  this.showclr = false;
  this.closeSearch = true;
  this.dataSource = new MatTableDataSource(this.all_contents);
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
}

mlistEditorPage(editor_value, id, field_name){
  this.table_view = false;
  if(editor_value == null || editor_value == undefined){
    editor_value = '';
  }

  if(this.all_contents.length !== 0){
    this.all_contents.forEach(obj => {
      if (obj.id === id) {
        const index = this.all_contents.indexOf(obj);
        // console.log(index);
        this.data = {
          ...this.data,
          index: index,
        }
        const isExist = this.changedIdArray.indexOf(obj.id);
        if(isExist == -1){
          this.changedIdArray.push(obj.id);
          }
      }
    });
  }

  this.data = {
   ...this.data,
   type: 'multiple_edit',
    editor_value: editor_value,
    key: field_name
  };
  // console.log(this.data)
  this.component = 'mlist_editor';
}

callLibrary(id, field_name) {
  this.table_view = false;
  if(this.all_contents.length !== 0){
    this.all_contents.forEach(obj => {
      if (obj.id === id) {
        const index = this.all_contents.indexOf(obj);
        // console.log(index);
        this.data = {
          ...this.data,
          index: index,
        }
        const isExist = this.changedIdArray.indexOf(obj.id);
        if(isExist == -1){
          this.changedIdArray.push(obj.id);
          }
      }
    });
  }
  this.data = {
     ...this.data,
    _type: "multiple_edit",
    _isDirected: true,
    _isSingle: true,
    key: field_name
  }
  this.component = 'library_list';
}

  setImage(event) {
    this.refreshLoading = true;
    // console.log(event);
    this.all_contents[this.data.index][this.data.key] = event;
    this.table_view = true;
    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.refreshLoading = false;
  }

  setEditor(event) {
    this.refreshLoading = true;
    // console.log(event);
    this.all_contents[this.data.index][this.data.key] = event;
    this.table_view = true;
    this.dataSource = new MatTableDataSource(this.all_contents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter;
    this.refreshLoading = false;
  }

  sortContent(name) {
    this.all_contents = this.all_contents.sort(
      function (a, b) {
        if (a[name].toLowerCase() < b[name].toLowerCase()) {
          return -1;
        } else if (a[name].toLowerCase() > b[name].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    );
    if (this.table_view === true) {
      this.dataSource = new MatTableDataSource(this.all_contents);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.filter;
    }
  }

  listView() {
    this.list_view = true;
    this.table_view = false;
    localStorage.setItem('current_content_view','list_view');
  }


  tableView() {
    this.list_view = false;
    this.table_view = true;
    localStorage.setItem('current_content_view','table_view');
  }

  typeOf(value) {
    return typeof value;
  }

  isArray(obj) {
    return Array.isArray(obj);
  }

  copyMContent(content) {
    //  console.log(content);
      if(this.table_view == true){
          this.add_copy_row(content);
      }
      else{
        this.refreshLoading = true;
        var record = {
          "model_id": this.model_id,
          "collection": this.collection,
          "content_id": content.id
        }
        // this.service.getSingleContentDetails(record).subscribe((content: any) => {
        //   // console.log(content);
        //   this.data = {
        //     ...this.data,
        //     content: content,
        //     model_id: this.model_id,
        //     collection: this.collectionName,
        //     model_name: this.model_name,
        //     _isRedirect: 'multiple_edit',
        //   }
        //   this.refreshLoading = false;
        //   this.component = 'madd';
        // });
      }
  
    }

    change_value(event, key, field_name) {
      if(this.all_contents.length !== 0){
        this.all_contents.forEach(obj => {
          if (obj.id === key) {
            const index = this.all_contents.indexOf(obj);
            if(index !== -1){
            this.all_contents[index][field_name] = event.target.value;
            }
            const isExist = this.changedIdArray.indexOf(obj.id);
            if(isExist == -1){
              this.changedIdArray.push(obj.id);
              }
          }
        });
      }
      this.dataSource = new MatTableDataSource(this.all_contents);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.filter;
    }
    
    onOptionSelect(event, key, field_name) {
      // console.log(event.option.value);
      if(this.all_contents.length !== 0){
        this.all_contents.forEach(obj => {
          if (obj.id === key) {
            const index = this.all_contents.indexOf(obj);
            // console.log(index);
            if(index !== '' && index !== null && index !== undefined){
            this.all_contents[index][field_name] = event.option.value;
            // console.log('matched');
            // console.log(this.all_contents[index][field_name]);
            const isExist = this.changedIdArray.indexOf(obj.id);
            if(isExist == -1){
              this.changedIdArray.push(obj.id);
              }
            this.dataSource = new MatTableDataSource(this.all_contents);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource.filter = this.filter;
  
            }
          }
        });
      }
     
    }
  
    add_row(isActive) {
  
      var data1 = {}
  
      // console.log(this.all_fields);
      if(this.all_fields){
        if(this.all_fields.length !== 0){
          this.all_fields.forEach(field => {
            if(field.type == 'multi_dropdown' || field.type == 'multi_file'){
              data1[field.name] = [];
            }
            else{
              data1[field.name] = '';
            }
          });
        }
      }
  
      data1['collection'] = this.collectionName;
  
      data1['createdAt'] = "";
  
      var new_row_id = this.makeid(6); 
      this.newRowsIds.push(new_row_id);
      data1['id'] = new_row_id;
  
      data1['isActive'] = isActive;
  
      // this.all_contents.push(data1);
      this.all_contents.splice(0, 0, data1);
  
      this.dataSource = new MatTableDataSource(this.all_contents);
      this.paginator.pageIndex = 0;
      this.dataSource.paginator = this.paginator;
      this.sort.direction = "asc";
      this.dataSource.sort = this.sort;
      this.filter = '';
      this.dataSource.filter = this.filter;
  
    }
  
    add_copy_row(content) {
  
      var data1 = {}
  
      // console.log(this.all_fields);
      if(this.all_fields){
        if(this.all_fields.length !== 0){
          this.all_fields.forEach(field => {
            if(field.type == 'multi_dropdown' || field.type == 'multi_file'){
              data1[field.name] = content[field.name];
            }
            else{
              data1[field.name] = content[field.name];
            }
          });
        }
      }
  
      data1['collection'] = this.collectionName;
  
      data1['createdAt'] = "";
  
      var new_row_id = this.makeid(6); 
      this.newRowsIds.push(new_row_id);
      data1['id'] = new_row_id;
  
      data1['isActive'] = true;
  
      // this.all_contents.push(data1);
      this.all_contents.splice(0, 0, data1);
  
      this.dataSource = new MatTableDataSource(this.all_contents);
      this.paginator.pageIndex = 0;
      this.dataSource.paginator = this.paginator;
      this.sort.direction = "asc";
      this.dataSource.sort = this.sort;
      this.filter = '';
      this.dataSource.filter = this.filter;
  
    }
  
    makeid(length) {
      var result = '';
      var characters = 'abcdefghijklmnopqrstuvwxyz';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }

    update() {
      let promise = new Promise((resolve,reject)=>{
        if(this.newRowsIds.length !==  0){
          if(this.dataSource.data.length !==  0){
            this.dataSource.data.forEach((content,idx) => {
              const isNew = this.newRowsIds.indexOf(content['id']);
              if(isNew !== -1){
                content['id'] = '';
              }
              if(idx == this.dataSource.data.length - 1){
                resolve(true);
              }
            });
          }
          else{
            resolve(true);
          }
        }
        else{
          resolve(true);
        }
      });
      promise.then((result)=>{
        var newData = {
          data : [],
          languages : this.all_languages
        }
        // console.log(this.dataSource.data);
        delete this.dataSource.paginator;
        for (let index = 0; index < this.dataSource.data.length; index++) {
          if(this.dataSource.data[index]['id'] !== ''){
            const isExist = this.changedIdArray.indexOf(this.dataSource.data[index]['id']);
            if(isExist !== -1){
              newData.data.push(this.dataSource.data[index]);
              }
          }
          else{
            this.dataSource.data[index]['_lang'] = this.default_code;
            newData.data.push(this.dataSource.data[index]);
          }
        }
        // console.log(this.dataSource.data);
        // console.log(this.changedIdArray);
        console.log(newData);
        if(newData.data.length == 0){
          this._snackBar.open('No changes found', '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
        }
        else{
          
          // verify that newData object is correct or not

          this.service.addUpdateContent(newData).subscribe(respond => {
            // console.log(respond);
            if(respond['status'] == "succcess"){
              this._snackBar.open('Content updated Successfully', '', {
                duration: 2500,
                horizontalPosition: "left",
                panelClass: ['custom-snackbar']
              });
              this.newRowsIds = [];
              this.back();
            }
          });
        }
      }); 
    }

    updateDropdownOptions(field_name, value) {
      // console.log(field_name);
      // this.emptyObject = false;
      this.static_single_dropdown_search = false;
      this.static_single_dropdown_tempArray = null;
      this.dynamic_single_dropdown_search = false;
      this.dynamic_single_dropdown_tempArray = null;
      this.static_multiple_dropdown_search = false;
      this.static_multiple_dropdown_tempArray = null;
      this.dynamic_multiple_dropdown_search = false;
      this.dynamic_multiple_dropdown_tempArray = null;
      try {
        this.all_fields.map(field => {
          if (field.name == field_name) {
            if (field.type == 'dropdown') {
              if (this.isArray(field.optionData) == true) {
                if (value == "") {
                  this.static_single_dropdown_search = false;
                  // this.form.controls.field_name.setValue(null);
                }
                else {
                  this.static_single_dropdown_search = true;
                  this.static_single_dropdown_tempArray = null;
                  this.static_single_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(value.toLowerCase()));
                }
              }
              else {
                if (value == "") {
                  this.dynamic_single_dropdown_search = false;
                  // this.form.controls.field_name.setValue(null);
                }
                else {
                  this.dynamic_single_dropdown_search = true;
                  this.dynamic_single_dropdown_tempArray = null;
                  this.dynamic_single_dropdown_tempArray = {
                    optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                  }
                  const linked_field_name = field.optionData.field;
                  field.optionData.data._data.map(o => {
                    if (o[linked_field_name].toLowerCase().includes(value.toLowerCase())) {
                      this.dynamic_single_dropdown_tempArray.optionData.data._data.push(o);
                    }
                  });
                }
              }
  
  
            }
  
            else if (field.type == 'multi_dropdown') {
              if (this.isArray(field.optionData) == true) {
                if (value == "") {
                  this.static_multiple_dropdown_search = false;
                }
                else {
                  this.static_multiple_dropdown_search = true;
                  this.static_multiple_dropdown_tempArray = null;
                  this.static_multiple_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(value.toLowerCase()));
                }
              }
              else {
                if (value == "") {
                  this.dynamic_multiple_dropdown_search = false;
                }
                else {
                  this.dynamic_multiple_dropdown_search = true;
                  this.dynamic_multiple_dropdown_tempArray = null;
                  this.dynamic_multiple_dropdown_tempArray = {
                    optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                  }
                  const linked_field_name = field.optionData.field;
                  field.optionData.data._data.map(o => {
                    if (o[linked_field_name].toLowerCase().includes(value.toLowerCase())) {
                      this.dynamic_multiple_dropdown_tempArray.optionData.data._data.push(o);
                    }
                  });
                }
              }
            }
            else {
              this.static_single_dropdown_search = false;
              this.dynamic_single_dropdown_search = false;
              this.static_multiple_dropdown_search = false;
              this.dynamic_multiple_dropdown_search = false;
            }
          }
        });
      } catch { }
    }


  bitrix() {
    // console.log("hello")
    this.loaderOverField = true;
    this.service.getAllCodes(this.selectedBitrixModel.key).subscribe((codes: any) => {
      // console.log(codes)
      this.contents._shortCode = codes[0].code
      this.service.getBitrixField(this.selectedBitrixModel.key).subscribe((field: any) => {
        // console.log(field);
        this.all_fields = field;
        this.loaderOverField = false;
      })
    })
  }

  onModelChange() {
    try {
      if (this.selectedModel == "") {
        this.selectedModel = null;
      };
      this.contents._shortCode = null;
      this.all_codes = [];
      this.filteredModels = this.all_models.filter(o => o.name.toLowerCase().includes(this.selectedModel.toLowerCase()));
    } catch { }
  }

  modelCode() {
    this.loaderOverField = true;
    this.service.getAllCodes(this.selectedModel.key).subscribe((codes: any) => {
      // console.log(codes);
      if (codes.length == 0) {
        this._snackBar.open("No query found in " + this.selectedModel.name, '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
      }
      this.all_codes = codes;
      this.loaderOverField = false;

    });
  }
  onCodeChange() {
    this.refreshLoading = true;
    this.form_template = [];
    this.service.getAllModelFields(this.selectedModel.key).subscribe((fields: any) => {
      // console.log(fields.data)
      this.optionFields = fields.data;
      this.fields.forEach(field => {
        this.form_template.push({
          type: "select",
          label: field,
          options: fields.data
        });
      });

      const group = {};
      this.form_template.forEach(input_template => {
        group[input_template.label] = new FormControl('');
      });
      this.fieldsForm = new FormGroup(group);
      if(this.form_template.length === 0){
        this.refreshLoading = false;
      }
      else{
        this.form_template.forEach((input_template,index) => {
          this.fieldsForm.controls[input_template.label].valueChanges.subscribe(value => {
            this.onFormControlValueChange(input_template.label, value);
          })
          if(index == this.form_template.length-1){
            this.refreshLoading = false;
          }
        });
      }
      
    });
  }

  onOptionChange() {
    this.is_option_choosed = true;
    this.dynamic_single_dropdown_search = false;
  }

  addCode() {
    // console.log(this.dynamicContent)
    this.data = {
      ...this.data,
      _details: this.dynamicContent,
      model_id: this.selectedModel.key,
      _isRedirect: 'multiple_edit'
    }
    this.component = 'code_add';
  }

  setCode(event) {
    this.loaderOverField = true;
    let record = {
      "key": event.key,
      "code": event.code
    }
    this.all_codes.push(record);
    this.contents._shortCode = event.code;
    this.onCodeChange();
    this.loaderOverField = false;
    // console.log(codes);

  }
  setEditField(event) {
    this.refreshLoading = true;
    // console.log(event);
    this.contents._data.map((content, index) => {
      if (event.previousContentId === content.id) {
        this.contents._data[this.contents._data.map((x, i) => [i, x]).filter(x => x[1] == content)[0][0]] = event.formData;
        this.refreshLoading = false;
      }
      if (index == this.contents._data.length - 1) { this.refreshLoading = false; }
    });
  }
  setAddField(event) {
    this.refreshLoading = true;
    // console.log(this.contents._data);
    // console.log(event);
    this.contents._data.push(event);
    this.refreshLoading = false;
  }

  deleteContent(content_id: any) {
    // console.log(content_id);
    if(this.newRowsIds.includes(content_id)){
     this.setDeleteField(content_id);
    }
    else{
      // console.log(sel_content);
    if (this.contents._data.length == 1) {
      this._snackBar.open("you can't delete all the items", '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }
    else {
      if (typeof content_id == 'number') {
        this.contents._data.map(content => {
          if (content_id === content.id) {
            var index = this.contents._data.indexOf(content);
            if (index !== -1) {
              this.contents._data.splice(index, 1);
            }
          }
        });
      }
      else {
        // console.log("redirect to mdelete");
        this.data = {
          ...this.data,
          model_id: this.model_id,
          collection: this.collectionName,
          content_id: content_id,
          _isRedirect: 'multiple_edit',
        };
        this.component = 'mdelete';
      }

    }
    }
  }

  setDeleteField(content_id) {
    this.refreshLoading = true;
    if(this.all_contents.length !== 0){
      this.all_contents.forEach(obj => {
        if (obj.id === content_id) {
          this.all_contents = this.removeByAttr(this.all_contents, 'id', obj.id);
          this.dataSource = new MatTableDataSource(this.all_contents);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSource.filter = this.filter;
          this.refreshLoading = false;
        }
        else{
          this.refreshLoading = false;
          }
      });
    }
    else{
    this.refreshLoading = false;
    }

  }


  setModel(event) {
    this.refreshLoading = true;
    var record = {
      pinned: "false"
    }
    this.service.getAllModels(record).subscribe((models: any) => {
      models.forEach(model => {
        if (model.type === 'bitrix') {
          this.bitrixModel.push(model)
        }
        else {
          this.filteredModels.push(model)
        }
      });
      this.all_models = models;
      this.selectedModel = event;
      this.service.sortAlphabetically(this.filteredModels, "name");
      this.refreshLoading = false;
    })
  }

  onSubmit() {
    this.spinner = true;
    let record = {};
    // console.log(this.selectedBitrixModel)
    if (this.type === 'bitrix') {
      record = {
        page_id: this.pageData.page_id,
        code: this.contents._shortCode,
        fields: this.bitrixField.value,
        model: this.model
      };
      // console.log(record);
      this.service.updateShortcode(record).subscribe((result: any) => {
        // console.log(result);
        this.spinner = false;
        this._snackBar.open(result.status, '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.modal.close();
      });
    }
    else {
      record = {
        page_id: this.pageData.page_id,
        code: this.contents._shortCode,
        fields: this.fieldsForm.value,
        model: this.model
      };
      // console.log(this.fieldsForm.value);
      this.service.updateShortcode(record).subscribe((result: any) => {
        // console.log(result);
        this.spinner = false;
        this._snackBar.open(result.status, '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        this.modal.close();
      });
    }
  }

  addModel() {
    this.component = 'model_add';
    this.data = {
      ...this.data,
      _isRedirect: 'multiple_edit'
    }
  }

  Refresh() {
    this.data = {
      ...this.data,
      shortcode: this.contents._shortCode,
      model: this.model,
      page_id: this.pageData.page_id,
      model_name: this.trimCollectionName(),
    }
    this.component = 'restore_model';
  }


  mAdd() {
    this.data = {
      ...this.data,
      _isRedirect: 'multiple_edit',
      collection: this.collectionName,
      model_id: this.model_id,
      model_name: this.trimCollectionName(),
      languages : this.apieData.languages
    }
    // console.log(this.data.model_name);
    this.component = 'madd';
  }

  mEdit(content) {
    // console.log(this.apieData);
    // console.log(content);
    this.data = {
      ...this.data,
      model_id: this.model_id,
      model_name: this.trimCollectionName(),
      collection: this.collectionName,
      content_id: content.id,
      model: this.model,
      page_id: this.pageData.page_id,
      _isRedirect: 'multiple_edit',
      content: content,
      fields: this.fields,
      shortcode: this.contents._shortCode,
      languages : this.apieData.languages,
      ln_code: content._lang
    }
    if(content._lang){
      this.data.ln_code = content._lang;
    }
    else{
      this.data.ln_code = this.default_code;
    }
    // console.log(this.data);
    this.component = 'medit';
  }

  trimCollectionName() {
    var model_name: any;
    if (this.collectionName !== null && this.collectionName !== '' && this.collectionName !== undefined) {
      model_name = this.collectionName.replace('MD_', '').trim();
      // console.log(model_name);
      const dotIndex = model_name.indexOf(".");
      if (dotIndex !== -1) {
        // console.log(model_name);
        var toRemove = model_name.substring(dotIndex - 7, dotIndex);
        var temp_model_name = model_name.replace(toRemove, '').trim();
        model_name = temp_model_name;
        // console.log(model_name);
        return model_name;
      }
      else {
        return model_name;
      }

    }
    else {
      model_name = 'default';
      return model_name;
    }
  }
  bitrixSubmit() {

  }

  onFormControlValueChange(field_name,value) {
    // console.log(field_name);
    try {
      // console.log(value);
      // console.log(this.form_template);
      this.form_template.map(field => {
        if (field.label == field_name) {
          // console.log(this.fieldsForm.controls[field_name].value);
          if (value == "") {
            this.dynamic_single_dropdown_search = false;
            // console.log('search box null');
            this.fieldsForm.controls.field_name.setValue(null);
          }
          else {
            this.dynamic_single_dropdown_search = true;
            this.dynamic_single_dropdown_tempArray = null;
            this.dynamic_single_dropdown_tempArray = {
              label: field.label, type: field.type, options: []
            }
            field.options.map(opt => {
              if (opt.name.toLowerCase().includes(value.toLowerCase())) {
                this.dynamic_single_dropdown_tempArray.options.push(opt);
              }
            });
            // console.log(this.dynamic_single_dropdown_tempArray);
          }

        }
      });


    } catch { }
  }
}