/* eslint-disable */
import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { McontentService } from '../mcontent.service';
import { FormGroup, FormArray, FormBuilder, Validators, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MlistComponent } from '../mlist/mlist.component';
import { MultipleEditComponent } from "../../light-box/multiple-edit/multiple-edit.component";
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as language from "../../language.json";
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';



@Component({
  selector: 'app-medit',
  templateUrl: './medit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './medit.component.scss'],
})
export class MeditComponent implements OnInit {
  loadingRouteConfig: boolean;
  refreshLoading: any;

  all_content_field: any;
  all_content_fields: any;
  form: FormGroup;

  demo_form: FormGroup;

  all_language_forms: FormGroup;

  objectKeys = Object.keys;

  @Input()
  data: any;

  @Output()
  emitedVallue = new EventEmitter();

  model_id: any;
  model_name: any;
  collection: any;
  content_id: any;
  field_name: any = [];
  obj: any = {};
  all_fields: any = [];
  mdownloadURL: any = [];
  sdownloadURL: any;

  contents: any = [];

  @Input()
  component: any;
  _isSingle: boolean;

  fcname: any;
  spinner: boolean;
  _isRedirect: any;
  fcname1: any;
  shortcode: any;

  all_languages: any = [];

  default_content_id: any;
  _default_content_id: any;
  ln_code: any;

  tab_disable: boolean = false;

  loaded_contents: any = [];
  form_contents: any = [];


  nowShowing: any;

  static_single_dropdown_search: boolean = false;
  static_single_dropdown_tempArray: any;
  dynamic_single_dropdown_search: boolean = false;
  dynamic_single_dropdown_tempArray: any;
  static_multiple_dropdown_search: boolean = false;
  static_multiple_dropdown_tempArray: any;
  dynamic_multiple_dropdown_search: boolean = false;
  dynamic_multiple_dropdown_tempArray: any;

  isFirstInputField: boolean = true;
  all_dynamic_dropdown_field_name = [];
  removable: any = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('optInput', { static: true }) optInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  @ViewChild('optInput2', { static: true }) optInput2: ElementRef<HTMLInputElement>;
  @ViewChild('auto2', { static: true }) matAutocomplete2: MatAutocomplete;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar, private service: McontentService, private list1: MlistComponent, private list2: MultipleEditComponent, private sanitizer: DomSanitizer) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '50',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  get f() { return this.all_language_forms.controls; }
  get t() { return this.f.lang_form as FormArray; }


  add(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      // this.fruits.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.form.controls[field_name].setValue('');
  }

  remove(opt: string, field_name): void {
    this.form.controls[field_name].value.forEach(obj => {
      if (obj == opt) {
        const index = this.form.controls[field_name].value.indexOf(obj);
        this.form.controls[field_name].value.splice(index, 1);
      }

    });
  }

  selected(event: MatAutocompleteSelectedEvent, field_name): void {
    if (this.form.controls[field_name].value == '' || this.form.controls[field_name].value == null || this.form.controls[field_name].value == undefined) {
      this.form.controls[field_name].setValue([]);
      this.form.controls[field_name].value[this.form.controls[field_name].value.length] = event.option.viewValue;
    }
    else {
      var is_option_exist = false;
      this.form.controls[field_name].value.forEach((obj, index) => {
        if (obj == event.option.viewValue) {
          is_option_exist = true;
        }
        if (index == this.form.controls[field_name].value.length - 1) {
          if (is_option_exist == true) {
            this._snackBar.open('you have already selected this option', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
          }
          else {
            this.form.controls[field_name].value[this.form.controls[field_name].value.length] = event.option.viewValue;
          }
        }
      });

    }

    // this.optInput.nativeElement.value = '';

    this.form.controls['static_multiple_dropdown_search_field'].setValue('');
  }

  add2(event: MatChipInputEvent, field_name): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      // this.fruits.push(value.trim());
    }

    if (input) {
      input.value = '';
    }

    this.form.controls[field_name].setValue('');
  }

  remove2(opt: string, field_name): void {
    this.form.controls[field_name].value.forEach(obj => {
      if (obj.id == opt['id']) {
        const index = this.form.controls[field_name].value.indexOf(obj);
        this.form.controls[field_name].value.splice(index, 1);
      }

    });
  }

  selected2(event: MatAutocompleteSelectedEvent, field_name, linked_field_name): void {
    if (this.isArray(this.form.controls[field_name].value) == false || this.form.controls[field_name].value.length == 0) {
      this.form.controls[field_name].setValue([]);
      const length = this.form.controls[field_name].value.length;
      this.form.controls[field_name].value[length] = {};
      this.form.controls[field_name].value[length]['id'] = event.option.id;
      this.form.controls[field_name].value[length][linked_field_name] = event.option.viewValue;
    }
    else {
      var is_option_exist = false;
      this.form.controls[field_name].value.forEach((obj, index) => {
        if (obj.id == event.option.id) {
          is_option_exist = true;
        }
        if (index == this.form.controls[field_name].value.length - 1) {
          if (is_option_exist == true) {
            this._snackBar.open('you have already selected this option', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
          }
          else {
            const length = this.form.controls[field_name].value.length;
            this.form.controls[field_name].value[length] = {};
            this.form.controls[field_name].value[length]['id'] = event.option.id;
            this.form.controls[field_name].value[length][linked_field_name] = event.option.viewValue;
          }
        }
      });


    }

    // this.fruitInput.nativeElement.value = '';
    // this.optInput2.nativeElement.value = '';

    // console.log(this.form.controls['static_multiple_dropdown_search_field'].value);
    this.form.controls['dynamic_multiple_dropdown_search_field'].setValue('');
  }

  ngOnInit() {
    this.all_languages = JSON.parse(this.data.languages);

    // console.log(this.all_languages);

    this.model_name = this.data.model_name;
    this._isRedirect = this.data._isRedirect;
    this.shortcode = this.data.shortcode;
    this.form = new FormGroup(
      {
        'static_multiple_dropdown_search_field': new FormControl(''),
        'dynamic_multiple_dropdown_search_field': new FormControl('')
      }
    );
    this.form.controls['static_multiple_dropdown_search_field'].valueChanges.subscribe(value => {
      if (document.activeElement.id !== '' && document.activeElement.id !== null && document.activeElement.id !== 'undefined') {
        this.onFormControlValueChange(document.activeElement.id, value);
      }
    });
    this.form.controls['dynamic_multiple_dropdown_search_field'].valueChanges.subscribe(value => {
      if (document.activeElement.id !== '' && document.activeElement.id !== null && document.activeElement.id !== 'undefined') {
        this.onFormControlValueChange(document.activeElement.id, value);
      }
    });

    if (this._isRedirect == 'multiple_edit') {
      this.directedFromMultiEdit();
    }

    if (this._isRedirect == 'mlist') {
      this.directedFromMlist(this.all_languages.default.code, this.data.content_id);
    }
  }

  directedFromMultiEdit() {
   
    this.nowShowing = "MultiEdit";
    this.ln_code = this.data.ln_code;

    if (this.shortcode == '' || this.data.shortcode == null || this.data.shortcode == undefined) {
      this.data.fields.forEach(field => {
        this.field_name.push(field);
      });
      this.create_Form();

      this.tab_disable = false;

      this.contents = this.data.content;

      // console.log(this.contents)

      this._isSingle = true;

      this.data.fields.forEach((field, index) => {
        this.form.controls[field].setValue(this.data.content[field]);
        if (index == this.data.fields.length - 1) {
          this.loadingRouteConfig = false;
        }
      });
    }
    else {
      this.model_id = this.data.model_id;
      this.collection = this.data.collection;
      this.content_id = this.data.content_id;
      this.service.getAllField(this.model_id).subscribe((fields: any) => {
        // console.log(fields);
        var isDropDown = false;
        var record = {
          "model_id": this.model_id,
          "collection": this.collection,
          "content_id": this.content_id
        }
        // console.log(record);

        this.service.getSingleContentDetails(record).subscribe((contents: any) => {
          if(contents[0]){
          // console.log(contents);
          fields.data.forEach((field, index) => {
            this.field_name.push(field.name);
            this.create_Form();
            // console.log(contents);
            // ---------------------------------------------- validation ------------------------------------------------------
          if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
            field.isRequired = 'false';
          }
          if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
            field.requiredErrMsg = '';
          }
          if(field.patterns == '' || field.patterns == null || field.patterns == undefined){
            field.patterns = '';
          }
          if(field.patternsErrMsg == '' || field.patternsErrMsg == null || field.patternsErrMsg == undefined){
            field.patternsErrMsg = '';
          }
          if(field.minLength == '' || field.minLength == null || field.minLength == undefined){
            field.minLength = '0';
          }
          if(field.minLengthErrMsg == '' || field.minLengthErrMsg == null || field.minLengthErrMsg == undefined){
            field.minLengthErrMsg = '';
          }
          if(field.placeholder == '' || field.placeholder == null || field.placeholder == undefined){
            field.placeholder = '';
          }

          // ---------------------------------------------- End -------------------------------------------------------------
            if (field.type === 'file' || field.type === 'multi_file') {
              if (field.type === 'file') {
                this._isSingle = true;
                this.sdownloadURL = contents[0][field.name];
                this.form.controls[field.name].setValue(this.sdownloadURL);
                this.fcname1 = field.name;

              }
              else if (field.type === 'multi_file') {
                this._isSingle = false;
                this.mdownloadURL = contents[0][field.name];
                this.form.controls[field.name].setValue(this.mdownloadURL);
                this.fcname1 = field.name
                // console.log(this.mdownloadURL)
              }
            }
            else if (field.type === 'dropdown' || field.type === 'multi_dropdown') {
              this.refreshLoading = true;
              if (field.l_code !== undefined) {
                const record = {
                  field_id: field.l_field_id,
                  code: field.l_code
                }
                // console.log(record);
                this.service.getCodeContent(record).subscribe((codecontent: any) => {
                  // console.log(codecontent);
                  if (codecontent.status === undefined) {
                    field.optionData = codecontent;
                    this.form.controls[field.name].setValue(contents[0][field.name]);
                    this.refreshLoading = false;
                  }
                  else{
                    this.refreshLoading = false;
                  }
                });
              }
              else {
                field.optionData = field.staticOption;
                this.form.controls[field.name].setValue(contents[0][field.name]);
              }
            }
            else {
              this.form.controls[field.name].setValue(contents[0][field.name]);
              if (!isDropDown) {
              }
            }

            console.log(this.all_fields)
            this.all_fields.push(field);
            if (index === fields.data.length - 1) {
              this.isFirstInputField = false;
              this.loadingRouteConfig = false;
            }
          })
        }
        });
      });
    }



  }

  directedFromMlist(_lang: any, default_content_id: any) {

    this.nowShowing = "MList";

    this.model_id = this.data.model_id;
    this.collection = this.data.collection;
    this.content_id = this.data.content_id;
    this.default_content_id = this.data.content_id;
    this.service.getAllField(this.model_id).subscribe((fields: any) => {
      var record = {
        "model_id": this.model_id,
        "collection": this.collection,
        "content_id": this.content_id,
        "_lang": _lang,
        "default_content_id": default_content_id
      }
      this.service.getMultiLanguageContent(record).subscribe((contents: any) => {
        this.tab_disable = false;
        // console.log(contents);
        if(contents[0]){
          this.content_id = contents[0].id;
          this.ln_code = contents[0]._lang;
        if (this._default_content_id == undefined) {
          this._default_content_id = this.content_id;
        }
        this.loaded_contents = contents;
        fields.data.forEach((field, index) => {
          this.field_name.push(field.name);
          this.create_Form();
         

          // this.add_language_form();
          // ---------------------------------------------- validation ------------------------------------------------------
          if(field.isRequired == '' || field.isRequired == null || field.isRequired == undefined){
            field.isRequired = 'false';
          }
          if(field.requiredErrMsg == '' || field.requiredErrMsg == null || field.requiredErrMsg == undefined){
            field.requiredErrMsg = '';
          }
          if(field.patterns == '' || field.patterns == null || field.patterns == undefined){
            field.patterns = '';
          }
          if(field.patternsErrMsg == '' || field.patternsErrMsg == null || field.patternsErrMsg == undefined){
            field.patternsErrMsg = '';
          }
          if(field.minLength == '' || field.minLength == null || field.minLength == undefined){
            field.minLength = '0';
          }
          if(field.minLengthErrMsg == '' || field.minLengthErrMsg == null || field.minLengthErrMsg == undefined){
            field.minLengthErrMsg = '';
          }
          if(field.placeholder == '' || field.placeholder == null || field.placeholder == undefined){
            field.placeholder = '';
          }

          // ---------------------------------------------- End -------------------------------------------------------------

          if (field.type === 'file' || field.type === 'multi_file') {
            if (field.type === 'file') {
              this._isSingle = true;
              this.sdownloadURL = contents[0][field.name];
              this.form.controls[field.name].setValue(this.sdownloadURL);
              this.fcname1 = field.name;

            }
            else if (field.type === 'multi_file') {
              this._isSingle = false;
              this.mdownloadURL = contents[0][field.name];
              this.form.controls[field.name].setValue(this.mdownloadURL);
              this.fcname1 = field.name
              // console.log(this.mdownloadURL)
            }
          }
          else if (field.type === 'dropdown' || field.type === 'multi_dropdown') {
            this.refreshLoading = true;
            if (field.l_code !== undefined) {
              const record = {
                field_id: field.l_field_id,
                code: field.l_code
              }
              // console.log(record);
              this.service.getCodeContent(record).subscribe((codecontent: any) => {
                // console.log(codecontent);
                if (codecontent.status === undefined) {
                  field.optionData = codecontent;
                  this.form.controls[field.name].setValue(contents[0][field.name]);
                  this.refreshLoading = false;
                }
                else{
                this.refreshLoading = false;
                }
              });
            }
            else {
              field.optionData = field.staticOption;
              this.form.controls[field.name].setValue(contents[0][field.name]);
            }
          }
          else {
            this.form.controls[field.name].setValue(contents[0][field.name]);
          }
          this.all_fields.push(field);
          if (index === fields.data.length - 1) {
            this.isFirstInputField = false;
            this.loadingRouteConfig = false;
          }
        })
      }
      });
    });
  }

  checkObj() {
    if (document.activeElement.id !== '' && document.activeElement.id !== null && document.activeElement.id !== 'undefined') {
      if (this.form.controls[document.activeElement.id]) {
        if (this.form.controls[document.activeElement.id].value) {
          this.onFormControlValueChange(document.activeElement.id, this.form.controls[document.activeElement.id].value);
        }
      }
    }
  }

  change_lang(event: any) {

    this.tab_disable = true;
    this.all_fields = [];
    if (event['tab'].textLabel == this.all_languages.default.language) {
      alert('default');
      this.ln_code = this.all_languages.default.code;
      if (this._isRedirect == 'mlist') {
        this.directedFromMlist(this.all_languages.default.code, this.data.content_id);
      }
    }
    else {
      language["default"].forEach(single_lang => {
        if (single_lang.language == event['tab'].textLabel) {
           alert('other');
          this.ln_code = single_lang.code;
          if (this._isRedirect == 'mlist') {
            this.directedFromMlist(single_lang.code, "");
          }
        }
      })
    }
  }

  typeOf(value) {
    return typeof value;
  }

  isArray(obj) {
    return Array.isArray(obj);
  }

  callLibrary(fcname) {
    // console.log(fcname);
    this.fcname = fcname;
    this.data =
    {
      ...this.data,
      _type: "multiple_content_edit",
      _isDirected: true,
      _isSingle: this._isSingle
    }
    this.component = 'library_list';
  }

  create_Form() {
    this.field_name.forEach(multi_field_name => {
      this.form.addControl(multi_field_name, new FormControl(''));
      this.form.controls[multi_field_name].valueChanges.subscribe(value => {
        this.onFormControlValueChange(multi_field_name, value);
      })
    })
  }

  saveContent() {
    this.spinner = true;
    delete this.form.value.static_multiple_dropdown_search_field;
    delete this.form.value.dynamic_multiple_dropdown_search_field;
    if (this.content_id == this._default_content_id) {
      let promise = new Promise((resolve, reject) => {
        this.form.value.collection = this.collection;
        this.form.value.content_id = this.content_id;
        this.form.value._lang = this.ln_code;
        resolve(this.form.value);
      })
      promise.then((result1: any) => {
        this.service.updateContent(result1).subscribe((update) => {
          this.spinner = false;
          this._snackBar.open('Content updated Successfully', '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
          // console.log(this._isRedirect);
          if (this._isRedirect == 'mlist') {
            // this.list1.ngOnInit();
            // this.list1.component = 'mlist';
            // this.list1.pageEvent.pageIndex = 0;
          }
          if (this._isRedirect == 'multiple_edit') {
            this.list2.component = 'multiple_edit';
            // window.location.reload();
          }

        })
      })
    }
    else {
      let promise = new Promise((resolve, reject) => {
        this.form.value.collection = this.collection;
        this.form.value.content_id = this.content_id;
        this.form.value._lang = this.ln_code;
        this.form.value.default_content_id = this._default_content_id;
        resolve(this.form.value);
      })
      promise.then((result1: any) => {
        this.service.updateContent(result1).subscribe((update) => {
          this.spinner = false;
          this._snackBar.open('Content updated Successfully', '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
          // console.log(this._isRedirect);
          if (this._isRedirect == 'mlist') {
            // this.list1.ngOnInit();
            // this.list1.component = 'mlist';
            // this.list1.pageEvent.pageIndex = 0;
          }
          if (this._isRedirect == 'multiple_edit') {
            this.list2.component = 'multiple_edit';
            // window.location.reload();
          }

        })
      })
    }

  }

  setImage(event) {
    // console.log(event);
    // console.log(this._isRedirect);
    if (this._isRedirect == 'mlist') {
      if (this._isSingle === false) {
        for (var i = 0; i < event.length; i++) {
          this.mdownloadURL.push(event[i]);
        }
        this.form.controls[this.fcname].setValue(this.mdownloadURL);
      }
      else {
        this.sdownloadURL = event;
        this.form.controls[this.fcname].setValue(this.sdownloadURL);
      }
    }
    if (this._isRedirect == 'multiple_edit') {
      // console.log(this.fcname);
      this.form.controls[this.fcname].setValue(event);

      for (let obj of this.objectKeys(this.contents)) {
        if (obj == this.fcname) {
          this.contents[obj] = event;
          // console.log(this.contents);
        }
      }

    }
    // console.log("iamge", this.downloadURL)
  }

  back() {
    if (this._isRedirect == 'mlist') {
      this.list1.ngOnInit();
      this.list1.component = 'mlist';
      this.list1.pageEvent.pageIndex = 0;
    }
    if (this._isRedirect == 'multiple_edit') {
      this.list2.component = 'multiple_edit';
    }
  }

  return() {
    this.spinner = true;
    delete this.form.value.static_multiple_dropdown_search_field;
    delete this.form.value.dynamic_multiple_dropdown_search_field;
    this.form.value._lang = JSON.parse(this.data.languages).default.code;
    // console.log(this.form.value._lang);
    if (typeof this.form.controls.id.value == 'number') {
      // console.log("id is a number");
      // console.log(this.data)
      var create_record: any = [];
      create_record = {
        "model": this.data.model,
        "page_id": this.data.page_id,
        "contents": this.form.value,
      }
      // console.log(create_record);

      this.service.UpdateMultipleContentOfPage(create_record).subscribe((result: any) => {
        // console.log(result);
        var previousContentId = this.form.controls.id.value;
        this.form.controls.id.setValue(result.content_id);
        this.spinner = false;
        this._snackBar.open(result.status, '', {
          duration: 2500,
          horizontalPosition: "left",
          panelClass: ['custom-snackbar']
        });
        if (this._isRedirect == 'multiple_edit') {
          var emitRecord = {
            formData: this.form.value,
            previousContentId: previousContentId
          }
          this.emitedVallue.emit(emitRecord);
          this.list2.component = 'multiple_edit';
          // window.location.reload();
        }

        else if (this._isRedirect == 'mlist') {
          this.list1.ngOnInit();
          this.list1.component = 'mlist';
          this.list1.pageEvent.pageIndex = 0;
        }
      });
    }
    else {
      if (this.form.controls.id.value.length >= 5) {
        // console.log("id is a string");
        let promise = new Promise((resolve, reject) => {
          var record = {
            ...this.form.value,
            shortcode: this.data.shortcode,
            content_id: this.form.controls.id.value
          }
          // this.form.value.shortcode = this.data.shortcode;
          // this.form.value.content_id = this.form.controls.id.value;
          resolve(record);
        })
        promise.then((result1: any) => {
          // console.log(result1);
          this.service.updateContent(result1).subscribe((update) => {
            // console.log(update);
            // this.form.value.id = contents.content_id;
            this.spinner = false;
            this._snackBar.open('Content updated Successfully', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
            if (this._isRedirect == 'multiple_edit') {
              var emitRecord = {
                formData: this.form.value,
                previousContentId: this.form.controls.id.value
              }
              this.emitedVallue.emit(emitRecord);
              this.list2.component = 'multiple_edit';
            }

            else if (this._isRedirect == 'mlist') {
              this.list1.ngOnInit();
              this.list1.component = 'mlist';
              this.list1.pageEvent.pageIndex = 0;
            }
          })
        });
      }
      else {
        // console.log("id is a number within quotes");
        var create_record: any = [];
        create_record = {
          "model": this.data.model,
          "page_id": this.data.page_id,
          "contents": this.form.value,
        }
        // console.log(create_record);

        this.service.UpdateMultipleContentOfPage(create_record).subscribe((result: any) => {
          // console.log(result);
          var previousContentId = this.form.controls.id.value;
          this.form.controls.id.setValue(result.content_id);
          this.spinner = false;
          this._snackBar.open(result.status, '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
          if (this._isRedirect == 'multiple_edit') {
            var emitRecord = {
              formData: this.form.value,
              previousContentId: previousContentId
            }
            this.emitedVallue.emit(emitRecord);
            this.list2.component = 'multiple_edit';
            // window.location.reload();
          }

          else if (this._isRedirect == 'mlist') {
            this.list1.ngOnInit();
            this.list1.component = 'mlist';
            this.list1.pageEvent.pageIndex = 0;
          }
        });
      }

    }
  }

  onFormControlValueChange(field_name, value) {
    this.static_single_dropdown_search = false;
    this.static_single_dropdown_tempArray = null;
    this.dynamic_single_dropdown_search = false;
    this.dynamic_single_dropdown_tempArray = null;
    this.static_multiple_dropdown_search = false;
    this.static_multiple_dropdown_tempArray = null;
    this.dynamic_multiple_dropdown_search = false;
    this.dynamic_multiple_dropdown_tempArray = null;
    try {
      this.all_fields.map(field => {
        if (field.name == field_name) {
          if (field.type == 'dropdown') {
            if (this.isArray(field.optionData) == true) {
              if (value == "") {
                this.static_single_dropdown_search = false;
                this.form.controls.field_name.setValue(null);
              }
              else {
                this.static_single_dropdown_search = true;
                this.static_single_dropdown_tempArray = null;
                this.static_single_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(value.toLowerCase()));
              }
            }
            else {
              if (value == "") {
                this.dynamic_single_dropdown_search = false;
                this.form.controls.field_name.setValue(null);
              }
              else {
                this.dynamic_single_dropdown_search = true;
                this.dynamic_single_dropdown_tempArray = null;
                this.dynamic_single_dropdown_tempArray = {
                  optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                }
                const linked_field_name = field.optionData.field;
                field.optionData.data._data.map(o => {
                  if (o[linked_field_name].toLowerCase().includes(value.toLowerCase())) {
                    this.dynamic_single_dropdown_tempArray.optionData.data._data.push(o);
                  }
                });
              }
            }
          }

          else if (field.type == 'multi_dropdown') {
            if (this.isArray(field.optionData) == true) {
              const multiple_dropdown_search_field = 'static_multiple_dropdown_search_field';
              if (this.form.controls[multiple_dropdown_search_field].value == "") {
                this.static_multiple_dropdown_search = false;
                this.form.controls[multiple_dropdown_search_field].setValue('');
              }
              else {
                this.static_multiple_dropdown_search = true;
                this.static_multiple_dropdown_tempArray = null;
                this.static_multiple_dropdown_tempArray = field.optionData.filter(o => o.toLowerCase().includes(this.form.controls[multiple_dropdown_search_field].value.toLowerCase()));
              }
            }
            else {
              const multiple_dropdown_search_field = 'dynamic_multiple_dropdown_search_field';
              if (this.form.controls[multiple_dropdown_search_field].value == "") {
                this.dynamic_multiple_dropdown_search = false;
              }
              else {
                this.dynamic_multiple_dropdown_search = true;
                this.dynamic_multiple_dropdown_tempArray = null;
                this.dynamic_multiple_dropdown_tempArray = {
                  optionData: { data: { _data: [], _shortCode: field.optionData.data._shortCode }, field: field.optionData.field }
                }
                const linked_field_name = field.optionData.field;
                field.optionData.data._data.map(o => {
                  if (o[linked_field_name].toLowerCase().includes(this.form.controls[multiple_dropdown_search_field].value.toLowerCase())) {
                    this.dynamic_multiple_dropdown_tempArray.optionData.data._data.push(o);
                  }
                });
              }
            }
          }
          else {
            // console.log('not a dropdown field');
            this.static_single_dropdown_search = false;
            this.dynamic_single_dropdown_search = false;
            this.static_multiple_dropdown_search = false;
            this.dynamic_multiple_dropdown_search = false;
          }
        }
      });
    } catch { }
  }


}