/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/storage';
import { LibraryListComponent } from '../library-list/library-list.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { async } from '@angular/core/testing';
import { LibraryService } from '../library.service';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-library-add',
  templateUrl: './library-add.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css','./library-add.component.scss'],
})
export class LibraryAddComponent implements OnInit {

  uploadPercent: any;
  downloadURL: any;
  folder: any;
  filePath: any;
  uploadState: string;
  task: any;
  selectedFiles: any = [];
  type: any = 'image';
  newFolder: any;

  @Input()
  data: any;

  file: any;
  localUrl: any;
  localCompressedURl: any;
  sizeOfOriginalImage: number;
  sizeOFCompressedImage: number;

  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;

  constructor(private service: LibraryService,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private storage: AngularFireStorage,
    private list: LibraryListComponent,
    private imageCompress: NgxImageCompressService,) { }

  ngOnInit() {

    // console.log(this.data)
    this.folder = this.data.path;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  back() {
    this.list.component = 'library_list'
  }
  updateDatabse(imageFile, fileName) {
    // console.log("upload to fire");
    // console.log(fileName);
    // console.log(imageFile);
    if (this.folder === undefined) {
      if (this.newFolder === undefined) {
        this.filePath = '/' + fileName;
      }
      else {
        this.filePath = '/' + this.newFolder + '/' + fileName;
      }
    }
    else {
      if (this.newFolder === undefined) {
        this.filePath = '/' + this.folder + '/' + fileName;
      }
      else {
        this.filePath = '/' + this.folder + '/' + this.newFolder + '/' + fileName;
      }
    }
    this.task = this.storage.upload(this.filePath, imageFile);
    this.task.snapshotChanges().subscribe(data => {
      // this.uploadPercent = (data.bytesTransferred / data.totalBytes) * 100;
      // this.selectedFiles[i].uploadPercent = (data.bytesTransferred / data.totalBytes) * 100;
      this.selectedFiles.forEach(file => {
        file.uploadPercent = (data.bytesTransferred / data.totalBytes) * 100;
        // console.log(file.uploadPercent)
      });
      this.uploadState = data.state;
      // console.log(this.uploadPercent)
    })
    this.task.then(() => {
      this.storage.ref(this.filePath).getDownloadURL().subscribe(url => {
        this.downloadURL = url
        // console.log(this.downloadURL );
      })
    })
  }
  

  upload(event: any) {
    // console.log(event.target.files.length)
    for (var i = 0; i < event.target.files.length; i++) {
      this.selectedFiles.push({ url: URL.createObjectURL(event.target.files[i]), uploadPercent: 0 })
      // console.log(URL.createObjectURL(event.target.files[i]));
      const file = event.target.files[i];
      // console.log(file);

      let fileName: any;
      fileName = file.name;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        // console.log(file.type);
        if(file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type ==='image/png'){
        //  console.log("hello 2");
          this.uploadImage(this.localUrl, fileName);
        }
        else{
        //  console.log("hello 3");
          this.updateDatabse(file, fileName);
        }
        // this.compressFile(this.localUrl, fileName,500);
      };
      reader.readAsDataURL(event.target.files[i]);
    }


  }

  uploadImage(image, fileName) {
    var that = this;
      // console.log("hello");
      const i = new Image();
      i.src = image;
      i.onload = function () {
        // console.log(i.width);
        if (i.width > 1000) {
          // console.log("1");
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_xl', fileName), 1600);
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_l', fileName), 1000);
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_m', fileName), 500);
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_s', fileName), 200);
        }
        else if(i.width > 500) {
          // console.log("2");
          // console.log(fileName);
          // console.log(that.splice(fileName.lastIndexOf('.'), 0, '_l', fileName));
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_l', fileName), 1000);
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_m', fileName), 500);
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_s', fileName), 200);
        }
        else if(i.width > 200) {
          // console.log("3");
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_m', fileName), 500);
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_s', fileName), 200);
        }
        else if(i.width <= 200) {
          // console.log("4");
          that.compressFile(image, that.splice(fileName.lastIndexOf('.'), 0, '_s', fileName), i.width);
        }
      }
  }

  splice = function (idx, rem, str, main_str) {
    return main_str.slice(0, idx) + str + main_str.slice(idx + Math.abs(rem));
  };


  compressFile(image, fileName, width) {
    let that = this;
    const i = new Image();
    i.onload = function () {
      // alert(i.width + ', ' + i.height);
      const imgWidth = i.width;
      const ratio = (width / imgWidth) * 100;
      // console.log(ratio);
      const orientation = -1;
      that.sizeOfOriginalImage =
        that.imageCompress.byteCount(image) / (1024 * 1024);
      // console.log('Size in bytes is now:', that.sizeOfOriginalImage);
      that.imageCompress
        .compressFile(image, orientation, ratio, 70)    // width
        .then((result) => {
          // console.log(result);          
          that.localCompressedURl = result;
          // console.log(that.localCompressedURl);
          that.sizeOFCompressedImage =
            that.imageCompress.byteCount(result) / (1024 * 1024);
          // console.log('Size in bytes after compression:',that.sizeOFCompressedImage );
          const imageName = fileName;
          const imageFile = that.dataURLtoFile(
            that.localCompressedURl,
            imageName
          );
          that.updateDatabse(imageFile, fileName);
        });
    };
    i.src = image;

  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  addImage() {
    if(this.data.type === 'setting_edit' || this.data.type === "element_add" || this.data.type === "element_edit")
    {
      this._snackBar.open('New Image uploaded', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }
    else
    {
      this._snackBar.open('New Image uploaded', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
    }
    this.list.component = 'library_list';
    this.list.ngOnInit();
  }

}
