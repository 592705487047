/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;
import { LightBoxService } from '../light-box.service';
import { FormControl, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-single-edit',
  templateUrl: './single-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './single-edit.component.scss'],
})
export class SingleEditComponent implements OnInit {
  url: any;

  @Input()
  model: any;
  @Input()
  contents: any;
  @Input()
  type: any;
  @Input()
  modal: any;
  @Input()
  pageData: any;
  @Input()
  allModels: any;
  @Input()
  dynamicContent: any;
  @Input()
  formModel: any;
  @Input()
  uid: any;
  @Input()
  apieData: any;

  sessionFields: any[] = [{
    key: "",
    value: ""
  }];

  loadingRouteConfig: boolean;
  shortcode: boolean;
  code: string;
  name: string;
  record: any = [];
  description: any;
  loading: boolean;
  contentType: any = 'static';
  fields: any;
  selectedField: any;
  spinner: boolean;
  urltype: any;
  all_pages: any = [];
  filteredPages: any = [];
  selectedPage: any;
  loaderOverField: boolean;
  setSessionVar: any;
  modelType: any;
  modelForm: any;
  setting: any;
  page: any;
  ln_code: any;
  group: any = {};
  okey: any;
  default_code: any;
  canSave: any;

  constructor(private _snackBar: MatSnackBar, private route: ActivatedRoute, private service: LightBoxService) {
    this.page = this.route['_routerState'].snapshot.url.substring(1);
    const index = window.location.href.lastIndexOf('lang=');
      if(index !== -1){
        this.ln_code = window.location.href.substring(index + 5,window.location.href.length);
        console.log(this.ln_code);
      }
    // this.route.queryParams.subscribe(params => {
    //   this.ln_code = params['lang'];
    // });
    this.loadingRouteConfig = true;
    this.shortcode = false;
    this.spinner = false;
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '50',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '50',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  availableInArray(arr, element) {
    if (arr !== undefined) {
      if (arr.includes(element)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }


  ngOnInit() {
    // console.log(this.apieData);
    this.default_code = JSON.parse(this.apieData.languages).default.code;
    if(this.dynamicContent !== undefined)
    {
      this.dynamicContent = JSON.parse(unescape(this.dynamicContent))
    }
    this.setting = JSON.parse(unescape(this.apieData.userData));
    this.setting = this.setting.settingData;

      if (this.setting.w_language !== undefined) {
        if (this.setting.w_language.includes(this.ln_code)) {
          this.okey = 'description#' + this.ln_code;
          this.group[this.okey] = this.contents._value;
        }
        else {
          this.okey = 'description#' + this.setting.w_l_default;
          this.group[this.okey] = this.contents._value;
        }
      }
      else
      {
        this.okey = 'description#en';
        this.group[this.okey] = this.contents._value;
      }
    
    this.service.getAllPages().subscribe((pages: any) => {
      this.loadingRouteConfig = false;
      if (pages['list'].length === 0) {
      } else {
        this.all_pages = pages['list'];
        this.filteredPages = this.all_pages;
        this.service.sortAlphabetically(this.all_pages, "name");
      }

    });

    if (this.contents._url !== undefined) {
      this.url = this.contents._url;
    }
    if (this.contents._session !== undefined && this.contents._session !== "") {
      this.setSessionVar = this.contents._session;
    }
    console.log(this.default_code);
    console.log(this.ln_code);
    console.log(this.contents._shortCode);
    if(this.ln_code == undefined){
      this.ln_code = this.default_code;
    }
    if((this.default_code !== this.ln_code) && (this.contents._shortCode == '' || this.contents._shortCode == null || this.contents._shortCode == undefined)){
      this.canSave = false;
    }
    else{
      this.canSave = true;
    }
    this.generateNameCode();
  }

  getField() {
    this.loaderOverField = true;
    if (this.contentType === 'dynamic') {
      if (this.pageData.pageDetails.model_id !== undefined) {
        this.service.getModelFields(this.pageData.pageDetails.model_id).subscribe((fields: any) => {
          this.modelType = fields.model_type;
          if (fields !== undefined) {
            this.fields = fields.data;
            this.loaderOverField = false;
          }
        });
      }
      else {
        if (this.pageData.pageDetails.sessionVariable !== undefined) {
          var record = {
            page_id: this.pageData.page_id,
            uid: this.uid
          }
          this.service.getSession(record).subscribe((fields: any) => {
            // console.log(fields)
            this.fields = fields;
            this.loaderOverField = false;
          })
        }
      }
    }
  }

  deleteVar(i) {
    this.sessionFields.splice(i, 1);
  }

  addNewVar() {
    this.sessionFields.push({
      key: "",
      value: ""
    })
  }

  displayFn(model) {
    if (model === null || model === undefined) {
      return '';
    } else {
      return model.name;
    }
  }

  onPageChange() {
    try {
      // console.log(this.selectedPage);
      if (this.selectedPage.name !== undefined) {
        this.url = this.selectedPage.name;
        this.filteredPages = this.all_pages.filter(o => o.name.toLowerCase().includes(this.selectedPage.name.toLowerCase()));
      }
      else {
        this.url = this.selectedPage;
        this.filteredPages = this.all_pages.filter(o => o.name.toLowerCase().includes(this.selectedPage.toLowerCase()));
      }
      if (this.selectedPage.sessionVariable !== undefined || this.selectedPage.sessionVariable !== "") {
        this.setSessionVar = this.selectedPage.sessionVariable;
      }

    } catch { }
  }
  loadContent() {
    if(this.dynamicContent !== undefined)
    {
        this.group[this.okey] = this.dynamicContent.details[this.selectedField.name];
    }
  }
  generateNameCode() {
    if (this.contents._shortCode !== null) {
      this.shortcode = true;
      this.code = this.contents._shortCode;
    } else {
      this.name = this.model;
      if(this.name.includes('apiecommon')) {
        this.code = this.name;
      }
      else{
        this.code = this.name + "_" + Math.floor(Math.random() * 1000001);
      }
      this.shortcode = false;
    }

  }

  clicktocopy(inputElement) {
    if (inputElement.value !== '') {
      inputElement.disabled = false;
      inputElement.select();
      document.execCommand('copy');
      inputElement.setSelectionRange(0, 0);
      inputElement.disabled = false;
      this._snackBar.open('Copied to clipboard', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      // $('#copied').text($(this).data('text')).fadeIn(400).delay(1000).fadeOut(400);
    }

  }

  createContent() {
    this.spinner = true;
    console.log(this.canSave);
    // console.log(this.selectedField)
    if(this.canSave == true){
    if (this.type === 'single_submit' || this.type === 'single_link') {
      var obj = {};
      // console.log(this.sessionFields)
      let promise = new Promise((resolve, reject) => {
        this.sessionFields.forEach(varField => {
          obj[varField.key] = varField.value
        })
        resolve(obj);
      });
      promise.then((result: any) => {

        if (this.setSessionVar !== undefined && this.setSessionVar !== null && this.setSessionVar !== "") {
          var obj1 = {};
          obj1[this.setSessionVar] = result;
        }
        this.record = {
          name: this.name,
          code: this.code,
          url: this.url,
          setSessionVar: obj1,
          model: this.model,
          page_id: this.pageData.page_id,
          contentType: this.contentType,
        };
          this.record[this.okey] = this.group[this.okey];
          this.service.addContent(this.record).subscribe((content: any) => {
            this.contents._value = this.group[this.okey];
            this.contents._shortCode = this.code;
            this.spinner = false;
            this._snackBar.open('content updated successfully', '', {
              duration: 2500,
              horizontalPosition: "left",
              panelClass: ['custom-snackbar']
            });
            this.modal.close();
          });
      })
    }
    else {
      if (this.contentType === 'static') {
        this.record = {
          name: this.name,
          code: this.code,
          url: this.url,
          setSessionVar: this.setSessionVar,
          model: this.model,
          page_id: this.pageData.page_id,
          contentType: this.contentType,
        };
      }
      else {
        if (this.pageData.pageDetails.sessionVariable !== undefined) {
          this.record = {
            name: this.name,
            code: this.code,
            url: this.url,
            setSessionVar: this.setSessionVar,
            model: this.model,
            page_id: this.pageData.page_id,
            contentType: this.contentType,
            field: this.selectedField,
          };
        }
        else {
          if (this.modelType === "bitrix") {
            this.record = {
              name: this.name,
              code: this.code,
              url: this.url,
              setSessionVar: this.setSessionVar,
              model: this.model,
              page_id: this.pageData.page_id,
              contentType: this.contentType,
              field: this.selectedField.key,
              dynamicName: this.dynamicContent.dynamicName
            };
          } else {
            this.record = {
              name: this.name,
              code: this.code,
              url: this.url,
              setSessionVar: this.setSessionVar,
              model: this.model,
              page_id: this.pageData.page_id,
              contentType: this.contentType,
              field: this.selectedField.name,
              dynamicName: this.dynamicContent.dynamicName
            };
          }
        }
      }

        this.record[this.okey] = this.group[this.okey];
        
        this.service.addContent(this.record).subscribe((content: any) => {
          this.contents._value = this.group[this.okey];
          this.contents._shortCode = this.code;
          this.spinner = false;
          this._snackBar.open('content updated successfully', '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
          this.modal.close();
        });
     
    }
  }
  else{
    this._snackBar.open('First update in default language', '', {
      duration: 2500,
      horizontalPosition: "left",
      panelClass: ['custom-snackbar']
    });
    this.spinner = false;
  }

  }

}
