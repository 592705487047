import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  host = environment.host;

  base_component:any;

  constructor(private httpClient: HttpClient) { }

  addModel(record) {
    return this.httpClient.post(`${this.host + "model/add"}`, record);
  }
  getAllModels(record) {
    return this.httpClient.post(`${this.host + "model/get"}`, record);
  }

  getOneModel(model_id) {
    return this.httpClient.get(`${this.host + "model/"}` + model_id);
  }
  updateModel(model_id, record) {
    return this.httpClient.put(`${this.host + "model/update/"}` + model_id, record);
  }
  deleteModel(key) {
    return this.httpClient.delete(`${this.host + "model/delete/"}` + key);
  }
  getBitrixSection() {
    return this.httpClient.get(`${this.host + "bitrix/sections"}`);
  }
  updatePinnedCollection(record) {
    return this.httpClient.post(`${this.host + "users/pinned"}`, record);
  }

  sortAlphabetically(arr, prop) {
    arr.sort(
      function (a, b) {
        if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
          return -1;
        } else if (a[prop.toLowerCase()] > b[prop].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    );
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

}
