/* eslint-disable */
import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../admin/shared/shared.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  wait: any;
  email: any;
  logo: any;
  loadingRouteConfig: boolean;
  loaderUrl: string;

  constructor(@Inject(DOCUMENT) private doc,private fireauth: AngularFireAuth, private router: Router,private pageTitle: Title, 
  private _snackBar: MatSnackBar, private service: SharedService) {
    this.wait = false;
    this.loadingRouteConfig = true;
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  createLink(type, url) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', url);
    this.doc.head.appendChild(link);
  }


  ngOnInit() {
    // this.pageTitle.setTitle("Change Your Password");
    this.loaderUrl = "https://firebasestorage.googleapis.com/v0/b/ " + environment.firebase.storageBucket + "/o/APIE_ASSETS%2Floader.gif?alt=media" ;
    this.service.getSettings().subscribe((setting: any) => {
      if(setting.w_logo === undefined)
      {
        this.logo = "../../../assets/apie_img/apie-logo.png";
      }
      else
      {
        this.logo = setting.w_logo;
      }
      if(setting.w_fav === undefined)
      {
        this.createLink("icon","../../../assets/apie_img/apie-fav.png");
      }
      else
      {
        this.createLink("icon", setting.w_fav);
      }
      this.loadingRouteConfig = false;
      // console.log(this.logo)
    })
  }

  forgot() {
    this.wait = true;
    this.fireauth.sendPasswordResetEmail(this.email).then(data => {
      this.wait = false;
      this._snackBar.open('Link sent', '', {
        duration: 2500,
        horizontalPosition: "center",
        panelClass: ['custom-snackbar']
      });
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 3000);

    })
      .catch(err => {
        this.wait = false;
        this._snackBar.open('failed -' + err, '', {
          duration: 2500,
          horizontalPosition: "center",
          panelClass: ['custom-snackbar']
        });
      });
  }

}
