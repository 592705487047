/* eslint-disable */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CodeService } from '../code.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $ :any;
import { CodeListComponent } from '../code-list/code-list.component';
import { FieldAddComponent } from '../../field/field-add/field-add.component';
import { MultipleEditComponent } from '../../light-box/multiple-edit/multiple-edit.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-code-add',
  templateUrl: './code-add.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css', './code-add.component.scss'],
})
export class CodeAddComponent implements OnInit {
  name: any = new FormControl('', [Validators.required, Validators.minLength(3)]);
  filtersForm: FormGroup;

  loadingRouteConfig: any;
  shortcode: any;
  code: string;
  model_id: any;
  all_fields: any;
  record: any = [];
  filters: any;
  limit: any;
  order: any;
  filtersCount: number = 1;
  filter1: any;
  filter2: any;
  filter3: any;
  filter4: any;
  filter5: any;
  condition1: any;
  condition2: any;
  condition3: any;
  condition4: any;
  condition5: any;
  value1: any;
  value2: any;
  value3: any;
  value4: any;
  value5: any;
  field: any;
  orderby: any;
  fieldValue: any = [];
  type1: any = 'static';
  type2: any = 'static';
  type3: any = 'static';
  type4: any = 'static';
  type5: any = 'static';

  @Input()
  data: any;

  @Output()
  emitedVallue = new EventEmitter();
  spinner: boolean;
  details: any;

  constructor(private _snackBar: MatSnackBar, private service: CodeService, private list: CodeListComponent, private field_add: FieldAddComponent, private multiple_edit: MultipleEditComponent) {
    this.loadingRouteConfig = true;
    this.shortcode = false;
  }

  getErrorMessage() {
    var errmsg = {
      name: null,
      title: null,
      metaTags: null
    }
    if (this.name.hasError('required')) {
      errmsg.name = 'You must enter a name';
    }
    if (this.name.hasError('minlength')) {
      errmsg.name = 'Code name must be 3 character';
    }

    return errmsg;
  }

  ngOnInit() {
    // console.log(this.data);
    if(this.data.query !== null && this.data.query !== undefined){
      // console.log(this.data.query);
      this.name.setValue(this.data.newName);
      this.add();
      this.limit = this.data.query.limit;
      
      if(this.data.query.order){
        this.data.query.order.forEach(order => {
          // console.log(order);
          var order_array = order.split('|');
          // console.log(order_array);
          this.field = order_array[0];
          this.orderby = order_array[1];
        });
      }

      if(this.data.query.filters){
        if(this.data.query.filters.length != 0){
          this.filters = this.data.query.filters;
          this.filtersCount = this.data.query.filters.length;
          this.data.query.filters.forEach((filter,index) => {
            // console.log(filter);
            var filter_array = [];
            filter_array = filter.split('|');
            // console.log(filter_array);
            if(index == 0){
              this.filter1 = filter_array[0];
              this.condition1 = filter_array[1];
              this.value1 = filter_array[2];
              this.type1 = filter_array[3];
            }
            if(index == 1){
              this.filter2 = filter_array[0];
              this.condition2 = filter_array[1];
              this.value2 = filter_array[2];
              this.type2 = filter_array[3];
            }
            if(index == 2){
              this.filter3 = filter_array[0];
              this.condition3 = filter_array[1];
              this.value3 = filter_array[2];
              this.type3 = filter_array[3];
            }
            if(index == 3){
              this.filter4 = filter_array[0];
              this.condition4 = filter_array[1];
              this.value4 = filter_array[2];
              this.type4 = filter_array[3];
            }
            if(index == 4){
              this.filter5 = filter_array[0];
              this.condition5 = filter_array[1];
              this.value5 = filter_array[2];
              this.type5 = filter_array[3];
            }
          });
        }
      }
    }

    this.model_id = this.data.model_id;
    this.service.getAllField(this.model_id).subscribe((field: any) => {
      this.all_fields = field.data;
      this.loadingRouteConfig = false;
    });

    if (this.data._details !== undefined) {
      this.details = this.data._details.details;
      // console.log(this.details);
      let key: any;
      for (key in this.details) {
        const value = this.details[key];
        if (key !== 'createdAt' && key !== 'model_id' && key !== 'modifiedAt' && key !== 'content_id' && key !== 'isActive') {
          this.fieldValue.push({
            field_name: key,
            field_value: value
          });
        }
      }
      console.log(this.fieldValue);
    }

  }

  addMore() {
    if (this.filtersCount !== 5) {
      this.filtersCount += 1;
    }
  }
  remove() {
    if (this.filtersCount !== 1) {
      this.filtersCount -= 1;
    }
  }
  add() {
    this.code = this.name.value + "_" + Math.floor(Math.random() * 1000001);
    if (this.name.value.length > 0) {
      this.shortcode = true
    } else {
      this.shortcode = false
    }
  }

  clicktocopy(inputElement) {
    if (inputElement.value !== '') {
      inputElement.disabled = false;
      inputElement.select();
      document.execCommand('copy');
      inputElement.setSelectionRange(0, 0);
      inputElement.disabled = true;
      this._snackBar.open('Copied to clipboard', '', {
        duration: 2500,
        horizontalPosition: "right",
        panelClass: ['custom-snackbar']
      });
      // $('#copied').text($(this).data('text')).fadeIn(400).delay(1000).fadeOut(400);
    }

  }

  createCode() {
    this.spinner = true;
    if (this.orderby !== undefined && this.field !== undefined) {
      this.order = [this.field + '|' + this.orderby]
    }
    else if (this.orderby === undefined && this.field !== undefined) {
      this._snackBar.open('please enter a order', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      this.spinner = false;
    }
    else if (this.orderby !== undefined && this.field === undefined) {
      this._snackBar.open('please enter a field', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      this.spinner = false;
    }


    if (this.filtersCount === 1) {
      this.filters =
        [
          this.filter1 + '|' + this.condition1 + '|' + this.value1 + '|' + this.type1,
        ]
    }
    if (this.filtersCount === 2) {
      this.filters =
        [
          this.filter1 + '|' + this.condition1 + '|' + this.value1 + '|' + this.type1,
          this.filter2 + '|' + this.condition2 + '|' + this.value2 + '|' + this.type2,
        ]
    }
    if (this.filtersCount === 3) {
      this.filters =
        [
          this.filter1 + '|' + this.condition1 + '|' + this.value1 + '|' + this.type1,
          this.filter2 + '|' + this.condition2 + '|' + this.value2 + '|' + this.type2,
          this.filter3 + '|' + this.condition3 + '|' + this.value3 + '|' + this.type3,
        ]
    }
    if (this.filtersCount === 4) {
      this.filters =
        [
          this.filter1 + '|' + this.condition1 + '|' + this.value1 + '|' + this.type1,
          this.filter2 + '|' + this.condition2 + '|' + this.value2 + '|' + this.type2,
          this.filter3 + '|' + this.condition3 + '|' + this.value3 + '|' + this.type3,
          this.filter4 + '|' + this.condition4 + '|' + this.value4 + '|' + this.type4,
        ]
    }
    if (this.filtersCount === 5) {
      this.filters =
        [
          this.filter1 + '|' + this.condition1 + '|' + this.value1 + '|' + this.type1,
          this.filter2 + '|' + this.condition2 + '|' + this.value2 + '|' + this.type2,
          this.filter3 + '|' + this.condition3 + '|' + this.value3 + '|' + this.type3,
          this.filter4 + '|' + this.condition4 + '|' + this.value4 + '|' + this.type4,
          this.filter5 + '|' + this.condition5 + '|' + this.value5 + '|' + this.type5,
        ]
    }

    if (this.code === undefined) {
      this._snackBar.open('enter a name', '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      this.spinner = false;
    }
    else {
      if (this.filters[0] === "undefined|undefined|undefined|static") {
        if (this.limit !== undefined && this.order !== undefined) {
          this.record = {
            "code": this.code,
            "limit": this.limit,
            "order": this.order,
          };
        }
        else if (this.limit !== undefined && this.order === undefined) {
          this.record = {
            "code": this.code,
            "limit": this.limit,
          };
        }
        else if (this.limit === undefined && this.order !== undefined) {
          this.record = {
            "code": this.code,
            "order": this.order,
          };
        }
        else if (this.limit === undefined && this.order === undefined) {
          this.record = {
            "code": this.code,
          };
        }
      }
      else {
        if (this.limit !== undefined && this.order !== undefined) {
          this.record = {
            "code": this.code,
            "filters": this.filters,
            "limit": this.limit,
            "order": this.order,
          };
        }
        else if (this.limit === undefined && this.order !== undefined) {
          this.record = {
            "code": this.code,
            "filters": this.filters,
            "order": this.order,
          };
        }
        else if (this.limit !== undefined && this.order === undefined) {
          this.record = {
            "code": this.code,
            "filters": this.filters,
            "limit": this.limit,
          };
        }
        else if (this.limit === undefined && this.order === undefined) {
          this.record = {
            "code": this.code,
            "filters": this.filters,
          };
        }
      }
      if (this.data._isRedirect === "code_list") {
        this.service.addCode(this.record, this.model_id).subscribe(codes => {
          this.spinner = false;
          this._snackBar.open('New Query Added Successfully', '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
          this.list.ngOnInit();
          this.list.component = 'code_list';
        })
      }
      else if (this.data._isRedirect === "field_add") {
        this.service.addCode(this.record, this.model_id).subscribe((codes: any) => {
        this.emitedVallue.emit({ code: this.code, key: codes.code_id });
          this.spinner = false;
          this._snackBar.open('New Query Added Successfully', '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
          this.field_add.ngOnInit();
          this.field_add.component = 'field_add';
        })
      }
      else if (this.data._isRedirect === "multiple_edit") {
  
        this.service.addCode(this.record, this.model_id).subscribe((codes: any) => {
          this.emitedVallue.emit({ code: this.code, key: codes.code_id });
          this.spinner = false;
          this._snackBar.open('New Query Added Successfully', '', {
            duration: 2500,
            horizontalPosition: "left",
            panelClass: ['custom-snackbar']
          });
          this.multiple_edit.loadingRouteConfig = true;
          this.multiple_edit.ngOnInit();
          this.multiple_edit.component = 'multiple_edit';
        })
      }
    }
    
   

  }
  back() {
    if (this.data._isRedirect === "code_list") {
      this.list.component = 'code_list';
    }
    else if (this.data._isRedirect === "field_add") {
      this.field_add.component = 'field_add';
    }
    else if (this.data._isRedirect === "multiple_edit") {
      this.multiple_edit.component = 'multiple_edit';
    }
  }

}
