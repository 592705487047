import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FieldService {
  host = environment.host;

  constructor(private httpClient: HttpClient) { }
  addField(record) {
    return this.httpClient.post(`${this.host + "field/add"}`, record);
  }

  addMultipleFields(record) {
    return this.httpClient.post(`${this.host + "field/add_multiple"}`, record);
  }

  getAllField(model_id) {
    return this.httpClient.get(`${this.host + "field/modelfield/"}` + model_id);
  }
  getOneField(record) {
    return this.httpClient.post(`${this.host + "field"}`, record);
  }
  updateField(record) {
    return this.httpClient.put(`${this.host + "field/update/"}`, record);
  }

  updateOrder(record) {
    return this.httpClient.put(`${this.host + "field/order/update"}`, record);
  }

  deleteField(record) {
    return this.httpClient.put(`${this.host + "field/delete/"}`, record);
  }
  getCodeField(code) {
    return this.httpClient.get(`${this.host + "field/codefield/"}` + code);
  }
  getAllModels(record) {
    return this.httpClient.post(`${this.host + "model/get"}`, record);
  }
  getAllCodes(model_id) {
    return this.httpClient.get(`${this.host + "query/"}` + model_id);
  }
  getFormField(key)
  {
    return this.httpClient.get(`${this.host + "forms/form-field/"}` + key);
  }

  sortAlphabetically(arr, prop) {
    arr.sort(
      function (a, b) {
        if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
          return -1;
        } else if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    );
  }


  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
}