/* eslint-disable */
import { Component, OnInit, Input } from '@angular/core';
declare var $ :any;
import { LightBoxService } from '../light-box.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['../../../../assets/css/shadow-dom.css','./form-edit.component.scss'],
})
export class FormEditComponent implements OnInit {

  @Input()
  model: any;
  @Input()
  fields: any;
  @Input()
  type: any;
  @Input()
  modal: any;
  @Input()
  pageData: any;
  @Input()
  allModels: any;
  @Input()
  contents: any;
  @Input()
  apieData: any;

  data: any;
  component: any;
  objectKeys = Object.keys;

  loadingRouteConfig: boolean;
  filteredForms: any;
  all_forms: any;
  selectedForms: any;
  all_fields: any;
  fieldsForm: FormGroup;
  fieldType: string;
  spinner: boolean;
  loadOverField: boolean;
  filteredFields: any;
  sessionVariable: any;
  mappingData: any;

  constructor(private service: LightBoxService, private _snackBar: MatSnackBar) {
    this.loadingRouteConfig = true;
    this.spinner = false;
  }

  leftNav() {
    $(".multieditForm-wrap").animate({ scrollLeft: "-=100px" });
  }
  rightNav() {
    $(".multieditForm-wrap").animate({ scrollLeft: "+=100px" });
  }

  ngOnInit() {
    this.component = 'form_edit';
    var record= {
        page_id: this.pageData.page_id,
        shortCode: this.contents._shortCode
      }
    // console.log(record);
    if(this.contents._shortCode !== null && this.contents._shortCode !== undefined && this.contents._shortCode !== ''){
      this.service.getFormFieldMap(record).subscribe((result: any) =>{
        // console.log(result);
        this.mappingData = result;
        // console.log(this.mappingData);
      })
    }
    // console.log(this.allModels[2][0][this.model])
    this.fieldsForm = new FormGroup(
      {}
    );

    this.service.getAllFormList().subscribe((forms: any) => {
      // console.log(forms);
      if(forms.status == 0){
        this.all_forms = [];
        this.filteredForms = [];
        this.loadingRouteConfig = false;
      }
      else{
        this.all_forms = forms.data;
        this.filteredForms = forms.data;
        this.loadingRouteConfig = false;
      }
    });
    this.fields.forEach(field => {
      this.fieldsForm.addControl(field, new FormControl(''));
    })
  }


  availableInArray(arr, element) {
    if (arr !== undefined) {
      if (arr.includes(element)) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  displayFn(model) {
    if (model === null || model === undefined) {
      return '';
    } else {
      return model.name;
    }
  }
  onModelChange() {
    try {
      this.filteredForms = this.all_forms.filter(o => o.name.toLowerCase().includes(this.selectedForms.toLowerCase()));
    } catch { }
  }

  onFieldChange() {
    try {
      this.filteredFields = this.all_fields.filter(o => o.name.toLowerCase().includes(this.sessionVariable.toLowerCase()));
    } catch { }
  }

  Refresh(){
    this.data = {
      ...this.data,
      page_id: this.pageData.page_id,
      shortcode: this.mappingData.code,
      model: this.mappingData.model,
    }
    this.component = 'restore_form';
  }
 

  formField() {
    // console.log(this.selectedForms)
    this.loadOverField = true;
    this.fieldType = this.selectedForms.type;
    this.service.getFormField(this.selectedForms.key).subscribe((field: any) => {
      // console.log(field)
      this.all_fields = field.data;
      this.filteredFields = this.all_fields
      this.loadOverField = false;
    });
  }

  onSubmit() {
    this.spinner = true;
    var record = {
      page_id: this.pageData.page_id,
      code: this.selectedForms.short_code,
      fields: this.fieldsForm.value,
      model: this.model
    };
    // console.log(record)
    this.service.updateShortcode(record).subscribe((result: any) => {
      // console.log(result);
      this.spinner = false;
      this._snackBar.open(result.status, '', {
        duration: 2500,
        horizontalPosition: "left",
        panelClass: ['custom-snackbar']
      });
      this.modal.close();
    });
  }

}
